import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.accountDisabled();
  }

  async accountDisabled() {
    openModal("#account-disabled-modal");
  }
}
