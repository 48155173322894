import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.setScroll();
  }

  setScroll() {
    const urlParams = new URLSearchParams(window.location.search);
    const sortByValue = urlParams.get('sort[by]');
    const pageValue = urlParams.get('page');
  
    if ((sortByValue === 'completed_at' || sortByValue === 'score') || pageValue) {      
      const section = document.getElementById('recruiters-review-section');
      if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
