import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    $('#hiddenFieldsGroup').hide();
    $('#submitBtn').attr('disabled', true);
    $('#public-profile-option').hide();
    $('#custom_review_review_type').select2({
      width:'100%',
      placeholder: 'Select Type',
      templateResult: formatData,
      templateSelection: formatData,
      minimumResultsForSearch: -1
    });

    $('#custom_review_review_type').on('select2:select', function (event) {
      $("#custom_review_subject > option").each(function() {
        this.remove();
      });

      switch(event.params.data.id) {
        case 'core':
          $('#public-profile-option').show();
          $('#custom_review_subject').append(new Option('A Person Inside Your Organization', 'person'));
          $('#custom_review_subject').append(new Option('Your Company', 'agency'));
          $('#custom_review_subject').append(new Option('Process', 'process'));
          $('#custom_review_reviewee').attr('placeholder', 'Examples: Recruiter, Salesperson, Hiring Manager')
          break;
        case 'external':
          $('#public-profile-option').hide();
          $('#custom_review_subject').append(new Option('Other' ,'thing'));
          $('#custom_review_reviewee').attr('placeholder', 'Examples: Department, Onboarding Process')
          break;
        case 'anonymous':
          $('#public-profile-option').hide();
          $('#custom_review_subject').append(new Option('Internal Feedback', 'internal'));
          $('#custom_review_reviewee').attr('placeholder', 'Examples: Department, Onboarding Process')
          break;
      }
    });

    function formatData(data) {
      if (!data.id) {
        return data.text;
      }
      if (data.selected) {
        $('#hiddenFieldsGroup').show()
        $('#submitBtn').attr('disabled', false);
      }
      var image = data.element.attributes.dataimage.nodeValue;
      if(!image) {
        return opt.text;
      }
      else {
        var $data = $(
          '<span><img src="' + image + '"width="33rem" style="margin-right: 0.5rem"/>' +
            '<span style="font-size: 0.9rem;">'+ data.text + '</span>' +
          '</span>'
        );
        return $data;
      }
    };
  }

  reverifyReview(event) {
    const msg = event.target.dataset.flashMsg;
    this.flash_message(msg);
  }

  flash_message(message) {
    document.getElementById('notice-container').innerHTML = '<p class="alert">' + message + '</p>';
    setTimeout(() => $('p.alert').addClass('fade-out'), 5000);
  }

  validateFileType(event) {
    const file = document.querySelector('.batch-upload-input').files[0];

    if (file && file.type !== 'application/vnd.ms-excel') {
      event.preventDefault();
      this.flash_message('Only CSV files are allowed.');
    }
  }
}
