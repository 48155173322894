export default class Alert {

  flashMessage = (notice) => {
    $('body').append('<p class="notice">' + notice + '</p>');
    setTimeout(() => $('p.notice').addClass('fade-out'), 5000);
  }

  flashImageMessage(inputField, message) {
    const container = document.getElementById('image-notice-container');
    container.style.display = 'block';
    container.className = 'fixed-notice hidden';
    const containerNotice = $('#image-notice-container');
    if (typeof inputField !== 'undefined' && typeof message !== 'undefined') {
      containerNotice.html(inputField + ' ' + message);
    } else {
      containerNotice.html('Image must be at least 400x400');
    }
    containerNotice.removeClass('fade-out').removeClass('hidden').addClass('notice');
    setTimeout(() => {
      containerNotice.addClass('fade-out');
      setTimeout(() => {
        containerNotice.addClass('hidden');
        container.style.display = 'none';
      }, 500);
    }, 5000);
  }
}
