// TODO: Check if this is still needed
export default class BackgroundLogo {
  initializeListeners() {
    $('[data-bg-logo]').toArray().forEach(logo => {
      let url = $(logo).data('bg-logo');

      if (url !== '') {
        let style = `[data-bg-logo='${url}']::after{background-image:url('${url}')}`;
        $(`<style>${style}</style>`).insertBefore($(logo));
      }
    });
  }
}
