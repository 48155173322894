import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['annualPanel', 'monthlyPanel'];

  initialize() {
    this.defaultPlanId = "Annual Premium (84$/month)";
    this.clearPlanId();
    this.planId = this.getStoredPlanId();
    this.storePlanId(this.planId);
    const publishableKey = $("#stripe-key").val();
    this.stripe = Stripe(publishableKey);
    this.stripeElements();
  }

  getStoredPlanId() {
    return localStorage.getItem('selected_plan') || this.defaultPlanId;
  }

  storePlanId(planId) {
    this.planId = planId;
    localStorage.setItem('selected_plan', planId);
  }

  clearPlanId() {
    this.planId = this.defaultPlanId;
    localStorage.removeItem('selected_plan');
  }

  selectedPlanAnually() {
    this.storePlanId('Annual Premium (84$/month)');
  }

  selectedPlanMonthly() {
    this.storePlanId('Monthly Premium (99$)');
  }

  resetStripeElements() {
    if (this.cardElement) {
      this.cardElement.destroy();

    }
    this.stripeElements();
  }

  stripeElements() {
    const elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://rsms.me/inter/inter-ui.css' }],
      locale: 'auto'
    });

    const style = {
      base: {
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': { color: '#CFD7DF' }
      },
      invalid: { color: '#E25950' }
    };

    this.mountCardElement(elements, style, '#card-element', '#card-errors');

  }

  mountCardElement(elements, style, cardElementId, errorElementId) {
    this.cardElement = elements.create('card', { style });
    this.cardElement.mount(cardElementId);

    this.cardElement.addEventListener('change', ({ error }) => {
      const displayError = document.querySelector(errorElementId);
      displayError.textContent = error ? error.message : '';
    });
  }

  async submitStep4(event) {
    event.preventDefault();

    const { token, error } = await this.stripe.createToken(this.cardElement);
    if (error) {
      const errorElementId = '#card-errors';
      document.querySelector(errorElementId).textContent = error.message;
      return false;
    } else {
      document.querySelector('#subscription-btn').setAttribute('disabled', true);
      const userId = $('[data-user-id]').data('user-id');
      const formElement = document.querySelector(`[data-user-id="${userId}"]`);
      this.appendHiddenFields(token.id, this.planId);
      this.clearPlanId();
      formElement.submit();
    }
  }

  appendHiddenFields(tokenId, planId) {
    const formId = 'payment-form';
    const formElement = document.getElementById(formId);

    formElement.appendChild(this.createFormField('user[subscriptions][stripeToken]', tokenId, 'hidden'));
    formElement.appendChild(this.createFormField('user[subscriptions][plan_name]', planId, 'hidden'));
  }

  createFormField(fieldName, value, type) {
    const newField = document.createElement("input");
    newField.type = type;
    newField.name = fieldName;
    newField.value = value;
    return newField;
  }

  validateCardDetails(event) {
    const cardHolderName = event.target.value;
    const errorElementId = `#${event.target.nextElementSibling.id}`;

    if (cardHolderName && !/^[a-zA-Z\s]*$/.test(cardHolderName)) {
      $(errorElementId).text('Card Holder Name should contain only alphabetic characters.');
      $(event.target).css('margin-bottom', '0px');
      this.disableContinueButton();
    } else {
      $(errorElementId).text('');
      this.enableContinueButton();
    }
  }

  disableContinueButton() {
    const button = document.querySelector('.step-continue-button');
    button.classList.remove('continue-next');
    button.classList.add('opacity-1-unclickable');
  }

  enableContinueButton() {
    const button = document.querySelector('.step-continue-button');
    button.classList.add('continue-next');
    button.classList.remove('opacity-1-unclickable');
  }
}
