export default class Mashup {
  initializeListeners() {
    const targets = '[data-mashup] input, [data-mashup] textarea';
    $(document).on('input', targets, processMashup);
    $(targets).toArray().forEach(target => $(target).trigger('input'));
  }
}

const processMashup = (event) => {
  const targetSelector = $(event.currentTarget).parents('[data-mashup]').first().data('target');
  let value = "---\n";

  $(`[data-mashup][data-target='${targetSelector}']`).toArray().forEach(group => {
    value += '- ';

    $(group).find('input, textarea').toArray().forEach((input, idx) => {
      if (idx !== 0) {
        value += '  ';
      }

      let inputValue = $(input).val().replace(/\n/gi, ';');
      value += `${$(input).attr('name')}: ${inputValue}\n`;
    });

    $(targetSelector).val(`${value}\n`);
  });
};
