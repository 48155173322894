import { Controller } from 'stimulus';

export default class extends Controller {
  confirmUpdateModal(event) {
    if ($(`#${event.target.id}`).is(':checked')) {
      alert('You have selected to send review requests on daily basis.');
    }
    else {
      alert('This will disable review requests untill you run manually.');
    }
  }
};
