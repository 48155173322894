// TODO: Check if this is still needed
export default class ScaleValue {
  initializeListeners() {
    $(document).on('click', '[data-slide-scale-value]', (event) => {
      event.preventDefault();

      const container = $(event.currentTarget).parents('[data-slide-scale]');
      const value = $(event.currentTarget).data('slide-scale-value');
      container.find('[data-slide-scale-value]').removeClass('active');
      $(event.currentTarget).addClass('active');

      new Foundation.Slider(container.find('.slider'), {initialStart: value});
    });

    $(document).on('moved.zf.slider', '.slider', (event) => {
      const container = $(event.currentTarget).parents('[data-slide-scale]');
      const value = container.find('[data-slide-scale-watch]').val();
      container.find('[data-slide-scale-value]').removeClass('active');
      container.find("[data-slide-scale-value='#{value}']").addClass('active');
    });
  }
}
