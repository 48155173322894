export default class {
  constructor(controller, target) {
    this.controller = controller;
    this.target = target && this.controller.targets.find(target);
  }

  isValid(value) {
    if (!this.target || !this.target.offsetParent) {
      return true;
    }

    return !!value;
  }
}
