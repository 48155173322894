export default class Taggable {
  initializeListeners() {
    $('[data-taggable]').toArray().forEach(input => {
      $(input).tagsInput({
        height: 'auto',
        autocomplete_url: `/tags/${$(input).data('context')}`,
        delimiter: "|",
        defaultText: ''
      });

      $(input).importTags($(input).data('tags'));
    });

    $(document).on('blur', '.tagsinput input', (event) => {
      const fakeInput = $(event.target);
      const realInput = fakeInput.parents('.tagsinput').siblings('input[data-tagsinput-init="true"]');
      const value = fakeInput.val();

      if (value.length) {
        $(realInput).addTag(value, {focus: false, unique: true})
      }
    });
  }
}
