import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("load", () => {
      if (window.location.hash) {
        var element = document.querySelector(window.location.hash);
        if (element) {
          var offset = 100;
          var elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          var offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });

          element.classList.add("highlight");
          setTimeout(function () {
            element.classList.remove("highlight");
          }, 3000);
        }
      }
    });
  }
}
