import { Controller } from 'stimulus';
import { openModal } from '../components/modal';
import Alert from '../error/notice';
import { DIRECTORY_COUNTRY_CODES } from './constants';


export default class extends Controller {

  initialize() {
    if ($(location).attr('hash') == '#avionte' || $(location).attr('hash') == '#labor-edge') {
      $("#unlocked-modal")[0].classList.remove('is-hidden');
    }
  }

  updateRequiredAttribute() {
    const locationsList = document.getElementById('locations-list');
    const areaListSelect = document.getElementById('area-list-select2');

    if (locationsList && areaListSelect) {
      if (locationsList.classList.contains("is-hidden")) {
        areaListSelect.required = false;
      } else {
        areaListSelect.required = true;
      }
    }
  }

  connect() {
    this.updateRequiredAttribute();

    $('#industry-select2').select2({
      placeholder: "Select industries",
      allowClear: true,
      tags: true
    });

    let individualCountry = $('#individual_country')
    if (individualCountry) {
      let individualCountryValue = individualCountry.val()
      if (individualCountryValue != "false") {
        $('#user_recruiting_country').append(`<option class="user_recruiting_country-option" value=${individualCountryValue} selected>${individualCountryValue}</option>`)
        $('#user_recruiting_country').select2({
          placeholder: 'Add Country',
          width: '100%',
          ajax: {
            url: '/google_places/autocomplete',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                term: params.term
              };
            },
            processResults: function (data) {
              return {
                results: data.places.map(function (place) {
                  return {
                    text: place,
                    id: place
                  };
                })
              };
            }
          }
        });
      }
    }

    var checkbox = document.getElementById('location-checkbox');
    if(checkbox){
      document.getElementById('user_recruiting_country').required = checkbox.checked;
    }

    $("#area-list-select2").change(function(){
      $('#user-profile-area-list .select2-search__field').css('margin-top','0.3rem')
    });

    $("#area-list-select2").click(function(){
      $('#user-profile-area-list .select2-search__field').css('margin-top','0.3rem')
    });

    $('#area-list-select2').select2({
      placeholder: 'Add Locations',
      minimumInputLength: 2,
      allowClear: true,
      ajax: {
        url: '/google_places/autocomplete',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            term: params.term
          };
        },
        processResults: function (data) {
          return {
            results: data.places.map(function (place) {
              return {
                text: place,
                id: place
              };
            })
          };
        }
      }
    });

    const spans = document.querySelectorAll("span#check-num");
    spans.forEach((span, index) => {
      if (span.textContent.trim().match(/\d+\.\d+/g)[0].split('.')[1].length < 2) {
        span.querySelectorAll('span')[1].style.paddingLeft = '0.4rem';
      }
    });

    let elements = document.querySelectorAll('.style-value-chart');
    let maxLen = 0;
    let arr = Array.from(elements, element => {
      let val = parseInt(element.textContent.replace(/,/g, ''));
      maxLen = Math.max(maxLen, val.toString().length);
      return val;
    });
    let numWidth = 13 + maxLen;
    if (maxLen > 3) {
      numWidth += (maxLen - 3) * 2;
      elements.forEach((element) => {
        element.style.width = `${numWidth}%`;
      });
    }

    this.configureDropdown('.companies_filter_id');
    this.configureDropdown('.companies_filter_users');
    this.configureDropdown('.activity_logs_filter_user__id');
  }

  configureDropdown(selector) {
    const elements = $(selector);
    if (elements.length) {
      const dropdownLinks = elements.find('span').find('a');
      dropdownLinks.css('text-indent', '0');
      dropdownLinks.addClass('companies-dropdown');
    }
  }

  showUserCount(event) {
    let active_user_count_parent = ''
    let active_user_count_child = ''
    if (event.target.id == 'company_max_user_limit'){
      active_user_count_parent = '#billing_user_count'
      active_user_count_child = '#company_max_user_count'
    } else {
      active_user_count_parent = '#admin-user-count'
      active_user_count_child = '#company_admin_count.numeric.integer.required'
    }
    if(event.target.checked){
      if (event.target.id == ''){}
      $(active_user_count_parent).removeClass('is-hidden');
      $(active_user_count_child).attr("required","required");
    } else {
      $(active_user_count_parent).addClass('is-hidden');
      $(active_user_count_child).removeAttr("required");
      $(active_user_count_child).val("");
    }
  }

  toggleInputField(event) {
    const targetDiv = $(event.target).closest('div.column').find('.limit-input');
    const inputField = targetDiv.find('input');

    if(event.target.checked){
      targetDiv.removeClass('is-hidden');
      targetDiv.show();
      inputField.attr('required', 'required');
    } else {
      targetDiv.hide();
      inputField.removeAttr('required');
      inputField.val('');
    }
  }

  validateImageExtension(event) {
    var file = document.querySelector('#image-upload');
    if ( /\.(jpe?g|png|gif)$/i.test(file.files[0].name) === false )
    {
      file.value = null
      $('#file-type-error').text('Only image files are allowed')
    }
    else
    {
      $('#file-type-error').text('')
    }
  }

  adminManagerLimitPopUp(event) {
    const user_id = event.target.closest('span').id;
    const role = event.target.id
    $.ajax(this.data.get('url'), {
      method: 'get',
      data: { user_id: user_id, role: role },
      success: (response) => {
        if ($(`#${role}`).is(':checked') && response.limit_reached ) {
          openModal(`#${role}-limit-reached`)
          $(`#${role}`).prop('checked', false);
        }
      },
      error: (response) => {
        alert('An error occuered.');
      }
    })
  }

  toggleLocationFields(event) {
    var checkbox = document.getElementById('location-checkbox');

    var user_country_input = document.getElementById('user_recruiting_country');
    if (user_country_input)
      document.getElementById('user_recruiting_country').required = checkbox.checked;

    if(checkbox.checked) {
      $('#country-select').removeClass('is-hidden');
      $('#locations-list').addClass('is-hidden');
      document.getElementById('user_recruiting_country').required = true;
      document.getElementById('area-list-select2').required = false;
    } else {
      $('#country-select').addClass('is-hidden');
      $('#locations-list').removeClass('is-hidden');
      document.getElementById('user_recruiting_country').required = false;
      document.getElementById('area-list-select2').required = true;
    }
  }

  gatedFeatureModal(event) {
    const lockedContainer = event.target.id;
    if (lockedContainer == 'bullhorn-label') {
      $('#unlocked-modal')[0].classList.add('is-hidden');
    } else {
      $('#unlocked-modal')[0].classList.remove('is-hidden');
    }
  }

  validateCSVExtension(event) {
    const file = $('#csv-upload')[0]
    if ( /\.(csv)$/i.test(file.files[0].name) === false )
    {
      file.value = null
      $('#csv-file-type-error').text('Only csv files are allowed')
    }
    else
    {
      $('#csv-file-type-error').text('')
    }
  }

  submitForm(event) {
    const trCompany = event.currentTarget.dataset['companyType'].includes('tr_sync_settings');
    if (trCompany) {
      const dropDown = JSON.parse(event.currentTarget.dataset['dropdown']);
      const eighteenCharacters = [
        'Id', 'OwnerId', 'RecordTypeId', 'CreatedById', 'LastModifiedById',
        'AVTRRT__Account_Job__c', 'AVTRRT__Account_Manager__c', 'AVTRRT__Candidate_Search__c',
        'AVTRRT__Deal_Sheet_Template__c', 'AVTRRT__Deal_Sheet__c', 'AVTRRT__Hiring_Manager__c',
        'AVTRRT__Recruiter__c', 'AVTRRT__Sales_Opportunity__c', 'Master_Job__c', 'Program_Manager__c',
        'Recruiter_2__c', 'Recruiter_3__c', 'Sector_Field_Office__c', 'Billing_Sector__c',
        'Facility_Location__c', 'Timesheet_Approver__c', 'Round_Robin_Queue__c', 'Survey_Campaign__c'
      ];

      const booleanTrFields = [
        'IsDeleted', 'AVTRRT__Billable__c', 'AVTRRT__Call_em_all_Scheuler_Completed__c',
        'AVTRRT__Closed__c', 'AVTRRT__Interview_Feedback_Questionnaire_Is_Set__c',
        'AVTRRT__Notify_All_Recruiters__c', 'AVTRRT__Notify_All_Vendors__c', 'AVTRRT__Publish__c',
        'AVTRRT__Update_Skills__c', 'AVTRRT__isRequired__c', 'BCST__Posted_to_Broadbean__c',
        'Drug_Screening__c', 'Employment_Verification__c', 'Privileged_Position__c',
        'References_Required__c', 'Shift__c', 'Bid__c', 'Indeed_Publish__c', 'Publish_Url_for_Indeed__c',
        'Remote_Address__c', 'Guaranteed_Shifts__c', 'Holidays_Included__c', 'Hot_Job__c',
        'Marketing_Efforts__c', 'Orientation_Billable__c', 'Overtime_Boolean__c',
        'Travel_Reimbursement__c', 'Evergreen__c', 'Current_Recruiter__c', 'My_Record__c',
        'Bonus__c', 'Bonus_Type__c', 'Bonus_Amount__c', 'Pecos_Legacy_Job__c' ];

      const numericTrFields = [
        'AVTRRT__Adverse_Impact_Percentage__c', 'AVTRRT__Bill_Rate__c', 'AVTRRT__DealSheet_Default_Pay_Rate__c',
        'AVTRRT__DealSheet_Hours_Per_Day__c', 'AVTRRT__Deal_Sheet_Hours_Per_Week__c', 'AVTRRT__Default_Pay_Rate__c',
        'AVTRRT__Lat__c', 'AVTRRT__Lon__c', 'AVTRRT__Miles_for_Search__c', 'AVTRRT__Number_of_Applicants__c',
        'AVTRRT__Number_of_Female_Applicants__c', 'AVTRRT__Number_of_Interviews__c', 'AVTRRT__Number_of_Male_Applicants__c',
        'AVTRRT__Number_of_Minority_Applicants__c', 'AVTRRT__Number_of_Non_Minority_Applicants__c',
        'AVTRRT__Number_of_Placements__c', 'AVTRRT__Number_of_Positions__c', 'AVTRRT__Prob_of_Win__c',
        'AVTRRT__Result_Score__c', 'AVTRRT__Travel_Per__c', 'Holiday_Rate__c', 'Orientation_Rate__c',
        'Overtime_Rate__c', 'Per_Diem_Allowance__c', 'Job_Robotix_ID__c', 'Candidates_Identified__c',
        'Candidates_in_Pipeline__c', 'Working_Candidates__c', 'Positions_Available__c',
        'Estimated_Weekly_Hours_Required__c', 'Key_Metric__c', 'Specialty_Percentage__c',
        'Required_Weekly_Hours__c', 'Identified_Full_time__c', 'Identified_PRN__c', 'Identified_Part_time__c',
        'Working_Full_time__c', 'Working_PRN__c', 'Working_Part_time__c', 'Pay_Rate__c',
        'In_Process_Applicants__c', 'Pipeline_Applicants__c', 'Working_Applicants__c', 'MDE_Surge_Positions_Filled__c' ];

      const dateTimeTrFields = [
        'CreatedDate', 'LastModifiedDate', 'SystemModstamp', 'LastViewedDate', 'LastReferencedDate',
        'AVTRRT__Broadcasted_On__c', 'AVTRRT__Hired_Date__c', 'Last_Modified_Date__c' ];

      const dateTrFields = [
        'LastActivityDate', 'AVTRRT__End_Date__c', 'AVTRRT__Estimated_Close_Date__c',
        'AVTRRT__Start_Date__c', 'Indeed_Publish_Date__c', 'Legacy_Exported_date__c', 'Legacy_client_Interview__c',
        'Published_date__c' ];

      const nestedFields = document.querySelectorAll('.nested-fields');
      let showAlert = false;
      let alert = new Alert;

      for (let i = 0; i < nestedFields.length; i++) {
        const nestedField = nestedFields[i];
        const inputElement = nestedField.querySelector('.matches_input').value;
        const inputField = nestedField.querySelector('.job_setting_fields_name select').value;
        const inputFieldIndex = dropDown.findIndex(item => item[1] === inputField);
        const inputFieldValue = dropDown[inputFieldIndex][0];

        if (eighteenCharacters.includes(inputField)) {
          if (inputElement.length < 18) {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input length is smaller than 18 characters!');
            break;
          }
        } else if (booleanTrFields.includes(inputField)) {
          if (inputElement !== 'true' && inputElement !== 'false') {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input value must be boolean!');
            break;
          }
        } else if (numericTrFields.includes(inputField)) {
          if (!/^\d+$/.test(inputElement)) {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input value must be numeric!');
            break;
          }
        } else if (dateTimeTrFields.includes(inputField)) {
          if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(inputElement)) {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input Expected: YYYY-MM-DDTHH:mm:ss.sssZ');
            break;
          }
        } else if (dateTrFields.includes(inputField)) {
          if (!/^\d{4}-\d{2}-\d{2}$/.test(inputElement)) {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input Expected: Expected: YYYY-MM-DD');
            break;
          }
        } else if ('Shift_Time__c'.includes(inputField)) {
          if (!/^\d{1,2}:\d{2}:\d{2}\.\d{1,3}:\w{1,3}$/.test(inputElement)) {
            showAlert = true;
            alert.flashImageMessage(inputFieldValue,'Input Expected: Expected: H:M:S.L:z');
            break;
          }
        }
      }

      event.preventDefault();

      if (!showAlert) {
        $('#date-submit')[0].form.submit();
      }
    }
  }
}
