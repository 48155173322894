export default class CustomQuestions {
  initializeListeners() {
    $('#custom-questions').on('change', '.js-review-type-reference-point', (e) => {
      hideQuestions();
      showQuestions($(`.js-custom-questions-reference-point-id input[value='${$(e.currentTarget).val()}']`));
      $('.add-custom-question .button').attr('disabled', $('.custom-question:not(.is-hidden)').length > 4);
    }).on('change', '.js-use-uniq-custom-questions', useUniqCustomQuestions)
      .on('cocoon:before-insert', beforeInsertCustomQuestion)
      .on('cocoon:after-remove', afterRemoveCustomQuestion)
      .on('cocoon:after-insert', afterInsertCustomQuestion);

    this.showCorrectQuestions();
  }

  showCorrectQuestions() {
    if ($('.js-use-uniq-custom-questions').is(':checked')) {
      $('.js-review-type-reference-points').removeClass('is-hidden');
      hideQuestions();
      showQuestions($(`.js-custom-questions-reference-point-id input[value='${$('.js-review-type-reference-point').val()}']`));
    } else {
      $('.js-review-type-reference-points').removeClass('is-hidden').addClass('is-hidden');
      hideQuestions();
      showGeneralQuestions();
    }
  }
}

const afterInsertCustomQuestion = (e) => {
  if ($('.custom-question:not(.is-hidden)').length > 4) {
    $('.add-custom-question .button').attr('disabled', true);
  }
};

const afterRemoveCustomQuestion = (e) => {
  const $hiddenCustomQuestions = $('.custom-question:not(.is-hidden)');

  $hiddenCustomQuestions.each((index, customQuestion) => {
    $(customQuestion).find('.js-custom-questions-question textarea').attr('placeholder', `Question ${index + 1} text referencing [reviewee_first_name]`);
  });

  if ($hiddenCustomQuestions.length < 5) {
    $('.add-custom-question .button').attr('disabled', false);
  }
};

const beforeInsertCustomQuestion = (e, insertItem) => {
  const customQuestionsLength = $('.custom-question:not(.is-hidden)').length;

  if (customQuestionsLength > 4) {
    return false;
  }

  // Assign item to a reference point
  if ($('.js-use-uniq-custom-questions').is(':checked')) {
    insertItem.find('.js-custom-questions-reference-point-id input').val($('.js-review-type-reference-point').val());
  }

  insertItem.find('.js-custom-questions-question textarea').attr('placeholder', `Question ${customQuestionsLength + 1} text referencing [reviewee_first_name]`)
};

const hideQuestions = () => {
  $('.custom-question').removeClass('is-hidden').addClass('is-hidden');
};

const showQuestions = ($questions) => {
  $questions.parents('.custom-question').removeClass('is-hidden');
};

const showGeneralQuestions = () => {
  const questions = $('.js-custom-questions-reference-point-id input').filter(function() {
    return !this.value;
  });
  showQuestions(questions);
};

const useUniqCustomQuestions = (e) => {
  if ($(e.currentTarget).is(':checked')) {
    $('.js-review-type-reference-points').removeClass('is-hidden');
    hideQuestions();
    showQuestions($(`.js-custom-questions-reference-point-id input[value='${$('.js-review-type-reference-point').val()}']`));
  } else {
    $('.js-review-type-reference-points').removeClass('is-hidden').addClass('is-hidden');
    hideQuestions();
    showGeneralQuestions();
  }

  $('.add-custom-question .button').attr('disabled', $('.custom-question:not(.is-hidden)').length > 4);
};
