import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    new Foundation.DropdownMenu($(this.menuBarElement));
    if ($('.disabled').length > 0)
      $('.disabled :input').prop('disabled', true);

      $(document).on('keydown', (event) => {
        if (event.key == "Escape") {
          this.clearQuery()
          $('#warning-msg').addClass('invisible')
        }
      });
  }

  disconnect() {
    $(this.menuBarElement).foundation('destroy');
  }

  contactSupportModal(event) {
    event.preventDefault();
    openModal('#support-modal')
  }

  closeSupportModal(event) {
    this.clearQuery();
  }

  sendSupportEmail(event) {
    event.preventDefault();
    var query = $('#query').val()
    if(!query || !query.replace(/\s/g, '').length){
      $('#warning-msg').removeClass('invisible')
    }
    else{
      $('#contact-support').submit();
      $('#contact-support-modal').foundation('close')
    }
  }

  clearQuery() {
    let query = $('#query').val()
    if(!query.replace(/\s/g, '').length){
      $('#query').val('')
    }
    $('#warning-msg').addClass('invisible')
  }

  get menuBarElement() {
    return this.targets.find('menuBar');
  }
}
