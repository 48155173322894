import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.interval = undefined;
  }

  connect() {
    this.interval = setTimeout(this.pullStats(this), 2000);
  }

  disconnect() {
    clearTimeout(this.interval);
  }

  pullStats(controller) {
    return () => {
      $.ajax(controller.data.get('url'), {
        success: (data) => {
          controller.targets.findAll('queue').forEach(queue => {
            if (data[queue.dataset['queue']]) {
              queue.querySelector('.sidekiq-queue-name').textContent = `${queue.dataset['queue']} Jobs`;
              queue.querySelector('.sidekiq-queue-size').textContent = data[queue.dataset['queue']];
            }
          });

          controller.targets.find('latency').textContent = data['latency'];
        }
      });

      controller.interval = setTimeout(controller.pullStats(controller), 2000);
    }
  }
}
