export default class EditReview {
  initializeListeners() {
    $(document).on('submit', '.edit_review, .new_review', (event) => {
      const $parent = $('.review_reference_point_id');

      if (!$parent.find('.candidate-stage-radio').length) {
        return true;
      }

      if ($parent.find('.candidate-stage-radio:checked').length) {
        return true;
      }

      $parent.find('.radio').addClass('is-error');
      event.preventDefault();
      return false
    }).on('change', '.candidate-stage-radio', (event) => {
      $('.review_candidate_stage .is-error').removeClass('is-error');
    })
  }
}
