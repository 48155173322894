import { Controller } from "stimulus"
import consumer from "../channels/consumer";

export default class extends Controller {
  connect() {
    const controller = this;

    this.subscription = consumer.subscriptions.create('DashboardChannel', {
      connected() {
        console.log('connecting to dashboard channel');
      },
      disconnected() {
        console.log('disconnected from dashboard channel');
      },
      received(data) {
        controller.showBullhornStatus(data.status)
      }
    });
  }

  showBullhornStatus(status) {
    let statusPill = $('.c-pill');

    if ( status  == 'Active' ) {
      statusPill.addClass('c-pill--success');
      statusPill.removeClass('c-pill--danger');
    } else {
      statusPill.addClass('c-pill--danger');
      statusPill.removeClass('c-pill--success');
    }
    statusPill.html(status).show();
  }
}
