export default class ReferencePoints {
  initializeListeners() {
    $('.reference-points-sortable').sortable({
      update: (e, ui) => {
        $('.reference-point').each((index, referencePoint) => {
          $(referencePoint).find('.js-reference-points-position input').val(index);
        });
      }
    });

    $('#reference-points').on('cocoon:before-insert', beforeInsertReferencePoint)
      .on('cocoon:after-remove', afterRemoveReferencePoint)
      .on('cocoon:after-insert', afterInsertReferencePoint);
  }
}

const beforeInsertReferencePoint = (e, insertItem) => {
  const referencePointsLen = $('.reference-point:visible').length;
  if (referencePointsLen > 5) {
    return false;
  }

  insertItem.find('.js-reference-points-name input').attr('placeholder', `Reference Point ${referencePointsLen + 1}`);
  insertItem.find('.js-reference-points-position input').val(referencePointsLen);
};

const afterInsertReferencePoint = (e) => {
  if ($('.reference-point:visible').length > 5) {
    $('.add-reference-point .button').attr('disabled', true);
  }
};

const afterRemoveReferencePoint = (e) => {
  const $visibleReferencePoints = $('.reference-point:visible');

  $visibleReferencePoints.each((index, referencePoint) => {
    $(referencePoint).find('.js-reference-points-name input').attr('placeholder', `Reference Point ${index + 1}`);
    $(referencePoint).find('.js-reference-points-position input').val(index);
  });

  if ($visibleReferencePoints.length < 6) {
    $('.add-reference-point .button').attr('disabled', false);
  }
};
