export default class CustomReviewSelect {
  initializeListeners() {
    $('.js-custom-review-select').on('change', (event) => {
      changeFormActionAndSubmit(event, /custom_reviews\/\d+/, (val) => `custom_reviews/${val}`);
    });

    $('.js-super-custom-review-select').on('change', (event) => {
      changeFormActionAndSubmit(event, /notifications\/.*\/edit/, (val) => `notifications/${val}/edit`);
    });
  }
}

const changeFormActionAndSubmit = (event, actionRegex, newActionFn) => {
  event.preventDefault();
  const $this = $(event.currentTarget);
  const $form = $this.parents('form');
  const url = $form.attr('action').replace(actionRegex, newActionFn($this.val()));
  const ats_header = $('.ats-header')[0];
  if (!ats_header) {
    $form[0].querySelector('input').value = new URLSearchParams(url.slice(url.indexOf('?'))).get('redirect_url');
  }

  $form.attr('action', url);
  $form.submit();
}
