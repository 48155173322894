// TODO: Check if this is still needed
export default class ScrollWatch {
  initializeListeners() {
    $(window).scroll(() => {
      const $scrollWatch = $('[data-scroll-watch]');

      if (!$scrollWatch.length) {
        return true;
      }

      $scrollWatch.toArray().forEach(scrollable => {
        const trigger = (parseFloat($(scrollable).data('scroll-watch')) / 100) * $(window).height();

        if ($(window).scrollTop() >= trigger) {
          $(scrollable).addClass($(scrollable).data('scroll-toggle'));
        } else {
          $(scrollable).removeClass($(scrollable).data('scroll-toggle'));
        }
      });
    });
  }
}
