import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#companies-select').select2({
      width:'100%',
      placeholder: 'Select Companies',
    });

    $('#users-select').select2({
      width:'100%',
      placeholder: 'Select Users',
    });

    $('#companies-select-edit').select2({
      width:'100%'
    });

    $('#users-select-edit').select2({
      width:'100%',
    });
  }

  CreateCompanyAdmin(event) {
    event.preventDefault();
    openModal('#create-company-admin-modal');
  }

  cancelAction (event) {
    event.preventDefault();
    window.location.reload();
  }

  DeleteCompanyAdmin(event) {
    event.preventDefault();
    const modal = document.getElementById('delete-company-admin-modal');
    const recordId = event.currentTarget.dataset['target'];
    let yesLink = modal.querySelector('#delete-company-admin-record');
    yesLink.href = `/company_admins/${recordId}`;

    openModal('#delete-company-admin-modal');
  }

  EditCompanyAdmin(event) {
    event.preventDefault();
    let user_id = this.element.getAttribute("user_id");
    let company_id = this.element.getAttribute("company_id");
    let record_id = this.element.getAttribute("record_id");

    const form = document.getElementById('edit-company-admin-form');
    const updatedUrl = `/company_admins/${+record_id}`;
    form.action = updatedUrl;

    const selectedUser = $('#users-select-edit');
    const selectedCompany = $('#companies-select-edit');

    selectedUser.val(user_id).trigger('change');
    selectedCompany.val(company_id).trigger('change');

    openModal('#edit-company-admin-modal');
  }
}
