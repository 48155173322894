import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    this.badgepage = 0;
    this.awardpage = 0;
    this.milestonepage = 0;
    this.badgeAccordionExecuted = false;
    this.awardAccordionExecuted = false;
    this.milestoneAccordionExecuted = false;
  }

  scrollToTop(event) {
    event.preventDefault();

    const targetId = this.element.dataset.achievements;
    const targetAccordion = document.getElementById(targetId);
    if (targetAccordion) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  verifyBadges() {
    const accordionIcon = document.querySelector('.badge-accordion-active-icon');
    accordionIcon.classList.add('criteria-icon');
  }

  verifyTopRatedRecruiters() {
    const accordionIcon = document.querySelector('.top-rated-accordion-active-icon');
    accordionIcon.classList.add('criteria-icon');
  }

  verifyMilestones() {
    const accordionIcon = document.querySelector('.milestones-accordion-active-icon');
    accordionIcon.classList.add('criteria-icon');
  }

  loadMoreAccordion() {
    const criteriaHash = window.location.hash;
    const criteria = criteriaHash.substring(1);

    if (criteria === "Badges") {
      if (!this.badgeAccordionExecuted) {
        this.loadMoreAchievements(criteria);
      }
      this.badgeAccordionExecuted = true;
    } else if (criteria === "TopRatedRecruiters") {
      if (!this.awardAccordionExecuted) {
        this.loadMoreAchievements(criteria);
      }
      this.awardAccordionExecuted = true;
    } else if (criteria === "Milestones") {
      if (!this.milestoneAccordionExecuted) {
        this.loadMoreAchievements(criteria);
      }
      this.milestoneAccordionExecuted = true;
    }
  }

  loadMoreButton() {
    this.loadMoreAchievements(window.location.hash.substring(1));
  }

  loadMoreAchievements(criteria) {
    let url;

    if (criteria === "Badges") {
      this.badgepage++;
      url = `${window.location.origin}/badge_criteria?page=${this.badgepage}`;
    } else if (criteria === "TopRatedRecruiters") {
      this.awardpage++;
      url = `${window.location.origin}/award_criteria?page=${this.awardpage}`;
    } else if (criteria === "Milestones") {
      this.milestonepage++;
      url = `${window.location.origin}/milestone_criteria?page=${this.milestonepage}`;
    }
    const loadMoreButtonId = `loadMore${criteria}`;
    const loadMoreButton = document.getElementById(loadMoreButtonId);

    if (loadMoreButton) {
      loadMoreButton.style.display = "none";
    }

    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok && response.status !== 422) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (data) {
          const criteriaId = `${criteria.toLowerCase()}-criteria`;
          const dataId = `${criteria.toLowerCase()}-data`;
          const criteriaData = document.getElementById(criteriaId);

          if (criteriaData) {
            if (loadMoreButton) {
              loadMoreButton.style.display = data.last_page ? "none" : "flex";
            }
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = data.content;
            const dataContainer = criteriaData.querySelector(`.${dataId}`);

            if (dataContainer) {
              dataContainer.appendChild(tempDiv);
            }
          }
        } else {
          console.error("Invalid server response");
        }
      })
      .catch((error) => console.error(`Error fetching ${criteria.toLowerCase()}:`, error));
  }

}
