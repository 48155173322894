import AbsoluteAdjust from './absoluteAdjust';
import ActiveToggle from './activeToggle';
import Alert from './alert';
import Analytics from './analytics';
import BackgroundLogo from './backgroundLogo';
import Copy from './copy';
import ConditionalInputGroupToggle from "./conditionalInputGroupToggle";
import DisplayProfile from "./displayProfile";
import DownloadButton from "./downloadButton";
import FoundationComponents from "./foundationComponents";
import DuplicateFormField from "./duplicateFormField";
import Impersonate from "./impersonate";
import Mashup from "./mashup";
import Modal from "./modal";
import Pickdate from "./pickdate";
import PositionWatch from "./positionWatch";
import PublicReviewActions from "./publicReviewActions";
import Removable from "./removable";
import ScaleValue from "./scaleValue";
import ScrollWatch from "./scrollWatch";
import ShareButtons from "./shareButtons";
import Sidebar from "./sidebar";
import Sortable from "./sortable";
import Taggable from "./taggable";
import TempUploadImage from "./tempUploadImage";
import TextLimit from "./textLimit";
import Tooltips from "./tooltips";
import UniqueCheckbox from "./uniqueCheckbox";
import Upload from "./upload";
import ReferencePoints from "./referencePoints";
import CustomQuestions from "./customQuestions";
import Combobox from "./combobox";
import EmojiScore from "./emojiScore";
import CustomReviewSelect from "./customReviewSelect";
import TriggerButton from "./triggerButton";

export default class Components {
  initializeListeners() {
    $(document).on('ready', () => {
      new AbsoluteAdjust().initializeListeners();
      new ActiveToggle().initializeListeners();
      new Alert().initializeListeners();
      new Analytics().initializeListeners();
      new BackgroundLogo().initializeListeners();
      new Combobox().initializeListeners();
      new ConditionalInputGroupToggle().initializeListeners();
      new Copy().initializeListeners();
      new CustomQuestions().initializeListeners();
      new CustomReviewSelect().initializeListeners();
      new DisplayProfile().initializeListeners();
      new DownloadButton().initializeListeners();
      new EmojiScore().initializeListeners();
      new FoundationComponents().initializeListeners();
      new DuplicateFormField().initializeListeners();
      new Impersonate().initializeListeners();
      new Mashup().initializeListeners();
      new Modal().initializeListeners();
      new Pickdate().initializeListeners();
      new PositionWatch().initializeListeners();
      new TriggerButton().initializeListeners();
      new PublicReviewActions().initializeListeners();
      new ReferencePoints().initializeListeners();
      new Removable().initializeListeners();
      new ScaleValue().initializeListeners();
      new ScrollWatch().initializeListeners();
      new ShareButtons().initializeListeners();
      new Sidebar().initializeListeners();
      new Sortable().initializeListeners();
      new Taggable().initializeListeners();
      new TempUploadImage().initializeListeners();
      new TextLimit().initializeListeners();
      new Tooltips().initializeListeners();
      new UniqueCheckbox().initializeListeners();
      new Upload().initializeListeners();
    });
  }
}
