// TODO: Check if this is still needed
export default class ConditionalInputGroupToggle {
  initializeListeners() {
    $(document).on('click', '.conditional-input-group-trigger', (event) => {
      if ($(event.target).is(':checked')) {
        $(`#${$(event.target).data('target')}`).addClass('active')
      } else {
        $(`#${$(event.target).data('target')}`).removeClass('active')
      }
    });
  }
}
