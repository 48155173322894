import { Controller } from 'stimulus';
import Alert from '../error/notice'

export default class extends Controller {
  saveConfiguration() {
    $('#text' + this.data.get('field')).keyup(function() {
      let nameField = $(this).closest('form').find('input#submitButton');
      if(($(this).val().trim().length < 1))
        nameField.attr('disabled', true);
      else
        nameField.attr('disabled', false);
    });
  }

  hideAlert(event) {
    event.preventDefault();
    $('#alert').hide();
  }

  async editForm() {
    var id = this.data.get('id');

    fetch(this.data.get('url'), {
      method: 'GET',
    })
    .then(response => response.text())
    .then(result => {
      $(result).insertAfter($('#' + id));
      $('#' + id).hide();
    })
    .catch(error => {
      let alert = new Alert
      alert.flashMessage(error)
    });
  }

  toggle(event) {
    event.preventDefault();
    $('#' + this.data.get('field')).hide();
    $('#' + this.data.get('id')).show();
  }

  deleteConfiguration(event) {
    event.preventDefault();
    const systemConfigurationsId = this.element.dataset.systemConfigurationsId;
    const deleteConfigurationId = document.querySelector("#delete-configuration-modal");
    deleteConfigurationId.setAttribute("data-system-configurations-id", systemConfigurationsId);
    openModal(deleteConfigurationId);
  }

  async saveDeleteConfiguration(event) {
    event.preventDefault();
    const deleteConfigurationId = document.querySelector("#delete-configuration-modal");
    const systemConfigurationsId = deleteConfigurationId.getAttribute("data-system-configurations-id");

    try {
      if (systemConfigurationsId.startsWith('industry')) {
        const response = await fetch(`/supers/industries/${systemConfigurationsId.split('_')[1]}`, {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          },
        });
      }
      else if (systemConfigurationsId.startsWith('role') || systemConfigurationsId.startsWith('placement')) {
        const response = await fetch(`/supers/${systemConfigurationsId.split('_')[0] + 's'}/${systemConfigurationsId.split('_')[1]}`, {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          },
        });
      }
      else {
        const response = await fetch(`/supers/configuration_mappings/${systemConfigurationsId.split('_')[2]}`, {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          },
        });
      }
      location.reload();
    } catch (error) {
      let alert = new Alert
      alert.flashMessage(error)
    }
  }
}
