export default class ShareButtons {
  initializeListeners() {
    $(document).on('click', '.share-facebook', (event) => {
      const data = $(event.target).closest('a').data();

      FB && FB.ui({
        method: 'share',
        href: data.href,
        quote: data.quote
      }, function(response){});
    });
  }
}
