export default class DisplayProfile {
  initializeListeners() {
    $('.pagination a').on('click', $('.pagination a'), function () {
      sessionStorage.setItem('pagination', 'selected');
    });
    $('.clear-search-button').attr('disabled', true);
    if (sessionStorage.getItem('search')) {
      $('.clear-search-button').attr('disabled', false);
      $('.' + sessionStorage.getItem('tab')).addClass('is-active');
    }
    else if (sessionStorage.getItem('recruiter-search')) {
      $('.clear-search-button').attr('disabled', false);
      $('.' + sessionStorage.getItem('tab')).addClass('is-active');
    }

    if (sessionStorage.getItem('clear-search')) {
      $('.clear-search-button').attr('disabled', true);
      sessionStorage.removeItem('clear-search');
    }
  }

  setTabs() {
    if (sessionStorage.tab === 'review-tab') {
      $('.review-tab').addClass('is-active');
    } else if (sessionStorage.tab === 'job-tab') {
      window.location.href = window.location.href.split('#')[0] + '#jobs-tab'
    } else if (sessionStorage.tab === 'recruiter-tab') {
      window.location.href = window.location.href.split('#')[0] + '#recruiters-tab'
    }
    else {
      $('.about-tab').addClass('is-active');
    }
  }
}
