import { openModal } from "./components/modal";

global.openModal = openModal;
global.Cookies = require('cookie');
require('papaparse');
global.autosize = require('autosize.min');

import Components from "./components";
import Forms from "./forms";
import Base from "./base";


new Base().initializeListeners();
new Components().initializeListeners();
new Forms().initializeListeners();

import "./controllers";
require("trix");
require("@rails/actiontext");
