export default class PublicReviewActions {
  initializeListeners() {
    $(document).on('ajax:complete', '.upvote-review', (xhr, status) => {
      if (status.status === 200) {
        $(xhr.target).next('.text-count').html(`(${status.responseText})`);
      }
    });

    $(document).on('ajax:complete', '.flag-review', (xhr, status) => {
      if (status.responseText === 'ok') {
        $('body').prepend('<p class="notice">Review Flagged</p>');
        setTimeout(() => $('p.notice, p.alert, p.top_notice').addClass('fade-out'), 5000);
      }
    });

    if ($('body.companies.show').length > 0 && /sort%5Bby/.test(document.location.search) && document.referrer.indexOf("google") < 0) {
      if (document.querySelector("#recent-reviews")){
        window.scrollTo(0, $('#recent-reviews').offset().top);
      }
    }
  }
}
