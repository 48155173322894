import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide", "dots"];
  static values = { currentIndex: Number };

  connect() {
    this.currentIndex = 0;
    this.showSlide();
  }

  goToSlide(event) {
    const slideIndex = parseInt(event.target.dataset.slideIndex);
    if (this.currentIndex !== slideIndex) {
      this.currentIndex = slideIndex;
      this.showSlide();
    }
  }

  showSlide() {
    this.slideTargets.forEach((element, index) => {
      element.classList.toggle("carousel-slide--visible", index === this.currentIndex);
    });

    const dots = this.dotsTarget.querySelectorAll("button");
    dots.forEach((dot, index) => {
      dot.dataset.current = index === this.currentIndex;
    });
  }

  async BackToList(event) {
    const referrerUrl = event.currentTarget.dataset['referrerUrl'];
    const url = event.currentTarget.dataset['target'];
    const backBtnUrl = event.currentTarget.dataset.backpage;

    if (url !== '') {
      window.location.href = url;
    } else if (referrerUrl) {
      window.location.href = referrerUrl;
    } else if (backBtnUrl){
      window.location.href = backBtnUrl;
    } else {
      history.back();
    }
  }

  isFromReferrer() {
    return (
      document.referrer.includes('industries') ||
      document.referrer.includes('specialties') ||
      document.referrer.includes('locations') ||
      document.referrer.includes('placement_types') ||
      document.referrer.includes('top_recruiters_filter') ||
      document.referrer.includes('top_companies_filter')
    );
  }
}
