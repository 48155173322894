import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.industryChange();

    $('#industries-select').on("change", () => {
      this.industryChange();
    });
  }

  async industryChange() {
    const selectIndustryField = document.querySelector('#industries-select');
    const selectedIndustries = Array.from(selectIndustryField.selectedOptions).map(option => option.value);
    const noRolesFoundElement = document.querySelector('.no-roles-found');

    if(selectedIndustries.length !== 0) {
      noRolesFoundElement.classList.add('display-hidden');
    }

    const rolesArray = [];

    const industryRolesHashString = this.element.getAttribute("data-industry-roles");
    const industryRolesHash = JSON.parse(industryRolesHashString);

    selectedIndustries.forEach(industry => {
      if (industryRolesHash[industry]) {
        rolesArray.push(...industryRolesHash[industry]);
      }
    });

    this.displayRoles(Array.from(new Set(rolesArray)).sort());
  }

  async displayRoles(roles) {
    const displayRolesContainer = document.getElementById("roles-select");
    const currentOptions = Array.from(displayRolesContainer.options);

    currentOptions.forEach((option) => {
      if (!roles.includes(option.value)) {
        option.selected = false;
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    });

    const event = new Event("change");
    displayRolesContainer.dispatchEvent(event);
  }

  async rolesSelected() {
    this.industryChange();
    const selectIndustryField = document.querySelector('#industries-select');
    const selectedIndustries = Array.from(selectIndustryField.selectedOptions).map(option => option.value);
    const noRolesFoundElement = document.querySelector('.no-roles-found');

    if(selectedIndustries.length === 0) {
      noRolesFoundElement.classList.remove('display-hidden');
    } else {
      noRolesFoundElement.classList.add('display-hidden');
    }
  }

  async placementSelected() {
    this.industryChange();
    const selectIndustryField = document.querySelector('#industries-select');
    const selectedIndustries = Array.from(selectIndustryField.selectedOptions).map(option => option.value);
    const noRolesFoundElement = document.querySelector('.no-roles-found');

    if(selectedIndustries.length === 0) {
      noRolesFoundElement.classList.remove('display-hidden');
    } else {
      noRolesFoundElement.classList.add('display-hidden');
    }
  }
}
