import { importAll } from '../utils';

const validatorsCache = {};
importAll(require.context('.', true, /_validator\.js$/), validatorsCache);

export default class {
  static from(controller, validationStr) {
    try {
      const validatorClass = getValidatorClassFrom(validationStr);

      return validatorClass && new validatorClass(controller, getValidatorTargetOrData(validationStr)) || new NullValidator();
    } catch (e) {
      return new NullValidator();
    }
  }
}

class NullValidator {
  isValid() {
    return false;
  }
}

const getValidatorClassFrom = (validationStr) => {
  const validationClass = validationStr.split('->')[0];
  return validatorsCache[validationClass];
};

const getValidatorTargetOrData = (validationStr) => {
  return validationStr.split('->')[1];
};
