export default class Analytics {
  initializeListeners() {
    if (process.env.NODE_ENV !== 'production') {
      return true;
    }

    $('.track-click').on('click', (event) => {
      event.preventDefault();

      trackEventAndGo(
        $(event.currentTarget).attr("data-subject"),
        $(event.currentTarget).attr("data-action"),
        $(event.currentTarget).attr("data-owner"),
        $(event.currentTarget).attr("href"),
        $(event.currentTarget).attr("target")
      );
    });
  }
}

/**
 * Sends an event to GA
 * @param type "Job Board" or "Company Website", etc
 * @param action "clicked"
 * @param company "Kelly Services"
 * @param url The optional location to go to after posting the event
 * @param windowName the optional window to open in.
 */
const trackEventAndGo = (type, action, company, url, windowName) => {
  ga('send', 'event', type, action, company, {
    hitCallback: function () {
      if (url) {
        if (windowName) {
          const win = window.open(url, windowName);
          if (win) {
            win.focus();
          } else {
            window.location = url;
          }
        } else {
          window.location = url;
        }
      }
    }
  });
};
