import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import "chartkick/chart.js"

/* This is your application's ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
    this.updateViewButtonText();
    this.handleFilterChange();
  }

  toggleFilters() {
    const recruiterFilters = document.querySelector('.refine-recruiters-search-tab .filters');
    recruiterFilters.classList.toggle('display-recruiter-filters');
  }

  updateViewButtonText() {
    if (/top_recruiters_filter|top_companies_filter/.test(window.location.href)) {
      const viewProfileButtons = document.querySelectorAll('.view-profile-button');
      const recruiterFilters = document.querySelector('.refine-recruiters-search-tab .filters');
      recruiterFilters.classList.remove('display-recruiter-filters');

      viewProfileButtons.forEach((viewProfileButton) => {
        const linkToElement = viewProfileButton.querySelector('.recruiter-view-profile-button');
        linkToElement.innerText = 'View';
      });
    }
  }

  handleFilterChange() {
    const form = document.getElementById('recruiters-filter-form');
    const inputs = form.querySelectorAll('input, select');

    inputs.forEach((input) => {
      input.addEventListener('change', () => {
        form.requestSubmit();
      });

      if (input.classList.contains('select2-hidden-accessible')) {
        $(input).on('change.select2', () => {
          form.requestSubmit();
        });
      }
    });
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  beforeReflex (element, reflex) {
    // document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, error) {
    // show success message etc...
  }

  reflexError (element, reflex, error) {
    // show error message etc...
  }

  afterReflex (element, reflex) {
    // document.body.classList.remove('wait')
  }
}
