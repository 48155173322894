import { Controller } from 'stimulus';

export default class extends Controller {
  modalsubmitBtn(event){
    $('#modal-submission').val('true');
    $('#preview-form').click();
  }

  async submitBtn(event){
    event.preventDefault();
    $('#preview-form')[0].form.submit();
  }
}
