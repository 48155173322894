import { Controller } from "stimulus";
import AlertController from "./alert_controller";

export default class extends Controller {
  static targets = [
    "form",
    "imagePreview",
    "errorMessage",
    "name",
    "review",
    "rating",
    "referral",
    "badgeIcon",
    "badgeEmailSubject",
    "badgeEmailBody",
    "nameError",
    "reviewError",
    "ratingError",
    "referralError",
    "badgeIconError",
    "badgeEmailSubjectError",
    "badgeEmailBodyError",
    "submitBtn",
    "fileData",
    "dateRange",
    "dateRangeError",
  ];

  connect() {
    flatpickr(".flatpickr-time", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "G:i K",
      dynamic: false,
    });
    this.removeExtraClassFromBody();
    $(".spinner-container").addClass("hide");
    const modalElement = document.getElementById("badge-modal");
    if (modalElement) {
      document.addEventListener(
        "click",
        this.handleClickOutsideModal.bind(this)
      );
    }
  }

  disconnect() {
    document.removeEventListener(
      "click",
      this.handleClickOutsideModal.bind(this)
    );
  }

  removeExtraClassFromBody() {
    const extraClass = this.element.dataset.extraClass;
    if (extraClass) {
      document.body.classList.remove(extraClass);
    }
  }

  handleClickOutsideModal(event) {
    this.closeModal("");
  }

  handleContent(content, table, record) {
    if (!content.trim()) {
      const noDataMessage = document.getElementById(`${record}-no-data-msg`);
      if (!noDataMessage) {
        const [achivementTable] = table.split("-");
        $(`${achivementTable}-div`).append(
          `<h3 class="text-center margin-top-xx-large margin-bottom-large" id="${record}-no-data-msg"> No ${record} available</h3>`
        );
      }
    } else {
      $(table).html(content);
    }
  }

  updateFileName() {
    const input = this.inputTarget;
    const fileNameDisplay = this.fileNameTarget;

    if (input.files.length > 0) {
      const fileName = input.files[0].name;
      fileNameDisplay.textContent = fileName;
      fileNameDisplay.style.display = "block";
    }
  }

  updatePreview() {
    const badge_icon = document.getElementById("badge-icon");
    const badge = document.getElementById("badge_icon_field");
    badge_icon.src = URL.createObjectURL(badge.files[0]);
  }

  handleModal(data, title, url) {
    const badgeModal = document.getElementById("badge-modal");

    if (badgeModal) {
      badgeModal.querySelector(".modal-body").innerHTML = "";
      badgeModal.querySelector(".modal-title").innerText = title;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = data.content;
      badgeModal.querySelector(".modal-body").appendChild(tempDiv);
      const badgeIconUrl = document.getElementById("badge-icon");
      if (badgeIconUrl) {
        this.initializeBadgeImage(badgeIconUrl.src);
      }
      openModal("#badge-modal");
      $("#badge-modal").scrollTop(0);
      history.pushState({}, title, url);
      const closeButton = badgeModal.querySelector(".badge-modal-close");
      closeButton.addEventListener("click", () => {
        this.closeModal(url);
      });
    } else {
      console.error("Error: Modal element not found in the DOM.");
    }
  }

  closeModal(url) {
    const modalElement = document.getElementById("badge-modal");
    const path = new URL(window.location.href);
    const pathSegments = path.href.split("/");

    if (event && modalElement) {
      const tabValues = ["milestones", "badges", "awards"];

      for (const value of tabValues) {
        if (
          pathSegments.includes(value) &&
          pathSegments.includes("scheduled_emails")
        ) {
          history.pushState(null, null, path.path);
        } else if (pathSegments.includes(value)) {
          history.pushState(null, null, `/supers/achievements?types=${value}`);
        }
      }
    } else {
      console.error("Error: Modal element not found in the DOM.");
    }
  }

  initializeBadgeImage(url) {
    const badge_icon = document.getElementById("badge_icon_field");
    fetch(url)
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageName = url.split("/").pop();
        const imageFile = new File([imageBlob], imageName, {
          type: imageBlob.type,
          lastModified: new Date(),
        });

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(imageFile);
        badge_icon.files = dataTransfer.files;

        if (badge_icon.webkitEntries.length) {
          badge_icon.dataset.file = `${dataTransfer.files[0].name}`;
        }
      })
      .catch((error) => {
        const alertController = new AlertController(this.element);
        alertController.flashMessage(
          `Error fetching or creating the file: ${error}`
        );
      });
  }

  fetchDataAndHandleModal(url, title) {
    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok && response.status !== 422) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (typeof data === "object") {
          if (data.content) {
            this.handleModal(data, title, url);
          } else {
            console.error(
              "Error: No or invalid content found in the response data."
            );
          }
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  duplicateModal(event) {
    event.preventDefault();
    const badgeId = this.element.dataset.badgeId;
    const url_parent = this.element.dataset.url;
    const title = this.element.dataset.title;
    const url = `${window.location.origin}${url_parent}`;
    this.fetchDataAndHandleModal(url, title);
  }

  editModal(event) {
    event.preventDefault();
    const badgeId = this.element.dataset.badgeId;
    const url_parent = this.element.dataset.url;
    const title = this.element.dataset.title;
    const url = `${window.location.origin}${url_parent}`;
    this.fetchDataAndHandleModal(url, title);
  }

  newModal(event) {
    event.preventDefault();
    const url = this.element.dataset.url;
    const title = this.element.dataset.title;
    var createBadgeBtn = document.getElementsByClassName("create-badge-btn");
    this.fetchDataAndHandleModal(url, title);
  }

  showModal(event) {
    const badgeId = this.element.dataset.badgeId;
    const url_parent = this.element.dataset.url;
    const title = this.element.dataset.title;
    const url = `${window.location.origin}${url_parent}`;
    this.fetchDataAndHandleModal(url, title);
  }

  deleteBadge(event) {
    event.preventDefault();
    const badgeId = this.element.dataset.badgeId;
    const modal = this.element.dataset.modal;
    const deleteBadgeId = document.querySelector(modal);
    deleteBadgeId.setAttribute("data-badge-id", badgeId);
    openModal(deleteBadgeId);
  }

  async saveDeleteBadge(event) {
    event.preventDefault();
    const modal = this.element.dataset.modal;
    const deleteBadgeId = document.querySelector(modal);
    const badgeId = deleteBadgeId.getAttribute("data-badge-id");
    const url = this.element.dataset.url;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
        },
      });
      location.reload();
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const formData = this.formTarget;
    const badgeData = this.handleDateRangeField(new FormData(this.formTarget));
    const actionUrl = formData.action;
    const url = this.element.dataset.url;
    if (url.includes("type=Badge") && this.validateForm() === true) {
      return false;
    } else if (
      url.includes("type=Milestone") &&
      this.validateFormMilestone() === true
    ) {
      return false;
    } else if (
      url.includes("type=Award") &&
      !url.includes("scheduled_emails") &&
      this.validateFormAward() === true
    ) {
      return false;
    } else if (
      url.includes("awards") &&
      url.includes("scheduled_emails") &&
      this.validateFormScheduledEmail() == true
    ) {
      return false;
    } else {
      this.submitBtnTarget.disabled = true;
      this.handleFormSubmission(formData, badgeData, actionUrl, url);
    }
  }

  handleDateRangeField(formData) {
    const dateRangeField = document.getElementById("date-range-picker");
    if (dateRangeField) {
      const [startDate, endDate] = dateRangeField.value.trim().split("-");
      formData.append(
        "award[start_date]",
        moment(startDate.trim(), "MM-DD-YYYY").format("DD-MM-YYYY")
      );
      formData.append(
        "award[end_date]",
        moment(endDate.trim(), "MM-DD-YYYY").format("DD-MM-YYYY")
      );
      return formData;
    } else {
      return formData;
    }
  }

  async handleFormSubmission(formData, badgeData, actionUrl, url) {
    let method;
    if (formData.id) {
      method = "PUT";
    } else {
      method = "POST";
    }

    try {
      const response = await fetch(actionUrl, {
        method: method,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
          Accept: "application/json",
        },
        body: badgeData,
      });

      if (response.ok) {
        window.location.replace(url);
      } else if (response.status === 422) {
        const data = await response.json();
        const badge_name_error = data.errors;
        const errorContainer = document.getElementById("error-container");
        if (errorContainer) {
          errorContainer.innerHTML = "";

          if (badge_name_error && badge_name_error.length > 0) {
            badge_name_error.forEach((error) => {
              const errorText = document.createElement("span");
              errorText.textContent = error;
              errorText.classList.add("error");
              errorContainer.appendChild(errorText);
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  validateForm() {
    return (
      this.validateName() ||
      this.validateReview() ||
      this.validateRating() ||
      this.validateBadgeIcon() ||
      this.validateBadgeEmail()
    );
  }

  validateFormMilestone() {
    return (
      this.validateName() ||
      this.validateReview() ||
      this.validateReferrals() ||
      this.validateBadgeIcon() ||
      this.validateBadgeEmail()
    );
  }

  validateFormAward() {
    return (
      this.validateName() ||
      this.validateReview() ||
      this.validateBadgeIcon() ||
      this.validateBadgeEmail() ||
      this.validateDateRangeField()
    );
  }

  validateFormScheduledEmail() {
    return (
      this.validateBadgeEmail() ||
      this.validateScheduledDate() ||
      this.validateScheduledTime()
    );
  }

  validateName() {
    const name = this.nameTarget.value;
    if (name.length <= 0) {
      this.nameErrorTarget.classList.remove("hide");
      this.nameErrorTarget.innerText = "Name is required.";
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (name.length > 50) {
      this.nameErrorTarget.classList.remove("hide");
      this.nameErrorTarget.innerText =
        "The name should not exceed 50 characters in length.";
      this.submitBtnTarget.disabled = true;
    } else {
      this.nameErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateDateRangeField() {
    const dateRange = $("#date-range-picker").val();
    if (dateRange.length <= 0) {
      $("#date-range-picker-error").removeClass("hide");
      $("#date-range-picker-error").html("Please select a date range");
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      $("#date-range-picker-error").addClass("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateReferrals() {
    const referral = this.referralTarget.value;
    if (referral.length == 0 || referral == null) {
      this.referralErrorTarget.innerText = "Referral is required.";
      this.referralErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (referral < 0) {
      this.referralErrorTarget.innerText =
        "Please enter a number greater than 0.";
      this.referralErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
    } else {
      this.referralErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateReview() {
    const review = this.reviewTarget.value;
    if (review.length == 0 || review == null) {
      this.reviewErrorTarget.innerText = "Review is required.";
      this.reviewErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (review < 0) {
      this.reviewErrorTarget.innerText =
        "Please enter a number greater than 0.";
      this.reviewErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
    } else {
      this.reviewErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateRating() {
    const rating = this.ratingTarget.value;
    if (rating.length == 0 || rating == null) {
      this.ratingErrorTarget.innerText = "Rating is required.";
      this.ratingErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (rating < 0 || rating > 5) {
      this.ratingErrorTarget.innerText = "Rating should be between 0 to 5.";
      this.ratingErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
    } else {
      this.ratingErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateBadgeEmail() {
    return this.validateEmailSubject() || this.validateEmailBody();
  }

  validateScheduledDate() {
    const scheduledDate = $("#email-scheduled-date").val();
    const currentDate = new Date().toISOString().split("T")[0];
    if (!scheduledDate) {
      $("#email-scheduled-date-error").removeClass("hide");
      $("#email-scheduled-date-error").html("Date is required.");
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (scheduledDate < currentDate) {
      $("#email-scheduled-date-error").removeClass("hide");
      $("#email-scheduled-date-error").html(
        "Please select a Date in the future."
      );
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      $("#email-scheduled-date-error").addClass("hide");
      $("#email-scheduled-date-error").html("");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateScheduledTime() {
    const scheduledTime = $("#email-scheduled-time").val();
    const scheduledDate = $("#email-scheduled-date").val();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date();
    const scheduledTimeParsed = Date.parse(`${scheduledDate} ${scheduledTime}`);
    if (!scheduledTime) {
      $("#email-scheduled-time-error").removeClass("hide");
      $("#email-scheduled-time-error").html("Time is required.");
      this.submitBtnTarget.disabled = true;
      return true;
    } else if (scheduledTimeParsed < currentTime) {
      $("#email-scheduled-time-error").removeClass("hide");
      $("#email-scheduled-time-error").html(
        "Please select a time in the future."
      );
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      $("#email-scheduled-time-error").addClass("hide");
      $("#email-scheduled-time-error").html("");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateEmailSubject() {
    const subject = this.badgeEmailSubjectTarget.value;
    if (subject.length <= 0) {
      this.badgeEmailSubjectErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      this.badgeEmailSubjectErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateEmailBody() {
    const subject = this.badgeEmailBodyTarget.value;
    if (subject.length <= 0) {
      this.badgeEmailBodyErrorTarget.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      this.badgeEmailBodyErrorTarget.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }

  validateBadgeIcon() {
    const imageInput = this.badgeIconTarget;
    const errorSpan = document.getElementById("badge-icon-error");
    if (!imageInput.files || imageInput.files.length === 0) {
      errorSpan.innerText = "Image is required";
      errorSpan.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    }

    const allowedFormats = ["image/jpeg", "image/png"];
    const maxSizeInBytes = 5 * 1024 * 1024;

    const file = imageInput.files[0];

    if (!allowedFormats.includes(file.type)) {
      errorSpan.innerText = "Please upload a JPG or PNG image";
      errorSpan.classList.remove("hide");
      this.submitBtnTarget.disabled = true;
      return true;
    } else {
      errorSpan.classList.add("hide");
      this.submitBtnTarget.disabled = false;
      return false;
    }
  }
}
