import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.recordingTimer = null;
  }
  static targets = ["startBtn", "stopBtn", "deleteBtn", "liveVideo", "previewVideo", "recordedVideoBlob"];

  connect() {
    this.chunks = [];
    this.stream = null;
  }

  async startRecording() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      this.liveVideoTarget.srcObject = this.stream;

      this.liveVideoTarget.addEventListener("loadedmetadata", () => {
        this.liveVideoTarget.play();
      });

      this.mediaRecorder = new MediaRecorder(this.stream);
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };
      this.mediaRecorder.start();

      this.startBtnTarget.disabled = true;
      this.stopBtnTarget.disabled = false;

      this.recordingTimer = setTimeout(() => {
        this.stopRecording();
      }, 60000);
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  }

  async stopRecordingCallback(stopBtnValue, chunks) {
    const file = new File(chunks, {
      filename: "recorded_video.webm",
      type: "video/mp4"
    });
    chunks = [];

    const formData = new FormData();
    formData.append('review_recording[video]', file);
    formData.append('review_recording[review_id]', stopBtnValue);

    try {
        await fetch('/review_recordings', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: formData,
      });
    } catch (error) {
      console.error("Error accessing media devices:", error);
      throw error;
    }
  }

  async deleteRecording() {
    const deleteBtnValue = this.deleteBtnTarget.value;
    const formData = new FormData();
    const videoElement = document.getElementById('preview');
    formData.append('review_recording[review_id]', deleteBtnValue);

    try {
      const response = await fetch(`/review_recordings/${deleteBtnValue}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: formData,
      });

      if (response.status === 200) {
        console.log('Video deleted successfully.');
      } else {
        console.error('Failed to delete video.');
      }
      videoElement.pause();
      videoElement.src = '';
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  }

  async stopRecording() {
    const stopBtnValue = this.stopBtnTarget.value;

    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.addEventListener("stop", async () => {
        this.stream.getTracks().forEach((track) => track.stop());

        this.liveVideoTarget.srcObject = null;
        this.startBtnTarget.disabled = false;
        this.stopBtnTarget.disabled = true;
        clearTimeout(this.recordingTimer);

        if (this.chunks.length > 0) {
          try {
            const blob = new Blob(this.chunks, { type: "video/webm" });
            const videoURL = window.URL.createObjectURL(blob);
            this.previewVideoTarget.src = videoURL;
            this.stopRecordingCallback(stopBtnValue, this.chunks);
            this.chunks=[];
            this.previewVideoTarget.play();
          } catch (error) {
            console.error("Error while processing recording:", error);
          }
        }
      });

      this.mediaRecorder.stop();
    }
  }
}
