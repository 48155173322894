// TODO: Move this to Bullhorn stimulus reflex controller

import Alert from "./alert";

export default class TriggerButton {
  initializeListeners() {
    $('.js-trigger-manually-btn').on('click', (e) => {
      e.preventDefault();
      this.showNotice(e);
    });
  }

  showNotice(e) {
    if (e.currentTarget.attributes.disabled?.value == 'disabled'){
      return
    }

    const trigger = e.currentTarget.dataset['trigger'];
    const message = `Review requests for ${trigger} will run manually.`;
    const duration = 5000;
    $.post(e.currentTarget.attributes.href.nodeValue, this.buildParams(trigger));
    const noticeContainer = document.getElementById('notice-container');
    noticeContainer.innerHTML = `<p class="alert">${message}</p>`;
    $(noticeContainer).fadeIn();
    setTimeout(() => {
      $(noticeContainer).fadeOut();
    }, duration);
  }
  
  buildParams(trigger) {
    return { bullhorn_setting: this[`${trigger}Params`]() };
  }

  notesParams() {
    return {
      note_actions: $('#bullhorn_setting_note_actions').val(),
      note_active: true,
      importer: 'notes'
    }
  }

  submissionsParams() {
    return {
      submission_active: true,
      submission_states: $('#bullhorn_setting_submission_states').val(),
      submission_reviewee_entity: $('#bullhorn_setting_submission_reviewee_entity').val(),
      submission_reviewee_field: $('#bullhorn_setting_submission_reviewee_field').val(),
      importer: 'submissions'
    }
  }

  placementsParams() {
    let fieldAttributes = {};

    $('.placement-field').each((i, el) => {
      fieldAttributes[i] = {
        name: $(el).find('select').val(),
        matches: $(el).find('textarea').val()
      };
    });

    const placementRevieweeField = $('#bullhorn_setting_placement_reviewee_field').val();
    return {
      placement_active: true,
      placement_days_ago: $('#bullhorn_setting_placement_days_ago').val(),
      placement_end_days_ago: $('#bullhorn_setting_placement_end_days_ago').val(),
      placement_reviewee_entity: $('#bullhorn_setting_placement_reviewee_entity').val(),
      placement_reviewer_field: $('#bullhorn_setting_placement_reviewer').val(),
      placement_reviewee_field: placementRevieweeField,
      placement_commission_role: placementRevieweeField === 'commissions' && $('#bullhorn_setting_placement_commission_role').val() || null,
      fields_attributes: fieldAttributes,
      importer: 'placements'
    }
  }
}
