import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.removeEventListener('gr-fw-modal-open', modalEvent);
    document.addEventListener('gr-fw-modal-open', modalEvent, false);
  }

  disconnect() {
    document.removeEventListener('gr-fw-modal-open', modalEvent, false);
  }

  show(event) {
    event.preventDefault();

    const customEvent = new CustomEvent('gr-fw-modal-open', { detail: { id: this.data.get('name'), clear: this.data.get('clear') } });
    document.dispatchEvent(customEvent);
  }
};

const modalEvent = event => {
  const modal = new Foundation.Reveal($(`#${event.detail.id}`), { closeOnClick: false, closeOnEsc: false });
  modal.open();

  if (event.detail.clear === 'true') {
    const inputs = document.querySelector(`#${event.detail.id}`).getElementsByTagName('input');
    Array.from(inputs).forEach(input => {
      if (input.type === 'text') {
        input.value = '';
      }
    });
  }
};
