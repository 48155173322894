import { Controller } from 'stimulus';

export default class extends Controller {
  toggleDashboardOptions = (event) =>  {
    var options = document.getElementById("conditional-gr-options")
    var eliminateUserOptions = document.getElementById("user-div")
    var addUserOptions = document.getElementById("add-user")
    var url = document.getElementById("company_url")
    var image = document.getElementsByClassName("image-uploader")[0]

    if (event.target.id == 'company_is_imported') {
      if (options.style.display == 'none') {
        options.style.display = 'block'
        addUserOptions.childNodes[1].childNodes[1].click()
        url.required = false
        image.style.display = 'block'
      } else {
        options.style.display = 'none'
        eliminateUserOptions.childNodes[2].click()
        url.required = true
        image.style.display = 'none'
      }
    }

    if (event.target.id == 'company_gr_starter') {
      options.style.display = 'block'
      addUserOptions.childNodes[1].childNodes[1].click()
      url.required = false
      image.style.display = 'block'
    }
  }

  toggleUnClaimCompany(event) {
    if ($('#company_is_imported').is(':checked')) {
      $('#company_gr_starter').prop('checked', false);
    }
    this.toggleDashboardOptions(event)
  }

  toggleGrStarterCompany(event) {
    if ($('#company_gr_starter').is(':checked')) {
      $('#company_is_imported').prop('checked', false);
    }
    this.toggleDashboardOptions(event)
  }

  confirmDisable(event) {
    event.preventDefault();
    var url = event.currentTarget.dataset['url']
    var status = event.currentTarget.dataset['status']
    const disableCompanyModal = document.querySelector('#disable-company-modal p');
    disableCompanyModal.innerHTML = 'Are you sure you want to ' + status.toLowerCase() + ' company?';
    const yesButton = document.getElementById('disable-company');
    yesButton.href = url;
    openModal('#disable-company-modal')
  }
}
