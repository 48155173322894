import { Controller } from 'stimulus';
import consumer from "../channels/consumer";

export default class extends Controller {
  connect() {
    const controller = this;
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ReportExpandableChannel',
        key: this.data.get('key')
      },
      {
        connected() {
          controller.connected = true;
        },
        disconnected() {
          controller.connected = false;
        },
        received(data) {
          if (data['finished']) {
            controller.enablePage();

            if (!data['error']) {
              controller.renderMembers(data['company_id'], data['content']);
            }
          }
        }
      }
    );
  }

  disablePage() {
    this.element.classList.add('loading');
  }

  enablePage() {
    this.element.classList.remove('loading');
  }

  displayMembers(event) {
    const companyRow = event.currentTarget;
    const companyId = companyRow.dataset.companyId;
    const customReviewId = companyRow.dataset.customReview

    if (companyRow.dataset.cached) {
      event.preventDefault();

      const parent = `[data-company-id="${companyId && companyId || ''}"]`;
      const customEvent = new CustomEvent(
        'gr-expandable',
        { detail: { parent: parent, element: `${parent}[data-report]` } }
      );

      return document.dispatchEvent(customEvent);
    }

    if (this.connected) {
      this.subscription.send({ company_id: companyId, day_span: this.data.get('days'), users: this.data.get('users'), review_id: customReviewId });
      this.disablePage();
    } else {
      this.enablePage();
      alert('Please refresh the page and try again.');
    }
  }

  renderMembers(companyId, content) {
    const companyRow = document.querySelector(`[data-company-id="${companyId && companyId || ''}"]`);
    const newTable = document.createElement('table');
    const parentTable = companyRow.parentNode
    const refChild = companyRow.nextSibling;
    let rowReport;

    newTable.innerHTML = content;
    newTable.childNodes[0].childNodes.forEach(row => {
      if (row.dataset.report) {
        row.classList.add('active');

        if (!rowReport) {
          row.classList.add('active-border-top');
        }

        rowReport = row;
      }

      parentTable.insertBefore(row, refChild);
    });

    rowReport && rowReport.classList.add('active-border-bottom');
    companyRow.dataset.cached = true;
    companyRow.dataset.expandable = 'open';
  }
}
