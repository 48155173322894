export default class Modal {
  initializeListeners() {
    if ($("#showcase-review-modal").length) {
      openModal("#showcase-review-modal");
    }
  }
}

export const openModal = (selector = '#app-modal') => {
  try {
    $(selector).foundation('open');

    $('.reveal-overlay').toArray().forEach(overlay => {
      if (!$(overlay).html()) {
        $(overlay).remove();
      }
    });

    $(selector).find('input[autofocus]').first().focus();
  } catch (error) {
    setTimeout(openModal, 100);
  }
};

// TODO: revert it when recaptcha is fixed

// export const openStrongModal = (selector = '#app-modal') => {
//   try {
//     $(selector).foundation('open', {
//       closeOnClick: false,
//       closeOnEsc: true
//     });

//     $('.reveal-overlay').off('click').on('click', function(event) {
//       event.stopPropagation();
//     });

//     $('.reveal-overlay').toArray().forEach(overlay => {
//       if (!$(overlay).html()) {
//         $(overlay).remove();
//       }
//     });

//     $(selector).find('input[autofocus]').first().focus();
//   } catch (error) {
//     setTimeout(() => openStrongModal(selector), 100);
//   }
// };
