export default class EditNotification {
  initializeListeners() {
    $('#positive-thank-you').on('change', '#custom_review_reference_points', (e) => {
      $('.company-email-positive-thank-you').removeClass('is-hidden').addClass('is-hidden');
      $(`.custom_review_emails_reference_point_id input[value='${$(e.currentTarget).val()}']`).parents('.company-email-positive-thank-you').removeClass('is-hidden');
    });

    $('.company-email-positive-thank-you').removeClass('is-hidden').addClass('is-hidden');
    $(`.custom_review_emails_reference_point_id input[value='${$('#positive-thank-you #custom_review_reference_points').val()}']`).parents('.company-email-positive-thank-you').removeClass('is-hidden');
  }
}
