import { Controller } from 'stimulus';
import StimulusReflex from "stimulus_reflex";
import CustomQuestions from "../components/customQuestions";

$(document).ready(function() {
  sessionStorage.removeItem('SessionStep');
});

export default class extends Controller {
  initialize() {
    this.isEditable = false
    this.individualCompany = this.data.get('value') == 'false' ? false : true
    this.displayStep();
  }

  connect() {
    StimulusReflex.register(this);
  }

  afterReflex() {
    this.displayStep();
  }

  cancel(event) {
    event.stopPropagation();

    this.element.classList.add('loading');

    const args = {
      company_id: this.data.get('company'),
      id: this.data.get('id'),
      lang: this.data.get('lang'),
      current_step: this.currentStep
    };

    this.isEditable = false;
    this.stimulate('ReviewPrint#cancel', JSON.stringify(args));
  }

  edit(event) {
    if (!this.isEditable && !this.individualCompany) {
      const container = event.currentTarget;
      this.targets.findAll(container.dataset['editable']).forEach(editable => {
        editable.classList.remove('is-hidden');
      });

      if (container.dataset['hidden']) {
        this.hideTargets(event);
      }

      this.isEditable = true;
      this.targets.find('prevBtn').disabled = true;
      this.targets.find('nextBtn').disabled = true;

      if (this.targets.has(`${container.dataset['editable']}Card`)) {
        this.targets.find(`${container.dataset['editable']}Card`).classList.remove('is-hidden');
      }
    }
  }

  editComparativeAttributes(event) {
    // force the icon dropdown to render if it does not exist; this can
    // occur when the user edits the comparative attribute section multiple times
    if ($(".select2").length === 0) {
      renderComparativeAttributeSelect2();
    }
  }

  hideTargets(event) {
    this.targets.findAll(event.currentTarget.dataset['hidden']).forEach(hidden => {
      hidden.classList.add('is-hidden');
    });
  }

  jqueryCustomQuestions() {
    new CustomQuestions().showCorrectQuestions();
  }

  jquerySortable() {
    $('.reference-points-sortable').sortable({
      update: (e, ui) => {
        $('.reference-point').each((index, referencePoint) => {
          $(referencePoint).find('.js-reference-points-position input').val(index);
        });
      }
    });
  }

  npsToggle(event) {
    const npsCheckbox = event.currentTarget;

    if (npsCheckbox.checked) {
      this.targets.find('npsHeader').innerText = this.data.get('npsLabel');

      if (!this.targets.find('npsOff').classList.contains('is-hidden')) {
        this.targets.find('npsOff').classList.add('is-hidden');
      }

      this.targets.find('npsOn').classList.remove('is-hidden');
    } else {
      this.targets.find('npsHeader').innerText = this.data.get('recommendationLabel');

      if (!this.targets.find('npsOn').classList.contains('is-hidden')) {
        this.targets.find('npsOn').classList.add('is-hidden');
      }

      this.targets.find('npsOff').classList.remove('is-hidden');
    }
  }

  recommendToggle(event) {
    if (event.currentTarget.checked) {
      this.targets.find('recommendLabel').innerText = this.data.get('recommendationLabelOff');
    } else {
      this.targets.find('recommendLabel').innerText = this.data.get('recommendationLabelOn');
    }
  }

  save(event) {
    event.stopPropagation();

    if (event.currentTarget.dataset['validates']) {
      if (!this[`${event.currentTarget.dataset['validates']}Validation`]()) {
        return;
      }
    }

    this.element.classList.add('loading');
    const editable = event.currentTarget.dataset['editable'];
    this.isEditable = false;
    this.stimulate('ReviewPrint#save', JSON.stringify(this.buildParams(editable)));
  }

  referralSettingToggle(event) {
    if (event.currentTarget.checked) {
      this.targets.find('referralSettingLabel').innerText = this.data.get('referralsOff');
    } else {
      this.targets.find('referralSettingLabel').innerText = this.data.get('referralsOn');
    }
  }

  next(event) {
    event.stopPropagation();

    if (this.isEditable) {
      return;
    }

    const nextStep = this.stepToggle(1);
    if (nextStep < 4) {
      this.data.set('currentStep', nextStep);
      sessionStorage.setItem('SessionStep', nextStep);
      this.displayStep();
    }
  }

  prev(event) {
    event.stopPropagation();

    if (this.isEditable && !this.individualCompany) {
      return;
    }

    const prevStep = this.stepToggle(-1);
    if (prevStep > 0) {
      this.data.set('currentStep', prevStep);
      sessionStorage.setItem('SessionStep', prevStep);
      this.displayStep();
    }
  }

  selectLang(event) {
    this.element.classList.add('loading');

    const args = {
      company_id: this.data.get('company'),
      id: this.data.get('id'),
      lang: event.currentTarget.value,
      current_step: this.currentStep
    };

    this.isEditable = false;
    this.stimulate('ReviewType#select_lang', JSON.stringify(args));
  }

  displayStep() {
    var step = sessionStorage.getItem('SessionStep') || null;
    this.targets.findAll('steps').forEach(step => {
      if (!step.classList.contains('is-hidden')) {
        step.classList.add('is-hidden');
      }
    });
    if (step == null) {
      step = this.currentStep;
    }
    this.targets.find(`step${(step)}`).classList.remove('is-hidden');

    if (step === '1') {
      this.targets.find('prevBtn').classList.add('is-hidden');
    } else {
      this.targets.find('prevBtn').classList.remove('is-hidden');
    }

    if (step === '3') {
      this.targets.find('nextBtn').classList.add('is-hidden');
    } else {
      this.targets.find('nextBtn').classList.remove('is-hidden');
    }

    this.displayStepHelp();
  }

  displayStepHelp() {
    this.targets.findAll('helpSteps').forEach(step => {
      if (!step.classList.contains('is-hidden')) {
        step.classList.add('is-hidden');
      }
    });

    if (this.targets.has(`helpStep${this.currentStep}`)) {
      this.targets.find(`helpStep${this.currentStep}`).classList.remove('is-hidden');
    }
  }

  buildParams(section) {
    return {
      company_id: this.data.get('company'),
      id: this.data.get('id'),
      lang: this.data.get('lang'),
      current_step: this.currentStep,
      review_print: this[`${section}Params`]()
    };
  }

  comparativeAttributesParams() {
    const controller = this;
    let comparativeAttributes = {};

    this.targets.findAll('comparativeAttribute').forEach((comparativeAttribute, i) => {
      const comparativeAttributeId = comparativeAttribute.querySelector('.comparative-attribute-id [type="hidden"]').value;

      comparativeAttributes[i] = { icon: comparativeAttribute.querySelector('select').value };

      if (comparativeAttributeId) {
        comparativeAttributes[i]['id'] = comparativeAttributeId;
      }

      if (controller.data.get('lang') === 'en') {
        comparativeAttributes[i]['name'] = comparativeAttribute.querySelector('input[name*="name"]').value;
        comparativeAttributes[i]['question'] = comparativeAttribute.querySelector('input[name*="question"]').value;
        if (comparativeAttribute.querySelector('input[name*="destroy"]')) {
          comparativeAttributes[i]['_destroy'] = comparativeAttribute.querySelector('input[name*="destroy"]').value;
        }
      } else {
        comparativeAttributes[i][`name_${controller.data.get('lang')}`] = comparativeAttribute.querySelector('input[name*="name"]').value;
        comparativeAttributes[i][`question_${controller.data.get('lang')}`] = comparativeAttribute.querySelector('input[name*="question"]').value;
        if (comparativeAttribute.querySelector('input[name*="destroy"]')) {
          comparativeAttributes[i][`_destroy_${controller.data.get('lang')}`] = comparativeAttribute.querySelector('input[name*="destroy"]').value;
        }
      }
    });

    let params = {
      comparative_attributes_attributes: comparativeAttributes
    };

    if (this.data.get('lang') === 'en') {
      params['attribute_intro'] = this.targets.find('comparativeAttributeIntro').value;
    } else {
      params[`attribute_intro_${this.data.get('lang')}`] = this.targets.find('comparativeAttributeIntro').value;
    }

    return params;
  }

  comparativeAttributesValidation() {
    let isValid = true;

    if (!this.targets.find('comparativeAttributeIntro').value) {
      this.targets.find('comparativeAttributeIntro').classList.add('is-input-error');
      isValid = false;
    } else {
      this.targets.find('comparativeAttributeIntro').classList.remove('is-input-error');
    }

    this.targets.findAll('comparativeAttribute').forEach(comparativeAttribute => {
      const nameElement = comparativeAttribute.querySelector('input[name*="name"]');
      const questionElement = comparativeAttribute.querySelector('input[name*="question"]');

      if (!nameElement.value) {
        nameElement.classList.add('is-input-error');
        isValid = false;
      } else {
        nameElement.classList.remove('is-input-error');
      }

      if (!questionElement.value) {
        questionElement.classList.add('is-input-error');
        isValid = false;
      } else {
        questionElement.classList.remove('is-input-error');
      }
    });

    return isValid;
  }

  customQuestionsParams() {
    const controller = this;
    let customQuestionAttributes = {};

    this.targets.findAll('customQuestion').forEach((customQuestion, i) => {
      const customQuestionId = customQuestion.querySelector('.custom-question-id [type="hidden"]').value;

      customQuestionAttributes[i] = {
        reference_point_id: customQuestion.querySelector('.js-custom-questions-reference-point-id [type="hidden"]').value,
        _destroy: customQuestion.querySelector('input[name*="_destroy"]').value,
        response_type: customQuestion.querySelector('[type="radio"]:checked').value
      };

      if (customQuestionId) {
        customQuestionAttributes[i]['id'] = customQuestionId;
      }

      if (controller.data.get('lang') === 'en') {
        customQuestionAttributes[i]['question'] = customQuestion.querySelector('.js-custom-questions-question textarea').value;
      } else {
        customQuestionAttributes[i][`question_${controller.data.get('lang')}`] = customQuestion.querySelector('.js-custom-questions-question textarea').value;
      }
    });

    return {
      custom_question_intro: this.targets.find('customQuestionIntro').value,
      use_uniq_custom_questions: this.targets.find('useUniqCustomQuestions').checked,
      ask_custom_questions: this.targets.find('askCustomQuestions').checked,
      custom_questions_attributes: customQuestionAttributes
    };
  }

  customQuestionsValidation() {
    let isValid = true;

    this.targets.findAll('customQuestion').forEach(customQuestion => {
      const questionElement = customQuestion.querySelector('.js-custom-questions-question textarea');
      const destroy = customQuestion.querySelector('input[name*="_destroy"]').value;

      if (destroy === 'false') {
        if (!questionElement.value) {
          questionElement.classList.add('is-input-error');
          isValid = false;
        } else {
          questionElement.classList.remove('is-input-error');
        }

        if (!customQuestion.querySelector('[type="radio"]:checked')) {
          customQuestion.querySelector('.review_print_custom_questions_response_type').classList.add('is-input-error');
          isValid = false;
        } else {
          customQuestion.querySelector('.review_print_custom_questions_response_type').classList.remove('is-input-error');
        }
      }
    });

    if (!isValid) {
      alert('There are invalid questions that need to be completed.');
    }

    return isValid;
  }

  step1TitleParams() {
    let params = {};

    if(this.data.get('lang') === 'en') {
      params['step1_title'] = this.targets.find('step1TitleText').value;
      params['step1_title_unsolicited'] = this.targets.find('step1TitleUnsolicitedText').value;
    } else {
      params[`step1_title_${this.data.get('lang')}`] = this.targets.find('step1TitleText').value;
      params[`step1_title_unsolicited_${this.data.get('lang')}`] = this.targets.find('step1TitleUnsolicitedText').value;
    }

    return params;
  }

  feedbackParams() {
    let params = {};

    if (this.data.get('lang') === 'en') {
      params['negative_feedback_title'] = this.targets.find('negativeFeedbackTitle').value;
      params['negative_feedback_placeholder'] = this.targets.find('negativeFeedbackPlaceholder').value;
      params['positive_feedback_title'] = this.targets.find('positiveFeedbackTitle').value;
      params['positive_feedback_placeholder'] = this.targets.find('positiveFeedbackPlaceholder').value;
    } else {
      params[`negative_feedback_title_${this.data.get('lang')}`] = this.targets.find('negativeFeedbackTitle').value;
      params[`negative_feedback_placeholder_${this.data.get('lang')}`] = this.targets.find('negativeFeedbackPlaceholder').value;
      params[`positive_feedback_title_${this.data.get('lang')}`] = this.targets.find('positiveFeedbackTitle').value;
      params[`positive_feedback_placeholder_${this.data.get('lang')}`] = this.targets.find('positiveFeedbackPlaceholder').value;
    }

    return params;
  }

  npsParams() {
    return { use_nps: this.targets.find('nps').checked, can_recommend: this.targets.find('recommend').checked };
  }

  referralParams() {
    let params = { can_referr: this.targets.find('referralSetting').checked };

    if (this.data.get('lang') === 'en') {
      params['referral_header'] = this.targets.find('referralHeader').value;
      params['referral_description'] = this.targets.find('referralDescription').value;
    } else {
      params[`referral_header_${this.data.get('lang')}`] = this.targets.find('referralHeader').value;
      params[`referral_description_${this.data.get('lang')}`] = this.targets.find('referralDescription').value;
    }

    return params;
  }

  referralValidation() {
    let isValid = true;

    const referralHeader = this.targets.find('referralHeader');
    const referralDescription = this.targets.find('referralDescription');

    if (!referralHeader.value) {
      referralHeader.classList.add('is-input-error');
      isValid = false;
    } else {
      referralHeader.classList.remove('is-input-error');
    }

    if (!referralDescription.value) {
      referralDescription.classList.add('is-input-error');
      isValid = false;
    } else {
      referralDescription.classList.remove('is-input-error');
    }

    return isValid;
  }

  referencePointsParams() {
    const controller = this;
    let referencePointAttributes = {};

    this.targets.findAll('referencePoint').forEach((referencePoint, i) => {
      const referencePointId = referencePoint.querySelector('.reference-point-name [type="hidden"]').value;
      referencePointAttributes[i] = {
        position: referencePoint.querySelector('.js-reference-points-position [type="hidden"]').value,
        _destroy: referencePoint.querySelector('input[name*="_destroy"]').value
      };

      if (referencePointId) {
        referencePointAttributes[i]['id'] = referencePointId;
      }

      if (controller.data.get('lang') === 'en') {
        referencePointAttributes[i]['name'] = referencePoint.querySelector('.js-reference-points-name [type="text"]').value;
      } else {
        referencePointAttributes[i][`name_${controller.data.get('lang')}`] = referencePoint.querySelector('.js-reference-points-name [type="text"]').value;
      }
    });

    return {
      reference_point_question: this.targets.find('referencePointQuestion').value,
      reference_points_attributes: referencePointAttributes
    };
  }

  referencePointsValidation() {
    let isValid = true;

    this.targets.findAll('referencePoint').forEach(referencePoint => {
      const nameElement = referencePoint.querySelector('.js-reference-points-name [type="text"]');
      const destroy = referencePoint.querySelector('input[name*="_destroy"]').value;

      if (destroy === 'false') {
        if (!nameElement.value) {
          nameElement.classList.add('is-input-error');
          isValid = false;
        } else {
          nameElement.classList.remove('is-input-error');
        }
      }
    });

    return isValid;
  }

  get currentStep() {
    return this.data.get('currentStep');
  }

  stepToggle = (value) => {
    var step = sessionStorage.getItem('SessionStep') || null;
    if (step == null) {
      step = this.currentStep;
    }
    return parseInt(step) + value;
  }
}
