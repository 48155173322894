import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollSection"];

  connect() {
    console.log("ScrollCardsController connected");

    this.startScrolling();

    window.addEventListener("resize", this.adjustScrollSpeed.bind(this));

    this.scrollSection.addEventListener("mouseenter", this.pauseScrolling.bind(this));
    this.scrollSection.addEventListener("mouseleave", this.resumeScrolling.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.adjustScrollSpeed.bind(this));
    this.scrollSection.removeEventListener("mouseenter", this.pauseScrolling.bind(this));
    this.scrollSection.removeEventListener("mouseleave", this.resumeScrolling.bind(this));

    if (this.scrollAnimationFrame) {
      cancelAnimationFrame(this.scrollAnimationFrame);
    }
  }

  startScrolling() {
    const scrollSection = document.getElementById("main-cards-section");
    if (!scrollSection) return;

    this.scrollSection = scrollSection;
    this.scrollPosition = this.scrollPosition || 0;
    this.scrollSpeed = this.calculateSpeed();

    console.log("Scroll speed: ", this.scrollSpeed);

    const scroll = () => {
      this.scrollPosition -= this.scrollSpeed;
      if (this.scrollPosition <= -this.scrollSection.scrollWidth) {
        this.scrollPosition = 0;
      }

      this.scrollSection.style.transform = `translateX(${this.scrollPosition}px)`;

      this.scrollAnimationFrame = requestAnimationFrame(scroll);
    };

    this.scrollAnimationFrame = requestAnimationFrame(scroll);
  }

  calculateSpeed() {
    const sectionWidth = this.scrollSection.scrollWidth;

    let baseSpeed;
    if (window.innerWidth >= 1024) {
      baseSpeed = 3;
    } else if (window.innerWidth >= 750) {
      baseSpeed = 2;
    } else {
      baseSpeed = 1;
    }

    return baseSpeed;
  }

  adjustScrollSpeed() {
    if (this.scrollAnimationFrame) {
      cancelAnimationFrame(this.scrollAnimationFrame);
    }
    this.startScrolling();
  }

  pauseScrolling() {
    if (this.scrollAnimationFrame) {
      cancelAnimationFrame(this.scrollAnimationFrame);
      this.scrollAnimationFrame = null;
    }
  }

  resumeScrolling() {
    if (!this.scrollAnimationFrame) {
      this.startScrolling();
    }
  }
}
