import { Controller } from 'stimulus';

export default class extends Controller {

  validateFileSize(event) {
    const input = event.currentTarget
    const maxFileSize = input.getAttribute('data-max-size');
    const uploadedFile = input.files[0];

    if (uploadedFile && uploadedFile.size > maxFileSize) {
      this.flash_message(`File size exceeds the maximum allowed size (${Math.floor( (maxFileSize / 1024 ) / 1000) } MB)`);
      event.preventDefault();
      input.value = ''
    }
  }

  flash_message(message) {
    document.getElementById('notice-container').innerHTML = '<p class="alert">' + message + '</p>';
    setTimeout(() => $('p.alert').addClass('fade-out'), 5000);
  }
}
