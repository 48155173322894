import { Controller } from 'stimulus';
import Alert from '../error/notice'

export default class extends Controller {
  deleteUnclaimedCompany(event) {
    event.preventDefault();
    const unclaimedCompanyId = this.element.dataset.unclaimedCompanyId;
    const deleteUnclaimedCompanyId = document.querySelector("#delete-unclaimed-company-modal");
    deleteUnclaimedCompanyId.setAttribute("data-unclaimed-company-id", unclaimedCompanyId);
    openModal(deleteUnclaimedCompanyId);
  }

  async saveDeleteUnclaimedCompany(event) {
    event.preventDefault();
    const deleteUnclaimedCompanyId = document.querySelector("#delete-unclaimed-company-modal");
    const unclaimedCompanyId = deleteUnclaimedCompanyId.getAttribute("data-unclaimed-company-id");

    try {
      const response = await fetch(`/supers/claim_companies/${unclaimedCompanyId.split('_')[1]}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
        },
      });
      location.reload();
    } catch (error) {
      let alert = new Alert
      alert.flashMessage(error)
    }
  }
}
