import { Controller } from 'stimulus';
import { updateSpecialtyInnerText,currentPage } from "./shared_functions";
import { DIRECTORY_COUNTRY_CODES } from './constants';

$(document).ready(function() {

  function handleLocationChange(input, target) {
    input.on('change', function() {
      var selectedValue = $(this).val();
      target.prop('disabled', !selectedValue);
      if (!selectedValue) {
        target.val(0);
      }
    });
  }

  handleLocationChange($('#top-recruiters-filter-recruiter-location'), $('#top_recruiters_filter_recruiter_location_within'));
  handleLocationChange($('#top-companies-filter-company-location'), $('#top_companies_filter_company_location_within'));
});

export default class extends Controller {
  initialize() {
    const dropdown = document.querySelector('.dropdown-content-1');
    const dropdownField = document.querySelector('.dropdown-toggle-1');
    const dropdownArrow = document.querySelector('.recruiter-type-arrow');

    this.loadSessionFilters();
    document.addEventListener("click", function (event) {
      const isClickInside = dropdown.contains(event.target) || dropdownField.contains(event.target);

      if (!isClickInside) {
        dropdown.style.display = 'none';
        dropdownArrow.classList.remove('arrow-downward');
      } else{
        dropdown.style.display = 'block';
        dropdownArrow.classList.add('arrow-downward');
      }
    });
    requestAnimationFrame(() => {
      this.loadPlacements();
      this.loadRoles();
    });
    window.addEventListener('popstate', this.handlePopState.bind(this));
  }

  loadRoles() {
    return new Promise((resolve) => {
      updateSpecialtyInnerText();
      const wrapper = document.getElementById("placements-wrapper");
      const sessionSpecialties = JSON.parse(wrapper.dataset.directorySpecialtiesSession);
      const sessionCompanySpecialties = JSON.parse(wrapper.dataset.directoryCompanySpecialtiesSession);
      const checkboxes = document.querySelectorAll('input[name="specialties[]"]');
      const currentUrl = window.location.pathname;
      const selectedRoles = [];

      if (currentUrl.includes("recruiters")) {
        checkboxes.forEach((checkbox) => {
          const exists = sessionSpecialties.includes(checkbox.value);
          if (exists) {
            checkbox.checked = true;
            selectedRoles.push(checkbox.value);
          }
        });
      } else {
        checkboxes.forEach((checkbox) => {
          const exists = sessionCompanySpecialties.includes(checkbox.value);
          if (exists) {
            checkbox.checked = true;
            selectedRoles.push(checkbox.value);
          }
        });
      }
    
      const searchInput = document.getElementById('speciality-search');
      if (selectedRoles.length > 0) {
        searchInput.placeholder = selectedRoles.join(', ');
        searchInput.classList.add('roles-placeholder');
      } else {
        searchInput.placeholder = 'All Specialties';
        searchInput.classList.remove('roles-placeholder');
      }
      resolve();
    });
  }

  loadPlacements() {
    return new Promise((resolve) => {
      const wrapper = document.getElementById("placements-wrapper");
      const sessionPlacements = JSON.parse(wrapper.dataset.directoryPlacementsSession);
      const sessionCompanyPlacements = JSON.parse(wrapper.dataset.directoryCompanyPlacementsSession);
      const sessionLocations = JSON.parse(wrapper.dataset.directoryLocationsSession);
      const industryLabels = document.querySelectorAll('#gr-directory-placements .industry-btn');
      const locationSelect = document.getElementById("directory-hiring-locations-select2");
  
      const currentUrl = window.location.pathname;
      if (currentUrl.includes("recruiters")) {
        industryLabels.forEach(label => {
          const checkbox = label.querySelector('input[type="checkbox"]');
          const exists = sessionPlacements.includes(checkbox.value);

          if (exists) {
            checkbox.checked = true;
            label.classList.add('selected-industry');
          }
      }); }
      else {
      industryLabels.forEach(label => {
        const checkbox = label.querySelector('input[type="checkbox"]');
        const inCompanyPlacements = sessionCompanyPlacements.includes(checkbox.value);

        if (inCompanyPlacements) {
          checkbox.checked = true;
          label.classList.add('selected-industry');
        }
      });
      }
      resolve();
    });
  }

  connect() {
    this.scrollToRecruiterPosition();

    this.selectElement = this.element.querySelector('.select2');
    $(this.selectElement).select2({
      width: '100%',
      placeholder: 'Any Location',
      minimumResultsForSearch: -1
    });

    $(document).ready(() => {
      function initializeSelect2(selector, clearSelection, isMultiple = false, dropdownParentSelector = null) {
        selector.select2({
          placeholder: 'Add Location (City, State)',
          width: '100%',
          minimumInputLength: 2,
          allowClear: clearSelection,
          multiple: isMultiple,
          ajax: {
            url: '/google_places/autocomplete',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                term: params.term
              };
            },
            processResults: function (data) {
              return {
                results: data.places.map(function (place) {
                  return {
                    text: place,
                    id: place
                  };
                })
              };
            }
          },
          dropdownParent: dropdownParentSelector ? $(dropdownParentSelector) : null
        });
      }
  
      initializeSelect2($('#top-recruiters-filter-recruiter-location'), true);
      initializeSelect2($('#top-companies-filter-company-location'), true);
      initializeSelect2($('#directory-hiring-locations-select2'), true, false, '#directory-hiring-locations');
    });
  }  

  scrollToRecruiterPosition() {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionId = urlParams.get('section_id');

    if (sectionId) {
      const sectionElement = document.getElementById(`recruiter-${sectionId}`);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  displayRecruiterText(industryName) {
    const recruiterTextContainer = document.querySelector('.recruiter-text-container');
    recruiterTextContainer.innerHTML = `
      <div class="top-rated-search">
        <h2>Top Rated Recruiter Search</h2>
        <h3>Recruiter in <span class="industry-name">${industryName}</span> and...</h3>
      </div>
    `;
    recruiterTextContainer.classList.remove('display-hidden');
  }

  handlePopState() {
    const industriesBlock = document.querySelector('.gr-directory-industries-block');
    industriesBlock.classList.remove('display-hidden');
  }

  loadSessionFilters() {
    const finalCounterValue = localStorage.getItem('counterValue');
    let counter = sessionStorage.getItem('initializeCounter');

    if (counter === null) {
      counter = 0;
    } else {
      counter = parseInt(counter);
    }
    this.loadRefineLocation();
    counter++;
    if (counter.toString() === finalCounterValue) {
      this.loadGrSessionFilters();
      sessionStorage.removeItem('initializeCounter');
    } else {
      sessionStorage.setItem('initializeCounter', counter);
    }
  }

  loadGrSessionFilters() {
    const sessionFiltersEnabled = sessionStorage.getItem('session-filters-enabled');
    if (sessionFiltersEnabled) {
      this.loadSessionIndustries();
      this.loadSessionPlacements();
      this.loadLocation();
      this.loadSpecialties();
      sessionStorage.removeItem('session-filters-enabled');
    } else {
      const sessionsToRemove = [
        'recruitersindustriesSession',
        'staffingindustriesSession',
        'recruitersPlacementsSession',
        'staffingPlacementsSession',
        'recruiterLocationSession',
        'staffingLocationSession',
        'recruitersSpecialtiesSession',
        'staffingSpecialtiesSession'
      ];

      sessionsToRemove.forEach(function(key) {
        sessionStorage.removeItem(key);
      });
    }
  }

  setGrFiltersEnabled() {
    this.setGrCounterValue();
    sessionStorage.setItem('session-filters-enabled', true)
  }

  async submitForm(event ){
    this.setGrCounterValue();
    event.preventDefault();
    $('#ranking-form')[0].submit();
    document.getElementById("ranking-form").reset();
  }

  setGrCounterValue () {
    if (localStorage.getItem('counterValue') === null) {
      const counter = sessionStorage.getItem('initializeCounter');
      localStorage.setItem('counterValue', counter);
      sessionStorage.removeItem('initializeCounter');
    }
  }

  async selectChanged(event) {
    event.preventDefault();
    const label = event.target.closest('label');
    const placements_block = $('.gr-directory-specifics-and-placements-block');
    const placements_types = $('#placements-types')[0];
    let target = event.currentTarget;
    let checkbox = target.querySelector('input[type="checkbox"]');

    const industrycheckboxes = document.querySelectorAll('input[name="industries[]"]');
    const industrysearchInput = document.getElementById('speciality-search');
    if (checkbox.name === 'industries[]') {
      industrycheckboxes.forEach((industrycheckbox) => {
        if (industrycheckbox !== checkbox) {
          industrycheckbox.checked = false;
          industrycheckbox.closest('label').classList.remove('selected-industry');
          industrysearchInput.placeholder = 'All Specialties';
        }
      });
    }

    checkbox.checked = !checkbox.checked;
    label.classList.toggle('selected-industry');

    const industriesBlock = document.querySelector('.gr-directory-industries-block');
    if (checkbox.checked) {
      $('.gr-directory-specifics-and-placements-block').removeClass('display-hidden');
      placements_types.classList.remove('display-hidden');
      industriesBlock.classList.add('display-hidden');

      const industryNameField = label.querySelector(".industry-btn-text");
      if (industryNameField) {
        const industryName = industryNameField.textContent;
        this.displayRecruiterText(industryName);
      }
    }

    history.pushState({}, '', window.location.href);

    if (checkbox.name === 'industries[]') {
      this.setIndustriesSession(checkbox);
    } else if (checkbox.name === 'placement_types[]') {
      this.setPlacementSession(checkbox);
    }

    const container = document.querySelector('.gr-directory-industries-block');
    const selectedIndustries = container.querySelectorAll('.selected-industry');
    const industryListLength = selectedIndustries.length;
    const selectedIndustryNames = [];

    if (selectedIndustries.length > 0) {
      placements_block.removeClass('display-hidden');
      placements_types.classList.remove('display-hidden');

      container.classList.add('display-hidden');
    } else {
      placements_block.addClass('display-hidden');
      container.classList.remove('display-hidden');
    }

    selectedIndustries.forEach(industryLabel => {
      const industryNameElement = industryLabel.querySelector('.industry-btn-text');

      if (industryNameElement) {
        const industryName = industryNameElement.textContent;
        selectedIndustryNames.push(industryName);
      }
    });

    if (industryListLength > 0) {
      placements_block.toggleClass('display-hidden', false);
      updateSpecialtyInnerText();

        placements_types.classList.remove('display-hidden');
    } else {
        placements_block.toggleClass('display-hidden', true);
    }

    if (checkbox.name === 'industries[]') {
      const recruitersPage = currentPage();
      const constantValue = this.element.getAttribute('data-constant-value');
      const constantPlacementsValue = this.element.getAttribute('data-placements-value');
      let filtered_value = constantValue.replace(/=>/g, ":");
      let filtered_placements_value = constantPlacementsValue.replace(/=>/g, ":");
      let data = JSON.parse(filtered_value)
      let placements_data = JSON.parse(filtered_placements_value)

      const extractedValues = this.extractValues(data, selectedIndustryNames);
      const extractedPlacementsValues = this.extractValues(placements_data, selectedIndustryNames);
      const rolesContainer = document.getElementById("gr-directory-roles");
      const placementsContainer = document.getElementById("gr-directory-placements");
      let newContent = "";
      let newPlacementsContent = "";
      let specilities = [];
      let placements = [];
      let specilitiesArrays = [];
      let placementsArrays = [];

      var storedValues;
      var storedPlacementsValues;
      if (recruitersPage) {
        storedValues = sessionStorage.getItem('recruitersSpecialtiesSession');
        storedPlacementsValues = sessionStorage.getItem('recruitersPlacementsSession');
      } else {
        storedValues = sessionStorage.getItem('staffingSpecialtiesSession');
        storedPlacementsValues = sessionStorage.getItem('staffingPlacementsSession');
      }
      var stringArray = storedValues ? JSON.parse(storedValues) : [];
      var stringPlacementArray = storedPlacementsValues ? JSON.parse(storedPlacementsValues) : [];

      selectedIndustryNames.forEach((selected_key) => {
        extractedValues[selected_key].forEach((speciality) => {
          specilitiesArrays.push(speciality)
        });
      });

      selectedIndustryNames.forEach((selected_key) => {
        extractedPlacementsValues[selected_key].forEach((placement) => {
          placementsArrays.push(placement)
        });
      });

      specilitiesArrays.sort().forEach((speciality) => {
        newContent += `
          <label class="custom-checkbox">
            <input type="checkbox" name="specialties[]" value="${speciality}" data-action="change->registrations#handleOptionChange">
            <span class="checkmark"></span>
            <span class="dropdown-options">${speciality}</span>
          </label>
        `;
        if (stringArray.includes(speciality)) {
          specilities.push(speciality);
        }
      });

      placementsArrays.sort().forEach((placement) => {
        const serverUrl = window.location.origin;
        newPlacementsContent += `
          <label class="industry-btn" data-controller="directory" data-action="click->directory#selectChanged">
             <input class="hidden-checkbox" type="checkbox" value="${placement}" name="placement_types[]" id="placement_types_${placement.toLowerCase()}">
             <span class="placement-btn-text">${placement}</span>
             <svg class="icon icon-checkmark color-medium checkmark-icon" viewBox="0 0 32 32">
               <use href="${serverUrl}/assets/icons-e6325accb05738d18fda204fbac8dcedafb0432064a1290a6bda71c71d256314.svg#checkmark" height="32" width="32"></use>
             </svg>
          </label>
         `;
        if (stringPlacementArray.includes(placement)) {
          placements.push(placement);
        }
      });

      rolesContainer.innerHTML = newContent;
      placementsContainer.innerHTML = newPlacementsContent;
      if (recruitersPage) {
        sessionStorage.setItem('recruitersSpecialtiesSession', JSON.stringify(specilities));
        sessionStorage.setItem('recruitersPlacementsSession', JSON.stringify(placements));
      } else {
        sessionStorage.setItem('staffingSpecialtiesSession', JSON.stringify(specilities));
        sessionStorage.setItem('staffingPlacementsSession', JSON.stringify(placements));
      }
      this.loadSpecialties();
      this.loadSessionPlacements();
    }

    const searchInput = document.getElementById('speciality-search');
    const specialtiesDropdown = document.getElementById('gr-directory-roles');

    searchInput.addEventListener('input', () => {
      const searchValue = searchInput.value.toLowerCase();
      const specialtyLabels = specialtiesDropdown.querySelectorAll('label.custom-checkbox');

      specialtyLabels.forEach((label) => {
        const optionText = label.querySelector('.dropdown-options').textContent.toLowerCase();
        if (optionText.includes(searchValue)) {
          label.style.display = 'block';
        } else {
          label.style.display = 'none';
        }
      });
    });
  }

  extractValues(jsonData, keysToExtract) {
    const extractedData = {};

    keysToExtract.forEach((key) => {
      if (jsonData[key]) {
        extractedData[key] = jsonData[key];
      }
    });

    return extractedData;
  }

  setIndustriesSession(checkbox) {
    if (checkbox.checked) {
      this.addIndustryValue(checkbox.value);
    } else {
      this.removeIndustryValue(checkbox.value);
    }
  }

  setPlacementSession(checkbox) {
    if (checkbox.checked) {
      this.addPlacementValue(checkbox.value);
    } else {
      this.removePlacementValue(checkbox.value);
    }
  }

  addIndustryValue(value) {
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
        storedValues = sessionStorage.getItem('recruitersindustriesSession');
    } else {
        storedValues = sessionStorage.getItem('staffingindustriesSession');
    }
    var stringArray = storedValues ? JSON.parse(storedValues) : [];

    stringArray = value;
    this.setIndustriesSessionValues(recruitersPage, stringArray);
  }

  setIndustriesSessionValues(recruitersPage, stringArray) {
    if (recruitersPage) {
      sessionStorage.setItem('recruitersindustriesSession', JSON.stringify(stringArray));
    } else {
      sessionStorage.setItem('staffingindustriesSession', JSON.stringify(stringArray));
    }
  }

  addPlacementValue(value) {
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersPlacementsSession');
    } else {
      storedValues = sessionStorage.getItem('staffingPlacementsSession');
    }
    var stringArray = storedValues ? JSON.parse(storedValues) : [];

    stringArray.push(value);
    this.setPlacementsSessionValues(recruitersPage, stringArray);
  }

  setPlacementsSessionValues(recruitersPage, stringArray) {
    if (recruitersPage) {
        sessionStorage.setItem('recruitersPlacementsSession', JSON.stringify(stringArray));
    } else {
        sessionStorage.setItem('staffingPlacementsSession', JSON.stringify(stringArray));
    }
  }

  removeIndustryValue(value) {
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersindustriesSession');
    } else {
      storedValues = sessionStorage.getItem('staffingindustriesSession');
    }
    var stringArray = storedValues ? JSON.parse(storedValues) : [];

    if (Array.isArray(stringArray)) {
      const indexToRemove = stringArray.indexOf(value);
      if (indexToRemove !== -1) {
        stringArray.splice(indexToRemove, 1);
        this.setIndustriesSessionValues(recruitersPage, stringArray);
      }
    }
    else {
      this.setIndustriesSessionValues(recruitersPage, '');
    }
  }

  removePlacementValue(value) {
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersPlacementsSession');
    } else {
      storedValues = sessionStorage.getItem('staffingPlacementsSession');
    }
    var stringArray = storedValues ? JSON.parse(storedValues) : [];

    const indexToRemove = stringArray.indexOf(value);
    if (indexToRemove !== -1) {
      stringArray.splice(indexToRemove, 1);
      this.setPlacementsSessionValues(recruitersPage, stringArray);
    }
  }

  loadSessionIndustries() {
    const industryLabels = document.querySelectorAll('.gr-directory-industries-block .industry-btn');
    const placements_block = $('.gr-directory-specifics-and-placements-block');
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersindustriesSession');
    } else {
      storedValues = sessionStorage.getItem('staffingindustriesSession');
    }
    const stringArray = storedValues ? JSON.parse(storedValues) : [];

    const rolesContainer = document.getElementById("gr-directory-roles");
    let newContent = "";
    const constantValue = this.element.getAttribute('data-constant-value');
    let filtered_value = constantValue.replace(/=>/g, ":");
    let data = JSON.parse(filtered_value)

    industryLabels.forEach(label => {
      const checkbox = label.querySelector('input[type="checkbox"]');

      const exists = stringArray.includes(checkbox.value);
      if (exists) {
        data[checkbox.value].forEach((specialty) => {
          newContent += `
            <label class="custom-checkbox">
              <input type="checkbox" name="specialties[]" value="${specialty}" data-action="change->registrations#handleOptionChange">
              <span class="checkmark"></span>
              <span class="dropdown-options">${specialty}</span>
            </label>
          `;
        });
        checkbox.checked = true;
        checkbox.parentNode.classList.add('selected-industry');
        placements_block.toggleClass('display-hidden', false);
      }
      rolesContainer.innerHTML = newContent;
    });
  }

  loadSessionPlacements() {
    const industryLabels = document.querySelectorAll('.gr-directory-placements-block .industry-btn');
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersPlacementsSession');
    } else {
      storedValues = sessionStorage.getItem('staffingPlacementsSession');
    }
    const stringArray = storedValues ? JSON.parse(storedValues) : [];

    industryLabels.forEach(label => {
      const checkbox = label.querySelector('input[type="checkbox"]');

      const exists = stringArray.includes(checkbox.value);
      if (exists) {
          checkbox.checked = true;
          checkbox.parentNode.classList.add('selected-industry');
      }
    });
  }

  setLocation(location) {
    const recruitersPage = currentPage();

    if (recruitersPage) {
      sessionStorage.setItem('recruiterLocationSession', location)
    } else {
      sessionStorage.setItem('staffingLocationSession', location)
    }
  }

  resetLocation() {
    const recruitersPage = currentPage();

    if (recruitersPage) {
      sessionStorage.removeItem('recruiterLocationSession');
    } else {
      sessionStorage.removeItem('staffingLocationSession');
    }
  }

  loadLocation() {
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruiterLocationSession');
    } else {
      storedValues = sessionStorage.getItem('staffingLocationSession');
    }

    document.querySelector('#directory-hiring-locations').value = storedValues;
  }

  loadRefineLocation() {
    var storedValues;
    if (document.querySelector('#top-recruiters-filter-recruiter-location')) {
      storedValues = new URLSearchParams(window.location.search);
      var recruiterLocation = storedValues.get('top_recruiters_filter[recruiter_location]')
      if(recruiterLocation){
        $('#top-recruiters-filter-recruiter-location').append(`<option value="${recruiterLocation}">${recruiterLocation}</option>`);
      }
      var recruiterLocationWithin = storedValues.get('top_recruiters_filter[recruiter_location_within]')

      if(recruiterLocationWithin != null){
        document.querySelector('#top_recruiters_filter_recruiter_location_within').value = recruiterLocationWithin;
      }
      if (recruiterLocationWithin && recruiterLocationWithin != '-1')
        document.querySelector('#top_recruiters_filter_recruiter_location_within').disabled = false
    } else {
      storedValues = new URLSearchParams(window.location.search);
      var companyLocation = storedValues.get('top_companies_filter[company_location]');
      if(companyLocation){
        $('#top-companies-filter-company-location').append(`<option value="${companyLocation}">${companyLocation}</option>`);
      }
      var companyLocationWithin = storedValues.get('top_companies_filter[company_location_within]')
      if( companyLocationWithin != null){
        document.querySelector('#top_companies_filter_company_location_within').value = companyLocationWithin;
      }
      if (companyLocationWithin && companyLocationWithin != '-1')
        document.querySelector('#top_companies_filter_company_location_within').disabled = false
    }
  }

  loadSpecialties() {
    updateSpecialtyInnerText();

    const checkboxes = document.querySelectorAll('input[name="specialties[]"]');
    const recruitersPage = currentPage();
    var storedValues;

    if (recruitersPage) {
      storedValues = sessionStorage.getItem('recruitersSpecialtiesSession');
    } else {
      storedValues = sessionStorage.getItem('staffingSpecialtiesSession');
    }
    const stringArray = storedValues ? JSON.parse(storedValues) : [];

    checkboxes.forEach((checkbox) => {
      const exists = stringArray.includes(checkbox.value);
      if (exists) {
        checkbox.checked = true;
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const recruiterTextContainer = document.querySelector(".recruiter-text-container");
  if (!recruiterTextContainer) return;
  function showRecruiterText() {
    recruiterTextContainer.classList.remove("display-hidden");
  }

  function hideRecruiterText() {
    recruiterTextContainer.classList.add("display-hidden");
  }

  if (sessionStorage.getItem("showRecruiterText") === "true") {
    showRecruiterText();
  }

  document.querySelectorAll(".industry-item").forEach((industry) => {
    industry.addEventListener("click", function () {
      sessionStorage.setItem("showRecruiterText", "true");
      showRecruiterText();
    });
  });

  window.addEventListener("pageshow", function (event) {
    if (event.persisted || sessionStorage.getItem("showRecruiterText") === "true") {
      showRecruiterText();
    } else {
      hideRecruiterText();
    }
  });

  window.addEventListener("popstate", function () {
    if (sessionStorage.getItem("showRecruiterText") !== "true") {
      hideRecruiterText();
    }
  });
});

$(document).ready(function() {
  if (window.location.href.includes('healthcare')) {
    $('#directory-hiring-locations-select2').on('change', function() {
      $('#ranking-form')[0].submit();
      document.getElementById("ranking-form").reset();
    });
  }
});
