export default class VisibilityAndNotifications {
  initializeListeners() {
    $(document).on('change', '#visibility-and-notifications-form .user-attributes-checkbox', (event) => this.toggleCheckbox(event)
    ).on('change', '#visibility-and-notifications-form .user-attributes-checkbox-notify', (event) => this.toggleNotifyCheckbox(event)
    ).on('change', '#visibility-and-notifications-form .js-review-type', this.toggleReviewType
    ).on('click', '#visibility-and-notifications-form input[type="submit"]', this.submitForm);
  }

  submitForm(event) {
    const $form = $(event.target).closest('form');

    if (!$form.hasClass('loading')) {
      $form
        .find('input[type="submit"]')
        .prop("disabled", true)
        .end()
        .addClass('loading')
        .submit();
    }
  }

  toggleCheckbox(event) {
    const $el = $(event.currentTarget);
    const isChecked = $el.is(':checked');
    const id = $el.attr('id');

    $el.siblings(`#${id}_notify`).val(isChecked);
    $el.siblings(`#${id}_location_id`).val($el.val());
    $el.siblings(`#${id}_department_id`).val($el.val());
    $el.siblings(`#${id}__destroy`).val(isChecked && '0' || '1');

    this.submitForm(event);
  }

  toggleNotifyCheckbox(event) {
    const $el = $(event.currentTarget);
    const id = $el.attr('id').split('_notify')[0];
    const isChecked = $el.is(':checked');

    $(`#visibility-and-notifications-form #${id}_notify`).val(isChecked);
    $(`#visibility-and-notifications-form #${id}_location_id`).val($el.val());
    $(`#visibility-and-notifications-form #${id}_department_id`).val($el.val());

    this.submitForm(event);
  }

  toggleReviewType(event) {
    const $el = $(event.currentTarget);
    const isChecked = $el.is(':checked');

    if ($('.js-review-type:checked').length) {
      $('.teams-section').removeClass('is-hidden');
    } else {
      $('.teams-section').removeClass('is-hidden').addClass('is-hidden');
    }

    if (isChecked) {
      $(`.review-type-${$el.val()}-row`).removeClass('is-hidden');
    } else {
      $(`.review-type-${$el.val()}-row`).removeClass('is-hidden').addClass('is-hidden');
    }
  }
}
