// TODO: Check if this is still needed
export default class PositionWatch {
  initializeListeners() {
    setElementPosition();

    $(window).resize(() => setElementPosition());
  }
}

const setElementPosition = () => {
  $('[data-position-watch]').toArray().forEach(el => {
    const data = $(el).data('position-watch');
    const position = parseFloat($(el).css(data.by)) * (data.size / 100);
    $(el).css(data.property, `${position}px`);
  });
};
