import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.modal = document.getElementById("contact-modal");
    this.successModal = document.getElementById("success-modal");
    this.originalEmail = document.getElementById("email-display").textContent.trim();
  }

  newModal(event) {
    event.preventDefault();
    const errorIntroElement = document.getElementById('intro-error');
    if (errorIntroElement) {
      errorIntroElement.remove();
    }
    openModal(this.modal);
    modal.classList.remove("hide");
  }

  success(event) {
    event.preventDefault();
    const target = event.target
    this.openSuccessModal();
    target.reset();
  }

  success(event) {
    event.preventDefault();
    const target = event.target;
    this.openSuccessModal();
    setTimeout(() => {
      target.reset();
      document.getElementById("email-display").textContent = this.originalEmail;
      document.getElementById("email-edit-field").querySelector('.email-input').value = this.originalEmail;
    }, 1000);
  }

  openSuccessModal() {
    openModal(this.successModal);
  }  
}

document.addEventListener("DOMContentLoaded", function () {
  const emailDisplay = document.getElementById("email-display");
  const emailEditField = document.getElementById("email-edit-field");
  const changeEmailBtn = document.getElementById("change-email-btn");
  const saveEmailBtn = document.getElementById("save-email-btn");
  const emailInput = emailEditField.querySelector('.email-input');
  const formSubmitBtn = document.querySelector('.modal-btn');

  let emailChanged = false;

  changeEmailBtn.addEventListener("click", function () {
    emailDisplay.style.display = "none";
    changeEmailBtn.style.display = "none";
    emailEditField.classList.remove("hide");
    emailInput.focus(); 
    emailChanged = true;
  });

  saveEmailBtn.addEventListener("click", function () {
    saveEmail();
  });

  formSubmitBtn.addEventListener("click", function (event) {
    const introInput = document.querySelector('.introduction-input').value.trim();
    if (emailChanged) {
      event.preventDefault();
      showErrorMessage("Please enter a valid email address.", 'email-error', emailEditField);
      formSubmitBtn.removeAttribute('data-close');
      return;
    }
    else if (!introInput) {
      event.preventDefault();
      const introductionField = document.querySelector('.introduction-input');
      showErrorMessage("Please provide an introduction.", 'intro-error', introductionField.parentElement);
      formSubmitBtn.removeAttribute('data-close');
    }
  });

  function validateEmail(email) {
    const emailPattern = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i;
    return emailPattern.test(email) && email.length > 0 && email.indexOf(' ') === -1;
  }

  function showErrorMessage(message, elementId, parentElement) {
    let errorElement = document.getElementById(elementId);
    
    if (!errorElement) {
      errorElement = document.createElement('div');
      errorElement.id = elementId;
      errorElement.style.color = 'red';
      errorElement.style.marginTop = '5px';
      
      parentElement.appendChild(errorElement);
    }
    errorElement.textContent = message;
  }

  function clearErrorMessage() {
    const errorElement = document.getElementById('email-error');
    if (errorElement) {
      errorElement.remove();
    }
  }

  function saveEmail() {
    const emailValue = emailInput.value.trim();
    clearErrorMessage();

    if (!validateEmail(emailValue)) {
      showErrorMessage("Please enter a valid email address.", 'email-error', emailEditField);
      return;
    }

    emailDisplay.textContent = emailValue;

    emailEditField.classList.add("hide");
    emailDisplay.style.display = "inline";
    changeEmailBtn.style.display = "inline";
    emailChanged = false;
  }

  document.addEventListener("keydown", function (event) {
    
    if (event.key === "Enter") {
      event.preventDefault();

      if (!emailEditField.classList.contains("hide")) {
        saveEmail();
      } else {
        formSubmitBtn.click();
      }
    }
  });
});