import { Controller } from "stimulus";
import confetti from "canvas-confetti";

export default class extends Controller {
  static targets = ["timerLine"];

  modalClose = false;
  achievements = [];
  timerIntervalId = null;
  timerInSeconds = 5;
  achievementConfig = [
    { type: "badge", modalId: "badge-unlocked-modal" },
    { type: "award", modalId: "award-unlocked-modal" },
    { type: "milestone", modalId: "milestone-unlocked-modal" },
  ];

  connect() {
    document.addEventListener("click", this.handleClickOutsideModal.bind(this));
    this.collectAchievements();
  }

  disconnect() {
    document.removeEventListener(
      "click",
      this.handleClickOutsideModal.bind(this)
    );
  }

  handleClickOutsideModal(event) {
    this.closeModal();
  }

  async collectAchievements() {
    for (const config of this.achievementConfig) {
      if (this.newAchievementForCurrentUser(config.type)) {
        this.achievements.push(config);
      }
    }

    if (this.achievements.length > 0) {
      this.showNextAchievement();
    }
  }

  newAchievementForCurrentUser(achievementType) {
    const achievement = $(`#${achievementType}-unlocked-modal`);
    if (achievement.length > 0 && !achievement.attr("displayed")) {
      achievement.attr("displayed", true);
      return true;
    }

    return false;
  }

  showNextAchievement() {
    const nextAchievement = this.achievements[0];
    this.showConfetti();
    openModal(`#${nextAchievement.modalId}`);
    this.setTimerLine();
  }

  showConfetti() {
    const duration = this.timerInSeconds * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 180, ticks: 60, zIndex: 1 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (this.modalClose) {
        confetti.reset();
        return;
      }

      const particleCount = Math.floor(50 * (timeLeft / duration));

      this.confetti(Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() },
      }));
      this.confetti(Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.4, 0.6), y: Math.random() },
      }));
      this.confetti(Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() },
      }));

    }, 50);

    const badgeUnlockedModalBackground = document.getElementById(
      this.achievements[0].modalId
    ).parentElement;
    badgeUnlockedModalBackground.setAttribute("id", "reveal-overlay-badge");
  }

  confetti(options) {
    confetti(options);
  }

  updateTimerLine(timeRemaining) {
    if (this.achievements.length > 0) {
      const percentageRemaining = (timeRemaining / this.timerInSeconds) * 100;
      $(`#${this.achievements[0].type}-timer-line`).css(
        "width",
        `${percentageRemaining}%`
      );
    }
  }

  setTimerLine() {
    let timeRemaining = this.timerInSeconds;
    this.timerIntervalId = setInterval(() => {
      timeRemaining--;
      this.updateTimerLine(timeRemaining);
      if (timeRemaining === 0) {
        clearInterval(this.timerIntervalId);
        if (!this.modalClose) {
          this.closeModal();
        }
      }
    }, 1000);
  }

  closeModal() {
    const currentAchievement = this.achievements.shift();
    if (currentAchievement) {
      this.modalClose = true;
      clearInterval(this.timerIntervalId);
      confetti.reset();
      this.timerIntervalId = null;
      $(`#${currentAchievement.modalId}`).foundation("close");
      if (this.achievements.length > 0) {
        this.modalClose = false;
        this.showNextAchievement();
      }
    }
  }
}
