import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#parent-company-select').select2({
      width:'100%',
      maximumSelectionLength: 5,
      placeholder: 'Select a Parent company'
    });

    $('#child-company-select').select2({
      width:'100%',
      placeholder: 'Select Child Companies'
    });

    $('#parent-company-select').on('change.select2', () => {
      this.selectedCompany();
    });

    $('#child-company-select').on('change.select2', () => {
      this.selectedChild();
    });
  }

  async ManageCompanies(event) {
    event.preventDefault();
    $('#manage-company-modal select').val('');
    $('#select2-parent-company-select-container').text('Select a Parent company');
    $('.child-companies-block')[0].classList.add('display-none');
    $('.super-admin-submit-btn')[0].classList.add("disabled");
    this.selectedCompany();
    $('#manage-company-modal').attr('data-action', 'create');
    openModal('#manage-company-modal');
  }

  async selectedCompany() {
    let company_id = $('.company-dropdown')[0].value;
    let child_companies_block = $('.child-companies-block')[0];

    $('.child-companies-block select').val('');
    document.getElementById("child-company-select").innerText = '';
    this.selectedChild();

    const formData = new FormData();
    formData.append('company_id', company_id);

    try {
      const response = await fetch('/manage_companies/unselected_companies', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: formData,
      });
      const data = await response.json();
      let child_companies = data.child_companies;
      this.displayChildCompanies(child_companies);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }

    if (company_id) {
      child_companies_block.classList.remove('display-none');
    }
  }

  async selectedChild() {
    let parent_company = $('.company-dropdown')[0].value;
    let child_company_dropdown = $('.child-company-dropdown')[0].value;

    if (parent_company != '' && parent_company != 'Select a Parent company' && child_company_dropdown != '') {
      $('.super-admin-submit-btn')[0].classList.remove("disabled");
    }
    else {
      $('.super-admin-submit-btn')[0].classList.add("disabled");
    }
  }

  async editParentCompany(event) {
    event.preventDefault();
    let childCompanies = this.element.getAttribute("child_companies");
    let parentCompanyId = this.element.getAttribute("parent_company_id");
    let parentCompanyName = this.element.getAttribute("parent_company_name");
    const parentCompanySelect = document.getElementById("parent-company-select");
    const companyOption = parentCompanySelect.querySelector(`option[value="${parentCompanyId}"]`);
    document.getElementById("select2-parent-company-select-container").textContent = parentCompanyName;

    if (companyOption) {
      companyOption.selected = true;
    }

    $('#manage-company-modal').attr('data-action', 'edit');
    openModal('#manage-company-modal');
    this.editRecord(childCompanies);
  }

  async editRecord(childCompanies) {
    let parentCompanyId = $('.company-dropdown')[0].value;
    let childCompaniesBlock = $('.child-companies-block')[0];
    let childContainer = document.getElementById("child-company-select");
    const childCompaniesValues = childCompanies.split(', ');
    const childCompaniesData = [];

    for (let i = 0; i < childCompaniesValues.length; i += 2) {
      const companyId = childCompaniesValues[i];
      const companyName = childCompaniesValues[i + 1];

      childCompaniesData.push({ companyId, companyName });
    }

    const formData = new FormData();
    formData.append('parentCompanyId', parentCompanyId);

    try {
      const response = await fetch('/manage_companies/unselected_companies', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: formData,
      });
      const data = await response.json();
      let unselected_companies = data.child_companies;
      let newContent = "";

      childCompaniesData.forEach((company) => {
        newContent += `
          <div>
            <option value="${company.companyId}" selected>${company.companyName}</option>
          </div>
        `;
      });

      unselected_companies.forEach((company) => {
        newContent += `
          <div>
            <option value="${company[0]}">${company[1]}${company[2]}</option>
          </div>
        `;
      });

      childContainer.innerHTML = newContent;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }

    if (parentCompanyId) {
      childCompaniesBlock.classList.remove('display-none');
    }

    this.selectedChild();
  }

  displayChildCompanies(companies) {
    const childCompaniesContainer = document.getElementById("child-company-select");
    let newContent = "";

    companies.forEach((company) => {
      newContent += `
        <div>
          <option value="${company[0]}">${company[1]}</option>
        </div>
      `;
    });

    childCompaniesContainer.innerHTML = newContent;
  }

  async saveParentChildCompanies(event) {
    event.preventDefault();
    let parent_company = $('.company-dropdown')[0].value;
    const selectedOptions = Array.from($('.child-company-dropdown')[0].selectedOptions);
    const selectedChildIds = selectedOptions.map(option => option.value);
    const modal = $('#manage-company-modal');
    const action = modal.attr('data-action');
    let noticeMessage = 'Parent Company successfully created!';

    if (action === 'edit') {
      noticeMessage = 'Parent Company successfully updated!';
    }

    if (parent_company == '' || parent_company == 'Select a Parent company' || selectedOptions == '') {
      $('.super-admin-submit-btn')[0].classList.add("disabled");
    } else {
      try {
        const url = `/manage_companies/${selectedChildIds.join(',')}`;
        const queryParams = new URLSearchParams({ parent_company, noticeMessage });
        const response = await fetch(`${url}?${queryParams.toString()}`, {
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
          },
        });
        location.reload();
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  }

  async deleteParentChildCompanies(event) {
    event.preventDefault();
    let company_id = this.element.getAttribute("parent_company_id");
    const confirmed = window.confirm("Are you sure you want to delete?");

    if (confirmed) {
      try {
        const response = await fetch(`/manage_companies/${company_id}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
          },
        });

        location.reload();
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  }
}
