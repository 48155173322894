export default class AbsoluteAdjust {
  initializeListeners() {
    $('[data-watch-absolute]').toArray().forEach(container => {
      $(container).toArray().forEach(child => {
        if ($(child).css('position') === 'absolute') {
          $(child).attr('data-absolute', '');
        }
      });

      adjustAbsolute(container);
    });

    $(window).resize(() => {
      $('[data-watch-absolute]').toArray().forEach(container => adjustAbsolute(container));
    });
  }
}

const adjustAbsolute = (container) => {
  const containerHeight = $(container).outerHeight();
  let childrenHeight = 0;

  $(container).children().toArray().forEach(child => childrenHeight += $(child).outerHeight(true));

  if (childrenHeight > containerHeight) {
    $(container).children().toArray().forEach(child => $(child).css('position', 'relative'));
  } else {
    $(container).find('[data-absolute]').css('position', 'absolute');
  }
};
