import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["awards", "milestones", "badges", "publicTooltip"];

  connect() {
    this.fetchAchievementsDataOnPageLoad();
  }

  fetchAchievementsDataOnPageLoad() {
    const userId = this.badgesTarget.dataset.userId;
    this.fetchAchievementData(this.generateUrl("badges", userId, 1), "badges");
    this.fetchAchievementData(this.generateUrl("awards", userId, 1), "awards");
    this.fetchAchievementData(this.generateUrl("milestones", userId, 1),"milestones");
  }

  generateUrl(type, user_id, page) {
    return `${window.location.origin}/users/achievements?id=${user_id}&achievement_type=${type}&page=${page}`;
  }

  fetchAchievementData(url, type) {
    fetch(url, {
      method: "GET",
      headers: { Accept: "application/json"},
    })
      .then((response) => {
        if (!response.ok && response.status !== 422) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (typeof data === "object") {
          if (data.content) {
            $(".spinner-container").addClass("hide");
            $(`#${type}`).append(data.content);
            if (data.next_page_available) {
              const page =$(`#${type}-page`).text();
              $(`#${type}-page`).text(parseInt(page) +1);
              $(`#${type}-button`).removeClass("hide");
            } else {
              $(`#${type}-button`).addClass("hide");
            }
          } else {
            console.error(
              "Error: No or invalid content found in the response data."
            );
          }
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  loadMore(event) {
    event.preventDefault();
    const userId = this.badgesTarget.dataset.userId;
    const type = event.target.dataset.achievementType;
    const page =$(`#${type}-page`).text();
    if (type && page) {
      this.fetchAchievementData(this.generateUrl(type, userId, parseInt(page)),type);
    }
  }

  showTooltip() {
    this.publicTooltipTarget.classList.remove("hide");
  }

  hideTooltip() {
    this.publicTooltipTarget.classList.add("hide");
  }
}
