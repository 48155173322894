import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.clearPlanId();
    const publishableKey = $("#stripe-key").val();
    this.defaultPlanId =
      this.element.dataset.annuallySeatPlan || "Seats Annually Premium (60$/month)";
    this.defaultPrice = this.element.dataset.annuallySeatPrice || 60;
    this.planId = this.defaultPlanId || this.getStoredPlanId();
    this.planId = this.getStoredPlanId();
    this.storePlanId(this.planId, this.defaultPrice);
    let seats = $("#company_max_user_count").val();
    $("#annually-seat-count").val(seats);
    this.setTotalPrice(seats, "annually");
  }

  getStoredPlanId() {
    return localStorage.getItem("selected_plan") || this.defaultPlanId;
  }

  getStoredPrice() {
    return localStorage.getItem("selected_price") || this.defaultPrice;
  }

  storePlanId(planId, price) {
    this.planId = planId;
    localStorage.setItem("selected_plan", planId);
    localStorage.setItem("selected_price", price);
  }

  clearPlanId() {
    this.planId = this.defaultPlanId;
    localStorage.removeItem("selected_plan");
  }

  selectedPlanAnually() {
    let seats = $("#company_max_user_count").val();
    $("#annually-seat-count").val(seats);
    this.storePlanId(
      "Seats Annually Premium (60$/month)",
      this.element.dataset.annuallySeatPrice
    );
    this.setTotalPrice(seats, "annually");
  }

  selectedPlanMonthly() {
    let seats = $("#company_max_user_count").val();
    $("#monthly-seat-count").val(seats);
    this.storePlanId(
      "Seats Monthly Premium (85$)",
      this.element.dataset.monthlySeatPrice
    );
    this.setTotalPrice(seats, "monthly");
  }

  resetStripeElements() {
    if (this.cardElement) {
      this.cardElement.destroy();
    }
    this.stripeElements();
  }

  handleSubmitBtn() {
    let seats = $("#company_max_user_count").val();
    return this.validateSeatCount(seats);
  }

  handleInputField(type, seats) {
    let inputSeat = $(`#${type}-seat-count`).val();
    if (inputSeat.endsWith(".")) {
      $("#company_max_user_count").val(inputSeat.slice(0, -1));
      $(`#${type}-seat-count`).val(inputSeat.slice(0, -1));
    }

    if (!Number.isInteger(Number(seats))) {
    }
    var intValue = parseInt(seats, 10);
    if (isNaN(intValue)) {
      $("#company_max_user_count").val("");
      $(`#${type}-seat-count`).val("");
    } else {
      $("#company_max_user_count").val(intValue);
      $(`#${type}-seat-count`).val(intValue);
    }
  }

  validateSeatCount(seats) {
    if (seats && parseInt(seats) >= 1) {
      $("#comapny-signup-btn").removeClass("disabled");
      $("#comapny-signup-btn").prop("disabled", false);
      $(`.seat-error`).removeClass("hide");
      $(`.seat-error`).text("");
      return true;
    } else {
      $("#comapny-signup-btn").prop("disabled", true);
      $("#comapny-signup-btn").addClass("disabled");
      $(`.seat-error`).removeClass("hide");
      $(`.seat-error`).text("Seat count should be greater than or equal to 1.");
      return false;
    }
  }

  handleSeatError(type) {
    const inputSeatField = $(`#${type}-seat-count`);
    let seats = $("#company_max_user_count").val();
    this.handleInputField(type, seats);
    if (!inputSeatField.val()) {
      $("#comapny-signup-btn").addClass("disabled");
      $("#comapny-signup-btn").prop("disabled", true);
      $(`.seat-error`).removeClass("hide");
      $(`.seat-error`).text("Seat count can't be blank.");
      return false;
    } else {
      this.validateSeatCount(seats);
    }
  }

  handleSeatCount(event) {
    const element = event.target;
    const type = element.dataset.type;
    $("#company_max_user_count").val(element.value);
    this.handleSeatError(type);
    this.setTotalPrice(element.value, type);
  }

  setTotalPrice(seats, type) {
    if (!seats) {
      $(`#${type}-total`).text("$0");
      return 0;
    }
    let totalSeats = parseInt(seats);
    if (totalSeats <= 0) {
      totalSeats = 0;
      $(`#due-${type}`).addClass("hide");
    } else {
      $(`#due-${type}`).removeClass("hide");
    }
    const total = $(`#${type}-total`);
    let totalPrice = totalSeats * this.getStoredPrice();
    if (type === "annually") {
      totalPrice = totalSeats * this.getStoredPrice() * 12;
    }
    total.text(`$${this.formatNumber(totalPrice) || 0}`);
  }

  submitStep(event) {
    event.preventDefault();
    const formElement = document.getElementById("company-signup-form");
    if (this.handleSubmitBtn()) {
      this.appendHiddenFields(formElement, this.getStoredPlanId());
      this.clearPlanId();
      formElement.submit();
    }
  }

  appendHiddenFields(formElement, planId) {
    formElement.appendChild(
      this.createFormField("company[plan_name]", planId, "hidden", "plan-name")
    );
  }

  createFormField(fieldName, value, type, id) {
    const newField = document.createElement("input");
    newField.type = type;
    newField.name = fieldName;
    newField.value = value;
    newField.id = id;
    return newField;
  }

  validateActiveUserCount(element) {
    const activeUsers = this.parseNumber(element.dataset.activeUsers);
    const currentUsers = this.parseNumber(element.dataset.currentUsers);
    const planAmount = this.parseNumber(element.dataset.planAmount);
    let userInput = this.cleanInput(element.value);
    const seatCountChange = this.validateSeatCountInput(userInput, element);
    if (seatCountChange === null) return;

    const totalSeatCount = seatCountChange + currentUsers;
    this.updateSeatCountDetails(totalSeatCount, planAmount);
    if (
      this.isInvalidSeatChange(
        seatCountChange,
        currentUsers,
        activeUsers,
        element
      )
    )
      return;

    if (seatCountChange === 0) {
      this.disableSubmitButton();
      this.clearError(element);
      return;
    }

    this.clearError(element);
    this.enableSubmitButton();
  }

  parseNumber(value) {
    return parseInt(value, 10);
  }

  cleanInput(input) {
    return input.trim().endsWith(".") ? input.slice(0, -1) : input.trim();
  }

  validateSeatCountInput(userInput, element) {
    let record = this.element.dataset.stripeRecord;
    if (!userInput) {
      record = record
        .split(" ")
        .map((d) => d.charAt(0).toUpperCase() + d.slice(1))
        .join(" ");

      this.displayError(`${record} count can't be blank.`, element);
      return null;
    }

    const seatCountChange = this.parseNumber(userInput);
    if (isNaN(seatCountChange)) {
      element.value = 0;
      return 0;
    }

    return seatCountChange;
  }

  isInvalidSeatChange(seatCountChange, currentUsers, activeUsers, element) {
    let record = this.element.dataset.stripeRecord;
    let grRecord = this.element.dataset.grRecord;
    let msg = `Please disable some ${grRecord} to decrease ${record} count.`;
    if (seatCountChange < 0 && currentUsers + seatCountChange < activeUsers) {
      this.displayError(msg, element);
      return true;
    }

    if (seatCountChange < 0 && currentUsers === activeUsers) {
      this.displayError(msg, element);
      return true;
    }

    return false;
  }

  displayError(message, element) {
    $("#seat-error").text(message).removeClass("hide");
    $("#comapny-signup-btn").prop("disabled", true).addClass("disabled");
    element.classList.add("company-seats-field-error");
  }

  clearError(element) {
    $("#seat-error").addClass("hide");
    element.classList.remove("company-seats-field-error");
  }

  updateSeatCountDetails(totalSeatCount, planAmount) {
    $("#company_max_user_count").val(totalSeatCount);
    $(".updated-seat-count").text(totalSeatCount);
    $("#total-payment").text(this.formatNumber(totalSeatCount * planAmount));
  }

  disableSubmitButton() {
    $("#comapny-signup-btn").prop("disabled", true).addClass("disabled");
  }

  enableSubmitButton() {
    $("#comapny-signup-btn").prop("disabled", false).removeClass("disabled");
  }

  validate_seat_count(event) {
    this.validateActiveUserCount(event.target);
  }

  updateSeatCount(event) {
    event.preventDefault();
    document.getElementById("company-seats-form").submit();
  }

  formatNumber(number) {
    return number.toLocaleString();
  }

  increment() {
    const targetElement = document.getElementById("new-seat-count");
    targetElement.value = this.parseNumber(targetElement.value) + 1;
    this.validateActiveUserCount(targetElement);
  }

  decrement() {
    const targetElement = document.getElementById("new-seat-count");
    targetElement.value = this.parseNumber(targetElement.value) - 1;
    this.validateActiveUserCount(targetElement);
  }
}
