import { Controller } from 'stimulus';

export default class extends Controller {
  showTestimonialCardPreview(event) {
    const style = event.currentTarget.value;

    if (this.targets.has(style)) {
      this.targets.findAll('style').forEach(styleEl => {
        if (!styleEl.classList.contains('is-hidden')) {
          styleEl.classList.add('is-hidden');
        }
      });

      this.targets.find(style).classList.remove('is-hidden');
    }
  }
}
