export default class TempUploadImage {
  initializeListeners() {
    $(document).on('change', 'input[type="file"]', (event) => {
      const input = $(event.currentTarget);
      const filename = input.val().split(/([\\/])/g).pop();
      const label = input.siblings('.filename-label');
      const originalLabel = label.text();
      const img = input.parents('.image-uploader').find('.img').first();

      setTempUploadImage(input, img, label, originalLabel);
    })
  }
}

const setTempUploadImage = (input, img, label, originalLabel) => {
  if (input[0].files && input[0].files[0]) {
    const reader = new FileReader();
    reader.onload = function(e) {
      img.find('img').remove();
      img.css('background-image', "url('/images/success.png')");
      label.text(originalLabel);
    };

    reader.readAsDataURL(input[0].files[0]);
  }
};
