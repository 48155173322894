import { Controller } from "stimulus"

export default class extends Controller {

    setElementAttributes(el, attrs) {
        for(var key in attrs) {
          el.setAttribute(key, attrs[key]);
        }
      }

    showEmbed(id, enteredVal) {
        const url = 'https://www.youtube.com/embed/'+id;
        var showUrl = document.createElement('a');
        showUrl.innerHTML = 'Embedded Video';
        showUrl.setAttribute('href', enteredVal);


        const youtubeIframe = document.createElement('iframe');

        this.setElementAttributes(youtubeIframe, {"id": "ytplayer", "type": "text/html", "width": "840", "height": "360",
                             "src": url, "frameboard": "0"
                            });
        document.querySelector('.trix-content').appendChild(showUrl);
        document.querySelector('.trix-content').insertAdjacentElement('afterend', youtubeIframe);
    }

    makeEmbed(event) {
        const enteredVal = document.getElementsByClassName("trix-input--dialog")[0].value;
        const pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/
        let matches = enteredVal.match(pattern)
        if (matches !== null) {
            let id = matches[2]
            this.showEmbed(id, enteredVal);
        }
      };

    connect() {
        const embedBtn2 = document.createElement('input');
        this.setElementAttributes(embedBtn2, {"id": "embedBtn", "type": "button", "class": "trix-button trix-button--dialog", "value": "Embed URL",
                             "data-controller": 'trix', "data-action": "click->trix#makeEmbed",
                             "data-behavior": "embed_container"
                            });
        const once = {
            once: true
        }

        addEventListener("trix-initialize", function(event) {
            event.target.toolbarElement.
            querySelector(".trix-dialog__link-fields").querySelector(".trix-button-group").
            appendChild(embedBtn2);
        }, once)
    }
}
