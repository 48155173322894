import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.updateSubmitButtonState();
    document.addEventListener("click", this.updateSubmit.bind(this));
    document.addEventListener("change", this.updateSubmit.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.updateSubmit.bind(this));
    document.addEventListener("change", this.updateSubmit.bind(this));
  }

  updateSubmit() {
    this.updateSubmitButtonState();
  }

  updateSubmitButtonState() {
    const companyTypeElement = $('.candidate-request')[0];
    const companyType = companyTypeElement.getAttribute('company-type');
    const revieweeableField = this.element.querySelector("#review_batch_0_revieweeable_id");

    if(revieweeableField) {
      this.companySubmitButton();
    } else {
      this.individualSubmitButton();
    }
  }

  individualSubmitButton() {
    const form = document.querySelector('#new_review');
    const submitButton = form.querySelector('#review-request-submit');
    const rows = form.querySelectorAll(".candidate-request");

    const canSubmit = Array.from(rows).map((row, index) => {
      const reviewBatchId = `#review_batch_${index}_`;
      const inputs = row.querySelectorAll(`${reviewBatchId}candidate_first_name, ${reviewBatchId}candidate_last_name, ${reviewBatchId}candidate_email`);
      const isRowComplete = Array.from(inputs).every((input) => input.value.trim() !== "");
      return isRowComplete;
    });

    const filledFieldsCount = Array.from(rows).map((row, index) => {
      const reviewBatchId = `#review_batch_${index}_`;
      const inputs = row.querySelectorAll(`${reviewBatchId}candidate_first_name, ${reviewBatchId}candidate_last_name, ${reviewBatchId}candidate_email`);
      const filledInputs = Array.from(inputs).filter((input) => input.value.trim() !== "");
      return filledInputs.length;
    });

    if (canSubmit.some((rowComplete) => rowComplete)) {
      if (!filledFieldsCount.every((count) => count == 0 || count == 3)) {
        canSubmit.fill(false);
      }
    }

    const enableSubmitButton = canSubmit.some((rowComplete) => rowComplete);
    submitButton.toggleAttribute('disabled', !enableSubmitButton);
  }

  companySubmitButton() {
    const form = document.querySelector('#new_review');
    const submitButton = form.querySelector('#review-request-submit');
    const rows = form.querySelectorAll(".candidate-request");

    const canSubmit = Array.from(rows).map((row, index) => {
      const reviewBatchId = `#review_batch_${index}_`;
      const inputs = row.querySelectorAll(`${reviewBatchId}candidate_first_name, ${reviewBatchId}candidate_last_name, ${reviewBatchId}candidate_email, ${reviewBatchId}revieweeable_id`);
      const isRowComplete = Array.from(inputs).every((input) => input.value.trim() !== "");
      return isRowComplete;
    });

    const filledFieldsCount = Array.from(rows).map((row, index) => {
      const reviewBatchId = `#review_batch_${index}_`;
      const inputs = row.querySelectorAll(`${reviewBatchId}candidate_first_name, ${reviewBatchId}candidate_last_name, ${reviewBatchId}candidate_email, ${reviewBatchId}revieweeable_id`);
      const filledInputs = Array.from(inputs).filter((input) => input.value.trim() !== "");
      return filledInputs.length;
    });

    if (canSubmit.some((rowComplete) => rowComplete)) {
      if (!filledFieldsCount.every((count) => count == 0 || count == 4)) {
        canSubmit.fill(false);
      }
    }

    const enableSubmitButton = canSubmit.some((rowComplete) => rowComplete);
    submitButton.toggleAttribute('disabled', !enableSubmitButton);
  }
}
