const Mousetrap = require('mousetrap');

export default class Impersonate {
  initializeListeners() {
    if (process.env.NODE_ENV !== 'development') {
      return true;
    }

    Mousetrap.bind('command+i', (event) => {
      $('.impersonate-container .bg-overlay').addClass('active');
      return true;
    });

    Mousetrap.bind('esc', (event) => {
      $('.impersonate-container .bg-overlay').removeClass('active');
      return true;
    });
  }
}
