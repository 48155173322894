export default class ActiveToggle {
  initializeListeners() {
    $(document).on('click', '[data-active-toggle]', (event) => {
      if ($(event.target).is('[data-block-active-toggle]') ||
        $(event.target).parents('[data-block-active-toggle]').length) {
        return true;
      }

      event.preventDefault();

      const ref = $(event.currentTarget).data('active-toggle');
      for (let selector in ref) {
        $(selector).toggleClass(ref[selector]);
      }
    });
  }
}
