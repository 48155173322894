export default class {
  constructor(controller, targetOrData) {
    this.controller = controller;
    this.targetOrData = targetOrData;
  }

  isValid(value) {
    return value === this.equalValue;
  }

  get equalValue() {
    try {
      if (this.targetOrData.startsWith('@')) {
        return this.controller.data.get(this.targetOrData.substring(1));
      } else {
        return this.controller.targets.find(this.targetOrData.substring(1));
      }
    } catch (e) {
      return undefined;
    }
  }
}
