// app/javascript/controllers/shared_functions.js

export function updateSpecialtyInnerText() {
  const grDirectory = document.querySelector('.gr-directory-industries-block');
  if (grDirectory) {
    const dropdownToggle = document.querySelector('.dropdown-toggle-1');
    const recruitersPage = currentPage();
    const storedValues = recruitersPage
      ? sessionStorage.getItem('recruitersSpecialtiesSession')
      : sessionStorage.getItem('staffingSpecialtiesSession');

    if (storedValues) {
      const stringArray = storedValues.replace(/[\["\]]/g, '');

      if (stringArray.length > 0) {
        const subStrLength = dropdownToggle.offsetWidth / 9;
        let firstNOptions;
        dropdownToggle.classList.remove("drop-down-placeholder");
        if (subStrLength > stringArray.length) {
          firstNOptions = stringArray.slice(0, subStrLength);
        } else {
          firstNOptions = stringArray.slice(0, subStrLength) + '...';
        }
        dropdownToggle.innerText = firstNOptions;
      }
    }
  }
}

export function currentPage() {
  return $('#ranking-form')[0].action.includes('top_recruiters');
}
