import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    $('#custom_review_anchor_tag').val($('.tabs-title.is-active').children().attr('aria-controls'))
    if ($('.disabled').length > 0)
      $('.tabs-content :input').prop('disabled', true);
  }

  selectedTab(event) {
    $('#custom_review_anchor_tag').val($(event.currentTarget).attr('aria-controls'))
  }

  save() {
    value = parseInt($('#custom_review_final_reminder_sent').val())
    if(isNaN(value)) {
      var value = 0;
    }
    (value < 4 || value >= 30) ? $('#save-id, #save-id-2').attr('disabled', true) : $('#save-id, #save-id-2').attr('disabled', false)
  }

  insertToken(event) {
    const token = $(event.target).closest('div').find('select').last().context.value
    if(token !== 'Insert Token') {
      const cursorPosition = $(`#${event.currentTarget.dataset.notificationsId}`)[0].selectionStart
      let emailBody = $(`#${event.currentTarget.dataset.notificationsId}`)[0].value
      if(cursorPosition == 0 || cursorPosition == emailBody.length){
        $(`#${event.currentTarget.dataset.notificationsId}`)[0].value += token
      } else{
        const textBefore = emailBody.substring(0,  cursorPosition);
        const textAfter  = emailBody.substring(cursorPosition, emailBody.length);
        $(`#${event.currentTarget.dataset.notificationsId}`)[0].value = (textBefore + token + textAfter);
      }
    }
    $(event.target).closest('div').find('select').last().context.value = 'Insert Token'
  }

  resetDefault(event) {
    $(`#${event.currentTarget.dataset.notificationsId}`)[0].value = event.currentTarget.dataset.notificationsMessage
  }

  matchEmailPattern() {
    const to = $('#test_email').val();
    const matches = to.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    if (matches !== null) {
      $('#email-pattern-error').text('')
      $('#close-button').removeClass('unclickable')
    }
    else {
      $('#email-pattern-error').text('Please enter correct email.')
      $('#close-button').addClass('unclickable')
    }
  }

  getTestEmailData(event) {
    const emailType = window.location.href.split('#')[1] || 'new-request'
    let referencePointId = ''
    if(emailType == 'positive-thank-you'){
      referencePointId = '-'+$('#custom_review_reference_points')[0].value
    }
    const to = $('#test_email').val();
    const from = $(`.${emailType}-from${referencePointId}`).val();
    const subject = $(`.${emailType}-subject${referencePointId}`).val();
    const content = $(`.${emailType}-body${referencePointId}`).val();
    const companyId = event.currentTarget.dataset.notificationsCompany

    $('#email-pattern-error').text('')
    $.ajax({
      type: 'GET',
      url: "/companies/"+companyId+"/notifications/send_test_email",
      data: { from: from, content: content,
      subject: subject, reply_to: to },
      success: function(data) {
        $('#test_email').val('');
        $('#close-button').addClass('unclickable');
        alert('An email has been sent to your stated account.');
      }
    }).fail(function (response) {
      alert("Failed to send email.");
    });
  }
}
