export default class EditReviewType {
  constructor() {
    this.form = 'form.edit_company, form.new_custom_review, form.edi_custom_review';
  }

  initializeListeners() {
    $(document).on('submit', this.form, (event) => {
      const $customQuestionsTextarea = $('.custom-question.nested-fields textarea');

      if (!$customQuestionsTextarea.length) {
        return true;
      }

      let isError = false;

      $('.custom-question.nested-fields:not(.is-hidden) textarea').toArray().forEach(textarea => {
        let $input = $(textarea);

        if (!$input.val()) {
          $input.removeClass('is-error is-scale-error').addClass('is-error is-scale-error');
          isError = true;
        }
      });

      if (!isError) {
        $customQuestionsTextarea.removeClass('is-error is-scale-error');
      }

      $('.custom-question.nested-fields:not(.is-hidden) div.radio_buttons').toArray().forEach(radioContainer => {
        let $radioContainer = $(radioContainer);

        if (!$radioContainer.find('input[type="radio"]').is(':checked')) {
          $radioContainer.find('label').removeClass('is-error').addClass('is-error');
          isError = true;
        }
      });

      if (isError) {
        event.preventDefault();
        alert('Please complete your custom questions');
      }

      return !isError;
    });
  }
}
