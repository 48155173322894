import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#review_type, #review_print').select2({
      width:'22%',
      placeholder: 'All',
      templateResult: formatData,
      templateSelection: formatData,
      minimumResultsForSearch: -1
    });

    add_selected_value_in_dropdwon('review_type')
    add_selected_value_in_dropdwon('review_print')

  }
}

function formatData(data) {
  if (!data.id || data.element.attributes.dataimage.nodeValue == '') {
    return data.text
  }

  var image = data.element.attributes.dataimage.nodeValue
  var review_count = data.element.attributes.dataReviewCount.nodeValue
  if(!image) {
    return opt.text;
  }
  else {
    var $data = $(
      '<span><img src="' + image + '"width="33rem" style="margin-right: 0.5rem"/>' +
        '<span style="font-size: 0.9rem;">'+ data.text + '</span>' +
        '<span style="float: right; font-size: 12px; color: #cbc7c7;">' + review_count + '</span>' +
      '</span>'
    )
    return $data
  }
}

const add_selected_value_in_dropdwon = (review_type) => {
  if (window.location.search.split('=')[0].includes(review_type)) {
    $(`#select2-${review_type}-container`).find(':first').text(window.location.search.split('=')[1].toUpperCase())
  }
}
