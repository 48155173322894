export default class TextLimit {
  initializeListeners() {
    const $limitText = $('[data-limit-text]');

    $limitText.after('<div class="text-counter" style="margin-top: -1rem;"></div>');
    $limitText.on('focus blur keyup paste', (event) => {

      const $this = $(event.currentTarget);
      const msgSpan = $this.siblings('.text-counter');
      const ml = parseInt($this.attr('maxlength'), 10);
      const length = $this.val().length;
      const msg = `${ml - length} of ${ml} characters left`;

      msgSpan.html("<small>" + msg + "</small>");
    });
  }
}
