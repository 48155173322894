import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.initializeDateRangePicker();
  }

  initializeDateRangePicker() {
    const dateValue = this.element.value.trim();
    const [startDate, endDate] = dateValue.split("-");

    const formattedStartDate = startDate ? this.formatDate(startDate) : Date.now();
    const formattedEndDate = endDate ? this.formatDate(endDate) : Date.now();

    $(this.element).daterangepicker({
      parentEl: "#date-range-field",
      opens: "left",
      showDropdowns: true,
      linkedCalendars: false,
      applyButtonClasses: "button trigger-btn",
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      autoUpdateInput: false,

    });
    if(!dateValue){
     $('#date-range-picker').val('')
    }
    $('input[name="dateRange"]').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    });

    $('input[name="dateRange"]').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

  }

  formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }
}
