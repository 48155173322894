import { Controller } from 'stimulus';
import Alert from '../error/notice'
import { INTEGRATION_NAMES, DEFAULT_JOB_SETTINGS } from './constants';

export default class extends Controller {

  initialize() {
    $('#submitBtn').attr('disabled', true);
    var selected_option = $('#company_datasource_type').find(':selected').val();
    if (INTEGRATION_NAMES.includes(selected_option)) {
      $('#hiddenFieldsATSsync').show();
    }
  }

  connect() {
    var selected_option = $('#company_datasource_type').find(':selected').val();
    if (INTEGRATION_NAMES.includes(selected_option)) {
      $('#hiddenFieldsATSsync').show();
    } else{
      $('#hiddenFieldsATSsync').hide();
    }
    this.applyEllipsis();
  }

  applyEllipsis() {
    const maxLength = 270;
    const contentElements = document.querySelectorAll(".job-description-list");

    contentElements.forEach((element) => {
      if (element.textContent.length > maxLength) {
        const truncatedContent = element.textContent.slice(0, maxLength) + "...";
        element.textContent = truncatedContent;
      }
    });
  }

  toggleFieldTipLaborEdge(event) {
    var dateFields = ['startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'];
    var singleLiners = ['jobStatusCode', 'jobId'];
    var target = event.target.closest('.filter-heading').querySelector('.field_value_tip');
    var selected_option = event.target.options[event.target.selectedIndex].value;
    if (dateFields.includes(selected_option)) {
      target.setHTML('(YYYY-MM-DD, single entry, only last line will be used)');
    } else if (singleLiners.includes(selected_option)) {
      target.setHTML('(single entry, only last line will be used)');
    } else {
      target.setHTML('(multiples on their own lines)');
    }
  }

  toggleDataSourceOption(event) {
    var selected_option = $('#company_datasource_type').find(':selected').val();
    $('#submitBtn').attr('disabled', false);

    if (INTEGRATION_NAMES.includes(selected_option) || DEFAULT_JOB_SETTINGS.includes(selected_option)) {
      $('#hiddenFieldsATSsync').hide();  
    }
    if ((event.currentTarget.dataset['target'] == '') && (selected_option == 'not_in_use')) {
      $('#submitBtn').attr('disabled', true);
    }
    if (INTEGRATION_NAMES.includes(selected_option) && event.currentTarget.dataset['target'] == selected_option){
      $('#hiddenFieldsATSsync').show();
      $('#submitBtn').attr('disabled', true);
    }
    if ((DEFAULT_JOB_SETTINGS.includes(selected_option) || INTEGRATION_NAMES.includes(selected_option)) && 
        event.currentTarget.dataset['target'] == selected_option
        ) {
          $('#submitBtn').attr('disabled', true);
    }
  }

  saveDataSourceOption(event) {
    event.preventDefault();
    var selected_option = $('#company_datasource_type').find(':selected').val();
    if(INTEGRATION_NAMES.includes(selected_option)){
      sessionStorage.setItem(`${selected_option.split("_").first}_job_setting_value`, selected_option);
      $('#submitBtn').attr('disabled', true);
    }
    $('#job-datasource-form')[0].submit();
  }
}
