export default class Removable {
  initializeListeners() {
    $(document).on('click', '[data-removable-trigger]', (event) => {
      event.preventDefault();

      const container = $(event.currentTarget).parents('[data-removable]');
      container.find('input, textarea').toArray().forEach(input => {
        $(input).val('');
        $(input).trigger('input');
      });

      container.remove();
    });
  }
}
