export default class Copy {
  initializeListeners() {
    $('.js-copy').on('click', (e) => {
      const el = document.getElementById(e.currentTarget.dataset['copyElement']);
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand('copy');

      $(e.currentTarget).siblings().fadeIn(200).fadeOut(4000);
    });
  }
}
