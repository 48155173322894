const IMPORT_ALL_VALIDATORS_REGEX = /.*\/(.*)_validator\.js$/;

export const importAll = (context, cache = undefined) => {
  context.keys().forEach(key => {
    if (cache) {
      const identifier = getIdentifierFromKey(key);

      cache[identifier] = getModuleFromContextKey(context, key);
    } else {
      context(key);
    }
  });
};

const getIdentifierFromKey = (key) => {
  const matches = key.match(IMPORT_ALL_VALIDATORS_REGEX);

  if (matches) {
    return matches[1];
  }

  return matches;
};
const getModuleFromContextKey = (context, key) => context(key)['default'];
