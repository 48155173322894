import EditReviewType from "./editReviewType";
import EditNotification from "./editNotification";
import EditReview from "./editReview";
import VisibilityAndNotifications from "./visibilityAndNotifications";
import EditCompanies from "./editCompanies";

export default class Forms {
  initializeListeners() {
    $(document).on('ready', () => {
      new EditReviewType().initializeListeners();
      new EditNotification().initializeListeners();
      new EditReview().initializeListeners();
      new VisibilityAndNotifications().initializeListeners();
      new EditCompanies().initializeListeners();
    });
  }
}
