import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.planId = 'Annual Premium (84$/month)'
    const publishableKey = $('#stripe-key').val();
    this.stripe = Stripe(publishableKey);
    this.stripeElements();
  }

  getPlanId() {
    let plan = localStorage['selected_plan']
    localStorage.removeItem('selected_plan')
    return plan || this.planId
  }

  selectedPlanAnually() {
    localStorage.setItem('selected_plan', 'Annual Premium (84$/month)');
  }

  selectedPlanMonthly() {
    localStorage.setItem('selected_plan', 'Monthly Premium (99$)');
  }

  stripeElements() {
    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://rsms.me/inter/inter-ui.css'
        }
      ],
      locale: 'auto'
    });
    const style = {
      base: {
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF'
        }
      },
      invalid: {
        color: '#E25950'
      }
    };
    this.cardContent(elements, style);
  }

  cardContent(elements, style) {
    this.cardElement = elements.create('card', { style });
    this.cardElement.mount('#card-element');

    this.cardElement.addEventListener('change', ({ error }) => {
      const displayError = $('#card-errors')[0];
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  async submitBtn(event) {
    event.preventDefault();
    if (this.validateInputs()) {
      return false;
    }

    const { token, error } = await this.stripe.createToken(this.cardElement);
    if (error) {
      const errorElement = $('#card-errors')[0];
      errorElement.textContent = error.message;
      return false;
    } else {
      $('#subscription-btn').attr('disabled', true);
      $('#skip-btn').attr('disabled', true);
      $('#token-hidden').val(token.id);
      $('#plan-hidden').val(this.getPlanId());
      $('#payment-form')[0].submit();
    }
}

  validateInputs() {
      $('#name-error').text('');
      $('#postal-error').text('');
      $('#email-error').text('');
      $('#card-holder-name').css('margin-bottom', '1rem');
      $('#postal-code').css('margin-bottom', '1rem');
      $('#email-address').css('margin-bottom', '1rem');
      let cardName = $('#card-holder-name').val();
      let postalCode = $('#postal-code').val();
      let emailAddress = $('#email-address').val();
      let hasErrors = false;

      if (!/^[a-zA-Z\s]*$/.test(cardName)) {
          $('#name-error').text('Card Holder Name should contain only alphabetic characters.');
          $('#card-holder-name').css('margin-bottom', '0px');
          hasErrors = true;
      }

      if (!/^[0-9]*$/.test(postalCode)) {
          $('#postal-error').text('Postal Code should be numeric.');
          $('#postal-code').css('margin-bottom', '0px');
          hasErrors = true;
      }

      if (emailAddress !== "" && !/^\S+@\S+\.\S+$/.test(emailAddress)) {
          $('#email-error').text('Invalid email format.');
          $('#email-address').css('margin-bottom', '0px');
          hasErrors = true;
      }

      return hasErrors;
  }

  async previousSubmitBtn(event) {
    event.preventDefault();
    $("#subscription-btn").attr("disabled", true);
    const plan_field = $("#plan-hidden");
    if (!plan_field.val()) {
      plan_field.val(this.getPlanId());
    }
    $("#payment-form")[0].submit();
  }

  handleModelClose(event) {
    if (window.location.href.includes("success")) {
      window.location.href = window.location.pathname;
      window.location.reload();
    }
  }
}
