import "@stimulus/polyfills"
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import consumer from "../channels/consumer";
import StimulusReflex from "stimulus_reflex";

const application = Application.start()
const context = require.context('.', true, /_controller\.js$/)
application.load(definitionsFromContext(context));
StimulusReflex.initialize(application, { consumer, context, debug: false })
