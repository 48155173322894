import { Controller } from 'stimulus';
import { EMAIL_REGEX, PHONE_REGEX } from './constants';

export default class extends Controller {

  static targets = [ "button"]

  initialize() {
    if (document.getElementById('thank-you-modal')){
      openModal('#thank-you-modal')
    }
    this.hideInputBoxes();
  }

  hideInputBoxes()
  {
    const emailInput = document.getElementById('review_candidate_email');
    const phoneInput =  document.getElementById('review_candidate_phone');
    if(phoneInput){
      emailInput.style.display = 'none';
    }
    phoneInput.style.display = 'none';
  }

  toggle(event) {
    let acknowledged_at;
    let checkbox = document.getElementById(event.target.value);
    if (!checkbox.checked) {
      checkbox.checked = !checkbox.checked;
      checkbox.disabled = true;
      acknowledged_at = new Date().toUTCString();
      let formData = new FormData();
      formData.append('acknowledged_at', acknowledged_at);

      fetch(this.data.get('update-url'), {
        body: formData,
        method: 'PATCH',
        credentials: 'include',
        dataType: 'script',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
      })
        .then((response) => {
          if (response.status != 200) {
            checkbox.checked = !checkbox.checked;
            checkbox.disabled = false;
          } else {
            let referral = document.getElementById('not_contacted_referrals_' + event.target.value);
            let contacted_referrals = document.getElementById('contacted_referrals');
            contacted_referrals.prepend(referral);
            checkbox.setAttribute('title', 'can not uncheck already marked referral');
          }
          return response.text();
        })
        .then((data) => {
          let response = JSON.parse(data);
          document.getElementById('referral_count').innerHTML = response.referrals_count + ' ' + response.text;
          if (response.message != undefined) {
            this.flash_message(response.message);
          }
        });
    } else {
      this.flash_message('Cannot perform this action');
    }
  }

  flash_message(message) {
    document.getElementById('notice-container').innerHTML = '<p class="alert">' + message + '</p>';
    setTimeout(() => $('p.alert').addClass('fade-out'), 5000);
  }

  clearFormFields() {
    document.querySelector('#new_referral').reset();
    document.getElementById('referral_personalized_message').placeholder = `Provide an introduction to [first_name] to understand why ${this.data.get('user')} is great`
    document.getElementsByClassName('info')[0].innerText = `Great Recruiters will send an email to [first_name] with ${this.data.get('user')}'s Information so they can contact.`
    if (window.location.hash === "#refer-a-friend") {
      history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }

  changeText() {
    let first_name = document.getElementById('first_name').value
    document.getElementById('referral_personalized_message').placeholder = `Provide an introduction to ${first_name} to understand why ${this.data.get('user')} is great`
    document.getElementsByClassName('info')[0].innerText = `Great Recruiters will send an email to ${first_name} with ${this.data.get('user')}'s Information so they can contact.`
  }

  validate_candidate_email_phone_validity(event)
  {
    this.validate_phone_presence()
    this.validate_email_presence();
  }

  validate_email_presence() {
    const emailCheckbox = document.getElementById('email_checkbox').checked;
    const emailInput = document.getElementById('review_candidate_email');

    emailInput.required = emailCheckbox;
    emailInput.style.display = emailCheckbox ? 'block' : 'none';
    emailInput.value = emailCheckbox ? emailInput.value : '';

    const emailValue = emailInput.value.trim();
    if (emailValue !== '' && !EMAIL_REGEX.test(emailValue)) {
      return false;
    }

    return true;
  }

  validate_phone_presence() {
    const PHONE_REGEEX = /^\+(1|44)\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const phoneCheckbox = document.getElementById('phone_checkbox').checked;
    const phoneInput =  document.getElementById('review_candidate_phone');

    phoneInput.required = phoneCheckbox;
    phoneInput.style.display = phoneCheckbox ? 'block' : 'none';
    phoneInput.value = phoneCheckbox ? phoneInput.value : '';

    const phoneValue = phoneInput.value.trim();
    if (phoneValue !== '' && !PHONE_REGEEX.test(phoneValue)) {
      return false;
    }

    return true;
  }

  validate(event) {
    this.validation(event.currentTarget.id.split('_'));
  }

  validation(index) {
    let count, name, email, job_title;
    count = parseInt(index[index.length -1]);
    name = document.getElementById(`candidate_name_` + `${count}`);
    email = document.getElementById(`candidate_email_` + `${count}`);
    job_title = document.getElementById(`candidate_job_title_` + `${count}`);
    const inputsNotEmpty = (name.value.trim() + email.value.trim() + job_title.value.trim()).length > 0;
    name.required = email.required = job_title.required = inputsNotEmpty;
  }

  check_email_phone_validity(event) {
    const emailCheckbox = document.getElementById('email_checkbox').checked;
    const phoneCheckbox = document.getElementById('phone_checkbox').checked;
    const email_phone_fields = document.getElementsByName('candidate_email');
    const fixedNoticeContainer = $('#fixed-notice-container');
    const isPhoneValid = this.validate_phone_presence();
    const isEmailValid = this.validate_email_presence();
    if (!emailCheckbox && !phoneCheckbox) {
      fixedNoticeContainer.html('Please select either Email or Phone!');
      this.fix_container_function(fixedNoticeContainer);
      event.preventDefault();
    }
    if (emailCheckbox && !isEmailValid) {
      fixedNoticeContainer.html('Enter a valid email address!');
      this.fix_container_function(fixedNoticeContainer);
      event.preventDefault();
    }
    if (phoneCheckbox && !isPhoneValid) {
      fixedNoticeContainer.html('Enter a valid phone number!');
      this.fix_container_function(fixedNoticeContainer);
      event.preventDefault();
    }
    if (emailCheckbox && !isEmailValid && phoneCheckbox && !isPhoneValid) {
      fixedNoticeContainer.html('Enter a valid email address and phone number!');
      this.fix_container_function(fixedNoticeContainer);
      event.preventDefault();
    }
    email_phone_fields.forEach(element => {
      const candidate_name = document.getElementById('candidate_name_0').value;
      if (candidate_name) {
        if (!this.validate_referral_email_phone(element.value)) {
          if (element.value.trim() === '') {
            fixedNoticeContainer.html(`It is not a valid email address or phone number!`);
          }
          else {
            fixedNoticeContainer.html(`${element.value} is not a valid email address or phone number!`);
          }

          this.fix_container_function(fixedNoticeContainer);
          element.value = null;
          this.validation(element.id.split('_'));
        }
      }
    });
  }

  fix_container_function(fixedNoticeContainer) {
    fixedNoticeContainer.removeClass('hidden fade-out');
    setTimeout(() => {
      fixedNoticeContainer.addClass('fade-out');
      setTimeout(() => {
        fixedNoticeContainer.addClass('hidden');
      }, 0);
    }, 5000);
  }

  validate_referral_email_phone(email_phone_value) {
    return EMAIL_REGEX.test(email_phone_value) || PHONE_REGEX.test(email_phone_value);
  }

  userReferralModal() {
    $("#referred-job-id").attr('disabled','disabled');
  }

  jobReferralModal()
  {
    $("#referred-job-id").removeAttr('disabled');
  }


  connect() {
    this.initializeReferralModal();
    window.addEventListener('hashchange', this.handleHashChange.bind(this));
  }

  initializeReferralModal() {
    const button = this.buttonTarget;
    button.addEventListener('click', () => {
      const currentUrl = window.location.href;
      if (!currentUrl.includes("#refer-a-friend")) {
        const referralUrl = currentUrl + '#refer-a-friend';
        window.history.pushState(null, null, referralUrl);
      }
    });

    if (window.location.href.includes("refer-a-friend")) {
      $('#referral-modal').click();
    }
  }
  handleHashChange() {
   this.initializeReferralModal();
  }

  async CopyURL(event) {
    event.preventDefault();
    var url = event.currentTarget.dataset['target'];
    var dummy = document.createElement('input'),
    text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    var copy_alert = $('#url-copy');
    copy_alert.fadeIn('slow');
    copy_alert.fadeOut(5000);
  }
}
