import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.initializeSelect2();
  }

  initializeSelect2() {
    $('.departments-select-2').select2({
      placeholder: 'Teams',
      allowClear: true,
      tags: false
    });
  }
}
