import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    document.querySelectorAll('.article-body a').forEach(function(link) {
      link.target = '_blank'
    })
  }

  clickableArticle(event) {
    let id = event.currentTarget.dataset.loc;
    window.location.href = '/articles/'+id;
  }

  checkValidation(event) {
    const textArea =  $('#article_body').text().trim()
    const errorBody = $('#body-blank-error')
    if(textArea === '') {
      errorBody.addClass('error')
      errorBody.text('Body cannot be blank')
      event.preventDefault();
    } else {
      errorBody.text('');
      errorBody.removeClass('error')
    }
  }
};
