export default class Base {
  initializeListeners() {
    $(document).on('ready', () => {
      if (/Mobi/.test(navigator.userAgent)) {
        $('body').addClass('mobile');
      } else {
        $('body').removeClass('mobile')
      }
    });
  }
}
