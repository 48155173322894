import { Controller } from 'stimulus';
import { openStrongModal, closeModal } from '../components/modal';
import { updateSpecialtyInnerText, currentPage } from "./shared_functions";
import Alert from '../error/notice';
import { RECAPTHA_V3_SITE_KEY } from './constants';

export default class extends Controller {
  initialize() {
    this.hideErrors();

    if (document.getElementById('email-validation-modal')){
      openModal('#email-validation-modal')
    }
  }

  connect() {
    this.toggleDescriptionText();
    this.toggleGradientClass();
    this.changeContinueButton();
    const existingImageURL = this.element.dataset.myValue;
    const extistingStep = this.element.dataset.myStep;
    this.selectedDropDownOptions = [];

    if (existingImageURL) {
      this.handleImageUpload(null, existingImageURL, extistingStep);
      this.updateUserCard();
    } else if (existingImageURL === '') {
      this.changeContinueButtonStep2();
    }

    this.changeContinueButtonStep3();
    this.continueStep4();
    this.previewImageListner();
  }

  continueStep4() {
    const stepIndividualProcess = document.querySelector('.individuals-steps');
    const step4Element = document.querySelector('.step-4-form');

    if (stepIndividualProcess) {
      if (step4Element) {
        const industriesElement = document.querySelector('.selected-industries');
        if (industriesElement.dataset.registrationsStep4 === 'true') {
          this.removeClassesIfPresent(industriesElement);
        }
      }
    }
  }

  removeClassesIfPresent(element) {
    const recruitingYearElement = document.querySelector('.recruiting-start-year');
    if (recruitingYearElement) {
      recruitingYearElement.classList.remove('recruiting-empty-bar');
    }

    const industryList = element.dataset.registrationsIndustryList;
    if (industryList) {
      const industryListValues = industryList.split('\\| ')

      industryListValues.forEach((value, index) => {
        const pElement = document.querySelector(`.selected-industry-${this.toWord(index)}-value`);
        if (pElement) {
          pElement.classList.remove(`selected-industry-${this.toWord(index)}-empty-bar`);
        }
        const checkmarkElement = document.querySelector(`.checkmark-${this.toWord(index)}-tick`);
        if (checkmarkElement) {
          checkmarkElement.classList.add('tick-present');
        }
      });
    }
  }

  toWord(num) {
    const words = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
    return words[num];
  }

  toggleGradientClass() {
    const recruiterType = document.getElementById("recruiter-type-input");
    if (recruiterType) {
      $('#recruiter-type-input').select2({
        minimumResultsForSearch: -1,
        theme: 'foundation',
         placeholder: recruiterType.getAttribute('data-field-placeholder') || 'Select a recruiter type',
      }).on('change', (e) => {
        let fieldClassList= e.target.classList;
        this.handleRecruiterTypeChange(e.target.value, fieldClassList);
        $('.select2-selection__arrow b').css('tranform', 'rotate(-180deg)');
      })
      recruiterType.addEventListener('focus', e => {
        e.target.classList.add("gradient-border gradient-border open");
      });
    }
  }

  // TODO: revert it when recaptcha is fixed
  // verifyRecaptha(e) {
  //   if (sessionStorage.getItem('human') == 'true') {
  //     return;
  //   }
  //   $('#rec').attr('class');
  //   var recaptchaAttr = $('#rec').attr('data-recaptcha');
  //   if (!recaptchaAttr || recaptchaAttr === 'false') {
  //     e.preventDefault();
  //     grecaptcha.ready(function() {
  //       grecaptcha.execute(RECAPTHA_V3_SITE_KEY, {action: 'submit'}).then(function(token) {
  //         $.ajax({
  //           url: '/registrations/recaptcha',
  //           method: 'GET',
  //           dataType: 'json',
  //           data: {
  //             recaptcha_token: token
  //           },
  //           success: function(data) {
  //             if (data['success'] == false) {
  //               sessionStorage.setItem('human', 'false');
  //               openStrongModal('#recaptha-modal');
  //               $('.job-modal-close').css('display', 'none');
  //             } else {
  //               sessionStorage.setItem('human', 'true');
  //             }
  //           },
  //           error: function(jqXHR, textStatus, errorThrown) {
  //             console.error('reCAPTCHA verification failed:', textStatus, errorThrown);
  //           }
  //         });
  //       });
  //     });
  //   } else {
  //     sessionStorage.setItem('human', 'true');
  //   }
  // }

  hideErrors() {
    const errorMessages = document.querySelectorAll('small.error');
    errorMessages.forEach((errorMessage) => {
      errorMessage.style.display = 'none';
    });
  }

  previewImageListner () {
    const input = this.element;
    const step2Element = document.querySelector('.individual-step-2-form')
    input.addEventListener('change', (event) => {
      if (step2Element) {
        this.previewImage(event);
      }
    });

    input.addEventListener('click', (event) => {
      if (step2Element) {
      event.target.value = null;
      }
    });
  }

  static targets = ["dropdownContent", "dropdownToggle", "arrowElement"];
  toggleDropdown(event) {
    event.preventDefault();
    const dropdownContent = this.dropdownContentTarget;
    const arrowElement = this.arrowElementTarget;
    const dropdownToggle = this.dropdownToggleTarget;
    const dropdownContent1 = document.querySelector('.dropdown-content-1');
    const recruiterSelectorArrow = document.querySelector('.recruiter-type-arrow');
    const secondRecruiterSelectorArrow = document.querySelector('.recruiter-type-arrow-2');

    dropdownToggle?.classList.remove('drop-down-placeholder');
    dropdownToggle?.classList.remove('dropdown-toggle-1-bold');

    this.toggleServiceCompanySizeBorder();
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
      arrowElement.classList.remove("arrow-downward");
      dropdownToggle.classList.remove("select-industries-bold");
      dropdownContent1.classList.remove('gradient-border');
    } else {
      recruiterSelectorArrow.classList.remove('recruiter-type-arrow-color');
      secondRecruiterSelectorArrow?.classList.remove('recruiter-type-arrow-color');
      dropdownContent.style.display = "block";
      arrowElement.classList.add("arrow-downward");
      dropdownToggle.classList.add("select-industries-bold");
      dropdownContent1.classList.add('gradient-border');
      this.updateCheckboxStates();
      this.updateDropdownToggleText();
      updateSpecialtyInnerText();
      this.filterRoles({ target: document.getElementById('speciality-search') });
    }
  }

  filterRoles(event) {
    const searchTerm = event.target.value.toLowerCase();
    const dropdownContent = this.dropdownContentTarget;
    const options = dropdownContent.querySelectorAll('.dropdown-options');
    const noResultsMessage = document.getElementById('no-results-message');
    let hasVisibleOptions = false;

    options.forEach(option => {
        const roleText = option.textContent.toLowerCase();
        const checkbox = option.closest('.custom-checkbox');

        if (roleText.includes(searchTerm)) {
            checkbox.style.display = '';
            hasVisibleOptions = true;
        } else {
            checkbox.style.display = 'none';
        }
    });

    noResultsMessage.style.display = hasVisibleOptions ? 'none' : 'block';
  }

  toggleServiceCompanySizeBorder() {
    const dropdownToggle1 = document.querySelector('.dropdown-toggle-1');
    dropdownToggle1.classList.add('gradient-border');
    this.removeDropdown1border();
  }

  removeDropdown1border() {
    const serviceDropdown = document.querySelector('.selected-option');
    serviceDropdown?.classList.remove('selected-option-border');
  }

  handleOptionChange(event) {
    const checkbox = event.currentTarget;
    const selectedOption = this.getSelectedOption(checkbox);

    if (checkbox.checked) {
      const dropdownToggle = this.dropdownToggleTarget;
      dropdownToggle.value = '';
        if (!this.selectedDropDownOptions.includes(selectedOption)) {
            this.selectedDropDownOptions.push(selectedOption);
        }
    } else {
        const index = this.selectedDropDownOptions.indexOf(selectedOption);
        this.selectedDropDownOptions.splice(index, 1);
    }

    checkbox.checked = this.selectedDropDownOptions.includes(selectedOption);
    this.updatePlaceholder(); 
    this.updateDropdownToggleText();
    updateSpecialtyInnerText();
    this.changeContinueButtonStep3();
    this.showSelectedOptions();
    this.updateButtonText();
    if (window.location.href.includes('healthcare')) {
      event.preventDefault();
      $('#ranking-form')[0].submit();
    }
  }

  updatePlaceholder() {
      const dropdownToggle = this.dropdownToggleTarget;
      if (this.selectedDropDownOptions.length > 0) {
        dropdownToggle.placeholder = this.selectedDropDownOptions.join(', ');
        dropdownToggle.classList.add('roles-placeholder');
      } else {
        dropdownToggle.placeholder = 'All Specialties';
        dropdownToggle.classList.remove('roles-placeholder');
      }
  }

  updateButtonText() {
    const updatedTextContent = $('#speciality-search')[0];
    if (updatedTextContent && updatedTextContent.textContent && updatedTextContent.textContent.trim() != '') {
      $('#speciality-search').attr('placeholder', $('#speciality-search')[0].textContent);
    }
  }

  addSpecialtyValue(value) {
    const grDirectory = document.querySelector('.gr-directory-industries-block');

    if (grDirectory) {
      const recruitersPage = currentPage();
      var storedValues;

      if (recruitersPage) {
        storedValues = sessionStorage.getItem('recruitersSpecialtiesSession');
      } else {
        storedValues = sessionStorage.getItem('staffingSpecialtiesSession');
      }
      var stringArray = storedValues ? JSON.parse(storedValues) : [];

      stringArray.push(value);
      this.setSpecialtySession(stringArray,recruitersPage);
    }
  }

  removeSpecialtyValue(value) {
    const grDirectory = document.querySelector('.gr-directory-industries-block');

    if (grDirectory) {
      const recruitersPage = currentPage();
      var storedValues;
      if (recruitersPage) {
        storedValues = sessionStorage.getItem('recruitersSpecialtiesSession');
      } else {
        storedValues = sessionStorage.getItem('staffingSpecialtiesSession');
      }
      var stringArray = storedValues ? JSON.parse(storedValues) : [];

      const indexToRemove = stringArray.indexOf(value);
      if (indexToRemove !== -1) {
        stringArray.splice(indexToRemove, 1);
        this.setSpecialtySession(stringArray,recruitersPage);
      }
    }
  }

  setSpecialtySession(stringArray,recruitersPage) {
    if (recruitersPage) {
      sessionStorage.setItem('recruitersSpecialtiesSession', JSON.stringify(stringArray));
    } else {
      sessionStorage.setItem('staffingSpecialtiesSession', JSON.stringify(stringArray));
    }
  }

  getSelectedOption(checkbox) {
    const label = checkbox.closest('label');
    const span = label.querySelector('.dropdown-options');
    return span.innerText.trim();
  }

  updateDropdownToggleText() {
    const dropdownToggle = this.dropdownToggleTarget;
    const stepIndividualProcess = document.querySelector('.individuals-steps');
    const dropdown = document.querySelector('.dropdown-toggle-1');
    const selectorType =  stepIndividualProcess ? 'industry' : 'provided services'
    const parentElement = dropdownToggle.closest('.specialities-block');

    if (this.selectedDropDownOptions.length > 0) {
      const subStrLength = dropdownToggle.offsetWidth / 9;
      const joinedOptions = this.selectedDropDownOptions.join(", ");
      let firstNOptions;
      dropdownToggle.classList.remove("drop-down-placeholder");
      if (subStrLength > joinedOptions.length) {
        firstNOptions = joinedOptions.slice(0, subStrLength);
      }
      else {
        firstNOptions = joinedOptions.slice(0, subStrLength) + '...';
      }
      dropdownToggle.innerText = firstNOptions;
    } else {
      dropdown.classList.remove('dropdown-toggle-1-bold');

      if (parentElement && parentElement.classList.contains('specialities-block')) {
        dropdownToggle.innerText = 'All Specialities';
      } else {
        dropdownToggle.innerText = 'Select one or more ' + selectorType;
      }
    }
  }


  updateCheckboxStates() {
    const stepIndividualProcess = document.querySelector('.individuals-steps');
    const checkboxes = this.dropdownContentTarget.querySelectorAll(stepIndividualProcess ? 'input[name="industry_list[]"]' : 'input[name="service_ids[]"]');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = this.selectedDropDownOptions.includes(checkbox.value);
    });
  }

  showSelectedOptions() {
    // this.individualSelectedOptions();
  }

  individualSelectedOptions () {
    const firstSelectedValue = document.querySelector('.selected-industry-first-value');
    const secondSelectedValue = document.querySelector('.selected-industry-second-value');
    const thirdSelectedValue = document.querySelector('.selected-industry-third-value');
    const fourthSelectedValue = document.querySelector('.selected-industry-fourth-value');
    const fifthSelectedValue = document.querySelector('.selected-industry-fifth-value');
    const sixthSelectedValue = document.querySelector('.selected-industry-sixth-value');
    const firstSelectedTick = document.querySelector('.checkmark-first-tick');
    const secondSelectedTick = document.querySelector('.checkmark-second-tick');
    const thirdSelectedTick = document.querySelector('.checkmark-third-tick');
    const fourthSelectedTick = document.querySelector('.checkmark-fourth-tick');
    const fifthSelectedTick = document.querySelector('.checkmark-fifth-tick');
    const sixthSelectedTick = document.querySelector('.checkmark-sixth-tick');

    firstSelectedValue.textContent = '';
    secondSelectedValue.textContent = '';
    thirdSelectedValue.textContent = '';
    fourthSelectedValue.textContent = '';
    fifthSelectedValue.textContent = '';
    sixthSelectedValue.textContent = '';
    firstSelectedTick.classList.remove('tick-present');
    secondSelectedTick.classList.remove('tick-present');
    thirdSelectedTick.classList.remove('tick-present');
    fourthSelectedTick.classList.remove('tick-present');
    fifthSelectedTick.classList.remove('tick-present');
    sixthSelectedTick.classList.remove('tick-present');

    if (this.selectedDropDownOptions.length == 1) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.classList.add('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.add('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.add('selected-industry-fourth-empty-bar');
      fifthSelectedValue.classList.add('selected-industry-fifth-empty-bar');
      sixthSelectedValue.classList.add('selected-industry-sixth-empty-bar');
    }
    else if (this.selectedDropDownOptions.length == 2) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      secondSelectedValue.classList.remove('selected-industry-second-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      secondSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.textContent = this.selectedDropDownOptions[1];
      thirdSelectedValue.classList.add('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.add('selected-industry-fourth-empty-bar');
      fifthSelectedValue.classList.add('selected-industry-fifth-empty-bar');
      sixthSelectedValue.classList.add('selected-industry-sixth-empty-bar');
    }
    else if (this.selectedDropDownOptions.length == 3) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      secondSelectedValue.classList.remove('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.remove('selected-industry-third-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      secondSelectedTick.classList.add('tick-present');
      thirdSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.textContent = this.selectedDropDownOptions[1];
      thirdSelectedValue.textContent = this.selectedDropDownOptions[2];
      fourthSelectedValue.classList.add('selected-industry-fourth-empty-bar');
      fifthSelectedValue.classList.add('selected-industry-fifth-empty-bar');
      sixthSelectedValue.classList.add('selected-industry-sixth-empty-bar');
    }
    else if (this.selectedDropDownOptions.length == 4) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      secondSelectedValue.classList.remove('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.remove('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.remove('selected-industry-fourth-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      secondSelectedTick.classList.add('tick-present');
      thirdSelectedTick.classList.add('tick-present');
      fourthSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.textContent = this.selectedDropDownOptions[1];
      thirdSelectedValue.textContent = this.selectedDropDownOptions[2];
      fourthSelectedValue.textContent = this.selectedDropDownOptions[3];
      fifthSelectedValue.classList.add('selected-industry-fifth-empty-bar');
      sixthSelectedValue.classList.add('selected-industry-sixth-empty-bar');
    }
    else if (this.selectedDropDownOptions.length == 5) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      secondSelectedValue.classList.remove('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.remove('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.remove('selected-industry-fourth-empty-bar');
      fifthSelectedValue.classList.remove('selected-industry-fifth-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      secondSelectedTick.classList.add('tick-present');
      thirdSelectedTick.classList.add('tick-present');
      fourthSelectedTick.classList.add('tick-present');
      fifthSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.textContent = this.selectedDropDownOptions[1];
      thirdSelectedValue.textContent = this.selectedDropDownOptions[2];
      fourthSelectedValue.textContent = this.selectedDropDownOptions[3];
      fifthSelectedValue.textContent = this.selectedDropDownOptions[4];
      sixthSelectedValue.classList.add('selected-industry-sixth-empty-bar');
    }
    else if (this.selectedDropDownOptions.length > 5) {
      firstSelectedValue.classList.remove('selected-industry-first-empty-bar');
      secondSelectedValue.classList.remove('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.remove('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.remove('selected-industry-fourth-empty-bar');
      fifthSelectedValue.classList.remove('selected-industry-fifth-empty-bar');
      sixthSelectedValue.classList.remove('selected-industry-sixth-empty-bar');
      firstSelectedTick.classList.add('tick-present');
      secondSelectedTick.classList.add('tick-present');
      thirdSelectedTick.classList.add('tick-present');
      fourthSelectedTick.classList.add('tick-present');
      fifthSelectedTick.classList.add('tick-present');
      sixthSelectedTick.classList.add('tick-present');
      firstSelectedValue.textContent = this.selectedDropDownOptions[0];
      secondSelectedValue.textContent = this.selectedDropDownOptions[1];
      thirdSelectedValue.textContent = this.selectedDropDownOptions[2];
      fourthSelectedValue.textContent = this.selectedDropDownOptions[3];
      fifthSelectedValue.textContent = this.selectedDropDownOptions[4];
      sixthSelectedValue.textContent = this.selectedDropDownOptions[5];
    } else {
      firstSelectedValue.classList.add('selected-industry-first-empty-bar');
      secondSelectedValue.classList.add('selected-industry-second-empty-bar');
      thirdSelectedValue.classList.add('selected-industry-third-empty-bar');
      fourthSelectedValue.classList.add('selected-industry-fourth-empty-bar');
      firstSelectedTick.classList.remove('tick-present');
      secondSelectedTick.classList.remove('tick-present');
      thirdSelectedTick.classList.remove('tick-present');
      fourthSelectedTick.classList.remove('tick-present');
    }
  }

  companySelectedShowOptions() {
    const selectedIndustries = document.querySelector('.company-step-2-form');
    if (selectedIndustries) {
      const firstSelectedValue = document.querySelector('.selected-industry-first-value');
      const secondSelectedValue = document.querySelector('.selected-industry-second-value');
      const firstSelectedTick = document.querySelector('.checkmark-second-tick');
      const hasSelectedOptions = this.selectedDropDownOptions.length > 0;

      firstSelectedValue.textContent = '';
      secondSelectedValue.textContent = '';

      firstSelectedValue.classList.toggle('company-services-empty-bar', !hasSelectedOptions);
      secondSelectedValue.classList.toggle('company-selected-services-empty-bar', !hasSelectedOptions);
      firstSelectedTick.classList.toggle('tick-present', hasSelectedOptions);

      if (hasSelectedOptions) {
        firstSelectedValue.textContent = 'Services Provided';
        secondSelectedValue.textContent = this.selectedDropDownOptions[0];
      }
    }
  }

  clickOutside(event) {
    if (!event.target.closest(".step-custom-dropdown")) {
      const dropdownContent = this.dropdownContentTarget;
      const arrowElement = this.arrowElementTarget;
      const dropdownToggle = this.dropdownToggleTarget;

      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
        arrowElement.classList.remove("arrow-downward");
        dropdownToggle.classList.remove("select-industries-bold");
      }

      this.updateHiddenField();
      event.stopPropagation();
    }
  }

  UpdateSelectedOption() {
    const recruiterSelectorArrow = document.querySelector('.recruiter-type-arrow');
    const selectServiceArrow = document.querySelector('.recruiter-type-arrow-2');
    const selectedOptions = document.querySelector('.selected-option');

    if (recruiterSelectorArrow) {
      recruiterSelectorArrow.classList.add('recruiter-type-arrow-color');
    }
    if (selectServiceArrow) {
      selectServiceArrow.classList.add('recruiter-type-arrow-color');
    }
    const recruiterType = document.getElementById('recruiter-type-input');
    if (recruiterType) {
      if (recruiterType.value) {
        recruiterSelectorArrow.classList.remove('recruiter-type-arrow-color');
        selectedOptions.textContent = recruiterType.value.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, function(selectedRecruiterType) {
          return selectedRecruiterType.toUpperCase();
        });
      }
    }
  }

  previewImage(event) {
    const targetValue = event.target;
    if (targetValue.files && targetValue.files[0]) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          const image = new Image();

          image.onload = () => {
            if (image.width < 400 || image.height < 400) {
              let alert = new Alert;
              alert.flashImageMessage();
            } else {
              this.handleImageUpload(e);
              this.updateUserCard();
            }
          };

          image.onerror = () => {
            console.error('Invalid image file.');

          };

          image.src = e.target.result;
        };
        reader.onerror = (e) => {
          console.error('File reading failed:', e.target.error);
        };
        reader.readAsDataURL(targetValue.files[0]);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }

  handleImageUpload(e, existingImageURL = null, extistingStep = null) {
    if (extistingStep == '3' || extistingStep == '1' || extistingStep == '4') {
      const stepAvatar = document.querySelector('.step-avatar');
      stepAvatar.style.backgroundImage = "url(" + existingImageURL + ")";
    } else {
      const imagePreview = document.getElementById('uploaded-image-preview');
      const defaultIcon = document.querySelector('.default-upload-icon');
      const changeImageBtn = document.querySelector('.change-image-btn');
      const uploadButton = document.querySelector('.upload-button');
      const stepAvatar = document.querySelector('.step-avatar');

      if(!imagePreview || !defaultIcon || !changeImageBtn || !uploadButton || !stepAvatar) {
        return;
      }
    defaultIcon.style.display = "none";
    imagePreview.style.display = "block";

    if (existingImageURL) {
      this.enableContinueButton();
      imagePreview.style.backgroundImage = "url(" + existingImageURL + ")";
      stepAvatar.style.backgroundImage = "url(" + existingImageURL + ")";
    } else {
      imagePreview.style.backgroundImage = "url(" + e.target.result + ")";
      stepAvatar.style.backgroundImage = "url(" + e.target.result + ")";
    }
    changeImageBtn.style.display = "block";
    changeImageBtn.disabled = false;
    uploadButton.classList.add('image-uploaded');
    uploadButton.style.pointerEvents = "none";
    }
  }

  toggleDescriptionText() {
    const firstName = document.getElementById('user_first_name');
    const lastName = document.getElementById('user_last_name');
    if ((!firstName && !lastName) || (firstName.value.trim() && lastName.value.trim()) ) {
      $('#individual-signup-description').removeClass('hide');
    } else {
      $('#individual-signup-description').addClass('hide');
    }
  }

  updateUserCard() {
    const continueButton = document.querySelector('.step-continue-button');
    const lookingGreatElement = document.querySelector('.column-40 .looking-great');
    const lookingGreatElementLeftSide = document.querySelector('.column-60 .looking-great');
    const firstLetterElement = document.querySelector('.step-initials .first-letter');
    const lastLetterElement = document.querySelector('.step-initials .last-letter');
    const companyFirstLetterElement = document.querySelector('.step-initials .company-first-letter');
    this.enableContinueButton();

    if (lookingGreatElement) {
      lookingGreatElement.classList.remove('hidden');
      lookingGreatElementLeftSide.classList.remove('hidden');
    }

    if(!companyFirstLetterElement) {
      firstLetterElement.textContent = '';
      lastLetterElement.textContent = '';
    }
  }

  firstName(event) {
    const imageUrl = this.element.dataset.url;
    const targetValue  = event.target.value;
    const nameElement = document.querySelector('.step-user-info h2');
    const firstNameElement = document.querySelector('.step-user-info .first-name');

    const firstName = targetValue;
    if (!imageUrl) {
      const firstNameLetter = targetValue.charAt(0);
      const firstNameLetterElement = document.querySelector('.step-initials .first-letter');
      firstNameLetterElement.textContent = firstNameLetter;
    }

    firstNameElement.textContent = firstName;
    const lastName = document.querySelector('.step-user-info .last-name').textContent.trim();
    this.addRemoveEmptyBar(firstName || lastName, nameElement, 'name');
    this.changeContinueButton();
    this.toggleDescriptionText();
  }

  hideDropDowns(event) {
    const optionsList = document.querySelector(".options-list");
    const dropdownContent1 = document.querySelector(".dropdown-content-1");
    const placeholderOption = document.querySelector(".placeholder-option");
    const recruiterSelectorArrow = document.querySelector('.recruiter-type-arrow');
    const secondRecruiterSelectorArrow = document.querySelector('.recruiter-type-arrow-2');
    const dropdownToggle = document.querySelector('.dropdown-toggle-1');

    if (optionsList) {
      optionsList.style.display = 'none';
      this.removeArrow1Downward();
      if(!this.checkSelectedCompanySize()) {
        placeholderOption.classList.remove('dark-placeholder-option');
        recruiterSelectorArrow.classList.add('recruiter-type-arrow-color');
      }
    }
    if (dropdownContent1 && !event.target.classList.contains('select-industries')) {
      dropdownContent1.style.display = 'none';
      this.removeArrow2Downward();
      if(!this.checkSelectedServicesSize()) {
        dropdownToggle.classList.add('drop-down-placeholder');
        secondRecruiterSelectorArrow.classList.add('recruiter-type-arrow-color');
      }
    }
  }

  hideRecruiterTypeDropdown (event) {
    const dropdownContent1 = document.querySelector(".dropdown-content-1");
    const optionsList = document.querySelector(".options-list");
    const placeholderOption = document.querySelector(".placeholder-option");
    const recruiterType = document.querySelector('.step-user-info p:nth-of-type(1)').textContent.trim()
    const dropdownToggle = document.querySelector('.dropdown-toggle-1');
    const recruiterSelectorArrow = document.querySelector('.recruiter-type-arrow');
    if (dropdownContent1 && !event.target.classList.contains('select-industries')) {
      dropdownContent1.style.display = 'none';
      if (!this.checkSelectedServicesSize()) {
        dropdownToggle.classList.add("drop-down-placeholder");
        recruiterSelectorArrow.classList.add('recruiter-type-arrow-color');
      }
      this.removeArrow1Downward();
    }
    if (optionsList) {
      optionsList.style.display = 'none';
      this.removeArrow1Downward();
      if(!recruiterType) {
        placeholderOption.classList.remove('dark-placeholder-option');
        recruiterSelectorArrow.classList.add('recruiter-type-arrow-color');
      }
    }
    if (optionsList) {
      optionsList.style.display = 'none';
      this.removeArrow1Downward();
      if(!recruiterType) {
        placeholderOption.classList.remove('dark-placeholder-option');
      }
    }
  }

  removeArrow1Downward () {
    const dropDownFirstArrow = document.querySelector('.recruiter-type-arrow');

    if (dropDownFirstArrow) {
      dropDownFirstArrow.classList.remove('arrow-downward');
    }
  }

  removeArrow2Downward () {
    const dropDownSecondArrow = document.querySelector('.recruiter-type-arrow-2');

    if (dropDownSecondArrow) {
      dropDownSecondArrow.classList.remove('arrow-downward');
    }
  }

  addArrow1Downward () {
    const dropDownFirstArrow = document.querySelector('.recruiter-type-arrow');

    if (dropDownFirstArrow) {
      dropDownFirstArrow.classList.add('arrow-downward');
    }
  }

  lastName(event) {
    const imageUrl = this.element.dataset.url;
    const targetValue  = event.target.value;
    const nameElement = document.querySelector('.step-user-info h2');
    const lastNameElement = document.querySelector('.step-user-info .last-name');

    if (!imageUrl) {
      const lastNameLetterElement = document.querySelector('.step-initials .last-letter');
      const lastNameLetter = targetValue.charAt(0);
      lastNameLetterElement.textContent = lastNameLetter;
    }

    const lastName = targetValue;
    lastNameElement.textContent = lastName;
    const firstName = document.querySelector('.step-user-info .first-name').textContent.replace(/&nbsp;/g, '').trim();
    this.addRemoveEmptyBar(firstName || lastName, nameElement, 'name');
    this.changeContinueButton();
    this.toggleDescriptionText();
  }

  companyName(event) {
    const targetValue  = event.target.value;
    if (targetValue !== 'companies') {
      const companyElement = document.querySelector('.step-user-info p:nth-of-type(2)');
      const companyName = targetValue;
      companyElement.textContent = companyName;
      this.addRemoveEmptyBar(companyName, companyElement, 'company');
    }
    this.changeContinueButton();
  }

  jobTitle (event) {
    const targetValue = event.target.value;
    const jobTitleElement = document.querySelector('.company-user-name p:nth-of-type(1)');
    const jobTitle = targetValue;

    jobTitleElement.textContent = targetValue;

    this.addRemoveEmptyBar(jobTitle, jobTitleElement, 'recruiter');
    this.changeContinueButton();
  }

  cellNumber(event) {
    this.validateCellNumber(event.target);
  }

  validateCellNumber(targetField) {
    const targetValue = targetField.value;
    const cleaned = targetValue.replace(/\D/g, '');
    const formattedNumber = this.cellNumDisplay(cleaned);
    const cellNumberElement = document.querySelector('.company-user-name p:nth-of-type(2)');

    targetField.value = formattedNumber;

    if (cellNumberElement) {
      cellNumberElement.textContent = formattedNumber;
      this.addRemoveEmptyBar(cleaned, cellNumberElement, 'company');
      this.changeContinueButton();
    }
  }

  cellNumDisplay(phoneNumber) {
    if (phoneNumber.length === 0) {
      return phoneNumber;
    }
    let formattedNumber = '';
    if (phoneNumber.length === 10) {
      formattedNumber = '(' + phoneNumber.substring(0, 3) + ') ' +
                        phoneNumber.substring(3, 6) + '-' +
                        phoneNumber.substring(6, 10);
    } else if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
      formattedNumber = '+1 (' + phoneNumber.substring(1, 4) + ') ' +
                        phoneNumber.substring(4, 7) + '-' +
                        phoneNumber.substring(7, 11);
    } else if (phoneNumber.length === 12 && phoneNumber.startsWith('44')) {
      formattedNumber = '+' + phoneNumber.substring(0, 2) + ' (' +
                        phoneNumber.substring(2, 5) + ') ' +
                        phoneNumber.substring(5, 8) + '-' +
                        phoneNumber.substring(8, 12);
    } else {
      formattedNumber = phoneNumber;
    }
    return formattedNumber;
  }

  selectedOptions() {
    const dropdown = document.querySelector(".step-custom-dropdown");
    const placeholderOption = dropdown.querySelector(".placeholder-option");
    const optionsList = dropdown.querySelector(".options-list");
    const recruiterSelectorArrow = document.querySelector('.recruiter-type-arrow');
    recruiterSelectorArrow?.classList.remove('recruiter-type-arrow-color');
    placeholderOption?.classList.add('dark-placeholder-option');

    if (optionsList.style.display === "none" || optionsList.style.display === "") {
      optionsList.style.display = "block";

      this.addArrow1Downward();

    } else {
      optionsList.style.display = "none";

      this.removeArrow1Downward();

    }
  }

  optionsList (event) {
    const targetValue  = event.target;
    const dropdown = document.querySelector(".step-custom-dropdown");
    const selectedOption = dropdown.querySelector(".selected-option");
    const optionsList = dropdown.querySelector(".options-list");
    const recruiterTypeArrow = document.querySelector('.recruiter-type-arrow');

    if (targetValue.tagName === "LI") {
      const value = targetValue.getAttribute("data-value");
      selectedOption.textContent = targetValue.textContent;
      optionsList.style.display = "none";

      this.removeArrow1Downward();

      recruiterTypeArrow.classList.remove('recruiter-type-arrow-color');
    }
  }

  handleRecruiterTypeChange(recruiterType, fieldClassList) {
    if (recruiterType) {
      const recruiterName = this.titalizeContent(recruiterType);
      let recruiterTypeElement = document.querySelector('.step-user-info p:nth-of-type(1)');
      if (fieldClassList.contains('company-size-field')) {
        this.changeContinueButtonStep3();
        recruiterTypeElement = document.querySelector('.step-user-info p:nth-of-type(2)');
      }
      recruiterTypeElement.textContent = recruiterName;
      this.addRemoveEmptyBar(recruiterName, recruiterTypeElement, 'recruiter');
    }
    this.changeContinueButton();
  }

  titalizeContent(content) {
    if (content) {
      return content.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, function(selectedRecruiterType) {
          return selectedRecruiterType.toUpperCase();
      });
    } else {
      return content;
    }

  }

  addSelectBorder(event) {
    const targetValue = event.target;
    targetValue.classList.add('select-field-border')
  }

  companySize () {
    const { value: targetValue } = event.target.dataset;
    const companySizeElement = document.querySelector('.step-company-details p:nth-of-type(2)');

    companySizeElement.textContent = targetValue;

    if (targetValue) {
      this.addRemoveEmptyBar(targetValue, companySizeElement, 'company-size');
    }

    document.getElementById('company-size-input').value = targetValue;
    this.changeContinueButtonStep3();
  }

  companyUrl (event) {
    const targetValue = event.target.value;
    const companySizeElement = document.querySelector('.step-company-details p:nth-of-type(3)');
    const dropdownToggle1 = document.querySelector('.dropdown-toggle-1');

    companySizeElement.textContent = targetValue;

    dropdownToggle1.classList.remove('gradient-border');

    this.removeDropdown1border();
    this.addRemoveEmptyBar(targetValue, companySizeElement, 'company-website');
    this.changeContinueButtonStep3();
  }

  companyNameLinkedin(){
    const targetValue = event.target.value;
    const companylinkedin = document.querySelector('.step-company-details p:nth-of-type(1)');
    companylinkedin.textContent = targetValue;
    this.addRemoveEmptyBar(targetValue,companylinkedin, 'company-name');
    this.changeContinueButton();
  }

  dropdownSelection () {
    const button = document.querySelector('.selected-option');
    const serviceDropdown = document.querySelector('.toggle-dropdown-1')

    button.classList.add('selected-option-border');
    if (serviceDropdown) {
      serviceDropdown.classList.remove('gradient-border')
    }
  }

  addRemoveEmptyBar(name, nameElement, line) {
    const lineClass = line + '-empty-bar';
    if (name.trim()) {
      nameElement.classList.remove(lineClass);
    }
    else {
      nameElement.classList.add(lineClass);
    }
  }

  changeContinueButton() {
    const stepIndividualProcess = document.querySelector('.individuals-steps');
    const step1Process = document.querySelector('.step-1-form');

    if (step1Process) {
      if (stepIndividualProcess) {
        const companyName = document.querySelector('.step-user-info p:nth-of-type(2)').textContent.trim() || document.getElementById('user_company_name').value.trim();
        const recruiterType = document.querySelector('.step-user-info p:nth-of-type(1)').textContent.trim() || this.titalizeContent(document.getElementById('recruiter-type-input').value.trim());
        const firstName = document.querySelector('.step-user-info .first-name').textContent.replace(/&nbsp;/g, '').trim() || document.getElementById('user_first_name').value.trim();
        const lastName = document.querySelector('.step-user-info .last-name').textContent.trim() || document.getElementById('user_last_name').value.trim();

        if (recruiterType === 'Independent Recruiter') {
          $(".user_company_name > label").text("Company Name (optional)")
          if (firstName && lastName) {
              this.enableContinueButton();
           } else {
              this.disableContinueButton();
           }
      } else {
        $(".user_company_name > label").text("Company Name")
        if (companyName && recruiterType && firstName && lastName) {
              this.enableContinueButton();
          } else {
              this.disableContinueButton();
          }
      }

      } else {
        const firstName = document.querySelector('.step-user-info .first-name').textContent.replace(/&nbsp;/g, '').trim() || document.getElementById('company_claimants_attributes_0_first_name').value.trim();
        const lastName = document.querySelector('.step-user-info .last-name').textContent.trim() || document.getElementById('company_claimants_attributes_0_last_name').value.trim();
        const jobTitle = document.querySelector('.company-user-name p:nth-of-type(1)').textContent.trim() || document.getElementById('company_claimants_attributes_0_job_title').value.trim();
        const cellNumber = document.querySelector('.company-user-name p:nth-of-type(2)').textContent.trim() || document.getElementById('company_claimants_attributes_0_cell_number').value.trim();
        if (jobTitle && this.cellNumberLength(cellNumber) && firstName && lastName && this.checkCompanyName()){
          this.enableContinueButton();
        }
        else {
          this.disableContinueButton();
        }
      }
    }
  }

  cellNumberLength(cellNumber) {
    const cellNumberLength = cellNumber.length
    if (cellNumberLength > 3){
      const firstTwoNums = cellNumber.substring(0, 3);
      if (firstTwoNums === '+44') {
        return cellNumber.length > 17
      } else {
        return cellNumber.length >= 14
      }
    } else {
      false
    }
  }

  validatingCompanyUrl(event) {
    const urlField = event.target;
    const urlPattern = /^(?:https?:\/\/)?[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i;
    const errorMessageElement = document.getElementById('url-error');

    if (urlPattern.test(urlField.value)) {
      errorMessageElement.style.display = 'none';
    } else {
      errorMessageElement.style.display = 'block';
    }

    if(urlField.value == '')
    {
      errorMessageElement.style.display = 'none'; 
    }
  }

  validatingSocialMediaUrl(event) {
    const urlField = event.target;
    const urlPattern = /^(?:https?:\/\/)?[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i;

    const platform = urlField.name.split('_')[1];
    const errorMessageElement = document.getElementById(`social_${platform}_url-error`);
  
    if (urlPattern.test(urlField.value)) {
      errorMessageElement.style.display = 'none';
    } else {
      errorMessageElement.style.display = 'block';
    }

    if(urlField.value == '')
    {
      errorMessageElement.style.display = 'none'; 
    }
  }

  checkCompanyName() {
    const companyName = document.querySelector('.step-user-info p:nth-of-type(1)').textContent.trim();
    const linkedCompName = document.querySelector('.linkedin-comp');
    if (linkedCompName){
       if (companyName){
        return true;
       }
       else {
        return false;
       }
    }
    else {
      return true;
    }
  }

  changeContinueButtonStep2() {
    const step2Element = document.querySelector('.step-2-form');
    const step3Element = document.querySelector('.step-3-form');

    if (step2Element || step3Element) {
      this.disableContinueButton();
    }
    if (step3Element) {
      this.disableContinueButton();
    }
  }

  next_or_back(event) {
    event.preventDefault();
    const { method: urlMethod, url } = event.currentTarget.dataset;
    $.ajax({
      method: urlMethod,
      url: url,
      success: (response) => {
        window.location.replace(response['path']);
      }
    });
  }

  disableContinueButton () {
    const button = document.querySelector('.step-continue-button');
    button.classList.remove('continue-next');
    button.classList.add('opacity-1-unclickable');
  }

  enableContinueButton () {
    const button = document.querySelector('.step-continue-button');
    button.classList.add('continue-next');
    button.classList.remove('opacity-1-unclickable');
  }

  removeIndustriesGradient() {
    document.querySelector('.dropdown-toggle-1').classList.remove('gradient-border');
  }

  showRecruitingYear(event) {
    const recruitingYear = event.target.value;
    const recruitingYearElement = document.querySelector('.recruiting-start-year');
    const maxLength = 4;

    if (recruitingYear) {
      if (recruitingYear.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
      }
      recruitingYearElement.classList.remove('recruiting-empty-bar');
      recruitingYearElement.textContent = recruitingYear;
    } else {
      recruitingYearElement.classList.add('recruiting-empty-bar');
      recruitingYearElement.textContent = '';
    }
    this.changeContinueButtonStep3();
  }

  changeContinueButtonStep2() {
    const button = document.querySelector('.step-continue-button');
    const step2Element = document.querySelector('.step-2-form');
    const step3Element = document.querySelector('.step-3-form');

    if (step2Element || step3Element) {
      this.disableContinueButton();
    }
    if (step3Element) {
      this.disableContinueButton();
    }
  }

  changeContinueButtonStep3() {
    const stepIndividualProcess = document.querySelector('.individuals-steps');
    const step3Element = document.querySelector('.step-3-form');
    const companyStep3Element = document.querySelector('.company-step-2-form');

    if (step3Element) {
      if (stepIndividualProcess) {
        const recruitingYear = document.getElementById('user_recruiting_start_year').value;
        if(recruitingYear && this.checkSelectedIndustrySize()) {
          this.enableContinueButton();
        } else {
          this.disableContinueButton();
        }
      } else if (companyStep3Element) {
        const companyUrl = document.getElementById('company_url').value.trim();
        if(this.validateURL(companyUrl) && this.checkSelectedServicesSize() && this.checkSelectedCompanySize()) {
          this.enableContinueButton();
        } else {
          this.disableContinueButton();
        }
      }
    }
  }

  validateURL(url) {
    const urlPattern = /^(https?:\/\/)?([\w.-]+)(\.[\w.-]+)(\/[\w\-.\/?%&=]*)?$/;

    return urlPattern.test(url);
  }

  validateRecruitingYear(event) {
    const input = event.target;
    let value = input.value;

    value = value.replace(/\D/g, '');

    input.value = value;
  }

  checkSelectedServicesSize() {
    const selectedServices = document.querySelector('.dropdown-toggle-1').textContent;
    if (selectedServices.includes('Select one or more')) {
      return false;
    } else if (selectedServices.length > 0) {
      return true;
    }
  }

  checkSelectedIndustrySize() {
    const selectedServices = document.querySelector('.dropdown-toggle-1').textContent;
    if (selectedServices.includes('Select one or more')) {
      return false;
    } else if (selectedServices.length > 0) {
      return true;
    }
  }

  checkSelectedCompanySize() {
    const selectedOptions = document.getElementById('recruiter-type-input')
    if (selectedOptions && !selectedOptions.value) {
        return false;
    } else {
      return true;
    }
  }

  submitForm(event) {
    event.preventDefault();
    $(event.currentTarget).parents('form').submit();
  }

  next_or_back(event) {
    event.preventDefault();
    const { method: urlMethod, url } = event.currentTarget.dataset;
    $.ajax({
      method: urlMethod,
      url: url,
      success: (response) => {
        window.location.replace(response['path']);
      }
    });
  }
  async PasswordStrength(event) {
    event.preventDefault();
    let passwordStep = $(".password-step-1");
    let password_value = $(".password-field span input").val()
    let confirm_password_value = $(".confirm-password-field input").val()
    this.passwordLenghtCheck(password_value);

    if ((password_value == confirm_password_value) && (password_value != '')) {
      $(".confirm-password-icon").removeClass('display-hidden').addClass('display-block');
      $(".wrong-password").addClass('display-hidden').removeClass('display-block');
      $(".confirm-password-field").removeClass('sign-up-password-field');
    } else if (confirm_password_value != '') {
      $(".wrong-password").addClass('display-block').removeClass('display-hidden');
      $(".confirm-password-field").addClass('sign-up-password-field');
      $(".confirm-password-icon").removeClass('display-block').addClass('display-hidden');
    } else {
      $(".confirm-password-icon").removeClass('display-block').addClass('display-hidden');
      $(".confirm-password-field").removeClass('sign-up-password-field');
    }
  }

  passwordLenghtCheck(password) {
    if (password.length < 8) {
      $('#password-error').removeClass("hide")
    } else {
      $('#password-error').addClass("hide")
    }
  }

  updateValidationRules(event) {
    const dataIdx = event.currentTarget.dataset.idx;
    const emailInput = document.querySelector(`input[name="review[batch][${dataIdx}][candidate_email]"]`);
    const phoneInput = document.querySelector(`input[name="review[batch][${dataIdx}][candidate_phone]"]`);
    const emailInputValue = document.querySelector(`input[name="review[batch][${dataIdx}][candidate_email]"]`).value;
    const phoneInputValue = document.querySelector(`input[name="review[batch][${dataIdx}][candidate_phone]"]`).value;
    const recruiterNames = document.querySelectorAll('.custom-combobox-input');

    if (phoneInputValue !== '') {
      this.validateCellNumber(phoneInput)
    }

    recruiterNames.forEach(recruiter => {
      recruiter.setAttribute('required', true);
    });

    if ((emailInputValue === '') && (phoneInputValue !== '')) {
      phoneInput.setAttribute('required', true);
      emailInput.removeAttribute('required');
    } else if ((phoneInputValue === '') && (emailInputValue !== '')) {
      emailInput.setAttribute('required', true);
      phoneInput.removeAttribute('required');
    }
  }

  getConfirmation(event) {
    let company_name = $(".company-name input").val();
    let company_email = $(".company-email input").val();
    let company_url = $(".company-url input").val();
    let password_value = $(".password-field span input").val();
    let confirm_password_value = $(".confirm-password-field input").val();

    if ((company_name && company_email && this.validateURL(company_url) && password_value && confirm_password_value != '') && (password_value == confirm_password_value)) {
      event.preventDefault();
      openModal('#company-modal');
    }
  }

  confirmCompany(event) {
    $("#new_company").submit();
    $("#company-modal").foundation("close");
  }

  validateRecoverEmail(event) {
    const primaryEmailField = document.getElementById("user_email");
    const secondaryEmailField = document.getElementById("user_recovery_email");
    if (primaryEmailField.value.trim() == secondaryEmailField.value.trim()) {
      $('input[type="submit"]').prop("disabled", true);
      $("#recover-email-error").removeClass("hide");
    } else {
      $('input[type="submit"]').prop("disabled", false);
      $("#recover-email-error").addClass("hide");
    }
  }
}
