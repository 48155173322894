export default class Pickdate {
  initializeListeners() {
    $('[data-pickadate]').toArray().forEach(input => {
      $(input).pickadate({
        format: 'm/d/yyyy',
        selectMonths: true,
        selectYears: 50,
        max: true,
      });
    });
  }
}
