import { Controller } from 'stimulus';

export default class extends Controller {
    async copyLinkToClipboard(event) {
        var url = $("." + event.target.name).prop("href");

        $("#urltemp").val(url).select();
        await navigator.clipboard.writeText(url);

        var $reviewLinkPopup = $("#span-" + event.target.name);
        $reviewLinkPopup.outerWidth($(".copy-review-link-container").width());
        $reviewLinkPopup.fadeIn(200).fadeOut(4000);
    }
}
