import { Controller } from 'stimulus';
import { openModal } from '../components/modal';
import { updateSpecialtyInnerText, currentPage } from "./shared_functions";
export default class extends Controller {
  troubleshootJobFilters(event) {
    event.preventDefault();
    const jobSettingId = this.element.getAttribute('data-job-setting');
    const modal = document.querySelector('#troubleshoot-job-filters-modal');
    modal.setAttribute('data-job-setting', jobSettingId);
    openModal('#troubleshoot-job-filters-modal');
  }

  previewTroubleshootJobFilters(event) {
    event.preventDefault();
    const jobFilterId = $('#job_filter_id').val();
    const jobSettingId = event.currentTarget.dataset.jobSettingId;
    if (jobFilterId.trim() !== '') {
      $.ajax({
        url: `/job_settings/${jobSettingId}/get_bullhorn_job`,
        method: 'GET',
        data: { job_filter_id: jobFilterId },
        success: function(data) {
          const items = (data.result).split(', ');
          const previewContainer = document.getElementById("preview-jobs-filters");
          let newContent = "";

          items.forEach((item, index) => {
            const [name, selected] = item.split(": ");
            const cleanedName = name.replace(/"/g, "").trim();
            const fontSize = "1rem";

            newContent += `
              <div class="job-filters-checkbox">
                <input id="checkbox_${index}" type="checkbox" disabled="disabled">
                <label style="font-size: ${fontSize};">${cleanedName}</label>
              </div>
            `;
          });

          previewContainer.innerHTML = newContent;
          openModal('#preview-troubleshoot-job-filters-modal');

          items.forEach((item, index) => {
            const [name, selected] = item.split(": ");

            if (selected === "true") {
              $(`#checkbox_${index}`).prop('checked', true);
            }
          });
        },
        error: function(error) {
          console.error("Error:", error);
        }
      });
    }
  }
}
