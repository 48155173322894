export default class Upload {
  initializeListeners() {
    $('input[data-upload]').toArray().forEach(upload => {
      let label = $(upload).siblings('label, .form-label').first();

      if (label.hasClass('form-label')) {
        label = label.find('label')
      }

      $(label.clone()).insertBefore(label).addClass('filename-label');
      label.addClass('button').text('Choose File');
      $(upload).addClass('hide');
    });

    $('.batch_upload input[data-upload]').on('change', (e) => {
      let fileName = $(e.target).val().replace(/C:\\fakepath\\/i, '');
      let hint = "No file selected";
      if(fileName.length > 0){
        hint = fileName;
      }
      $(".batch_upload .hint").text(hint);
      $(".batch_upload .filename-label").text("* CSV file");
    });
  }
}
