// TODO: Use cocoon instead
export default class DuplicateFormField {
  initializeListeners() {
    $(document).on('click', '[data-form-duplicate-trigger]', (event) => {
      event.preventDefault();

      let target = $($(event.currentTarget).data('target')).last();
      $($('<div>').append(target.clone()).html()).insertAfter(target);

      target = $($(event.currentTarget).data('target')).last();
      const idx = target.data('idx');
      target.attr('data-idx', idx + 1);

      target.find('input, select, textarea').not('.ui-widget').toArray().forEach(field => {
        $(field).attr('name', $(field).attr('name').replace(idx, idx + 1));

        if ($(field).attr('id')) {
          $(field).attr('id', $(field).attr('id').replace(idx, idx + 1));
        }

        if ($(field).attr('type') !== 'checkbox') {
          $(field).val('');
        }
      });

      target.find('.custom-combobox').remove();
      target.find('[data-combobox]').toArray().forEach(input => $(input).combobox());
      this.removeRequiredClass(['candidate_name_', 'candidate_email_', 'candidate_job_title_'], idx)
    });
  }

  removeRequiredClass(elements, idx) {
    elements.forEach(element => {
      document.getElementById(`${element}` + `${idx + 1}`).required = false;
    });
  }
}
