import { Controller } from 'stimulus';
import Alert from '../error/notice'

export default class extends Controller {
  saveTeam() {
    $('#text' + this.data.get('field')).keyup(function() {
      let nameField = $(this).closest('form').find('input#submitButton')
      if(($(this).val().trim().length < 1))
        nameField.attr('disabled', true)
      else
        nameField.attr('disabled', false);
    });
  }

  hideAlert(event) {
    event.preventDefault();
    $('#alert').hide()
  }

  async editForm() {
    var id = this.data.get('id')

    fetch(this.data.get('url'), {
      method: 'GET',
    })
    .then(response => response.text())
    .then(result => {
      $(result).insertAfter($('#' + id));
      $('#' + id).hide();
    })
    .catch(error => {
      let obj = new Alert
      obj.flashMessage(error)
    });
  }

  toggle(event) {
    event.preventDefault();
    $('#' + this.data.get('field')).hide();
    $('#' + this.data.get('id')).show();
  }
}
