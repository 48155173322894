import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  initialize() {
    if (this.targets.find('submissionEntity')) {
      const submissionText = this.targets.find('submissionEntity')?.value || this.submissionFieldTextElement.dataset.reviewer;
      this.submissionFieldTextElement.innerHTML = `What field on ${submissionText}?`;
    }

   if (this.targets.find('placementEntity')) {
      const placementText = this.targets.find('placementEntity')?.value || this.placementFieldTextElement.dataset.reviewer;
      this.placementFieldTextElement.innerHTML = `What field on ${placementText}?`;
   }
  }

  connect() {
    const controller = this;
    this.subscription = consumer.subscriptions.create(
      {
        channel: `${this.data.get('channel')}Channel`,
        key: this.data.get('key')
      },
      {
        connected() {
          controller.connected = true;
        },
        disconnected() {
          controller.connected = false;
          controller.enableSettingForm()
        },
        received(data) {
          if (data['finished']) {
            if (data['fetchFields']) {
              window.location.reload();
            }

            controller.enableSettingForm();

            if (!data['error']) {
              controller.showModal(data['content']);
            } else {
              alert('Cannot preview reviews right now');
            }
          }
        }
      }
    );
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  disableSettingForm() {
    this.settingFormElement.classList.add('loading');
    this.settingSubmitElement.disabled = true;
  }

  enableSettingForm() {
    this.settingFormElement.classList.remove('loading');
    this.settingSubmitElement.disabled = false;
  }

  displayPlacementCommissions(event) {
    if (event.currentTarget.value === 'commissions') {
      this.placementCommissionContainerElement.classList.remove('is-hidden');
    } else {
      if (!this.placementCommissionContainerElement.classList.contains('is-hidden')) {
        this.placementCommissionContainerElement.classList.add('is-hidden');
      }
    }
  }

  displayPlacementFields(event) {
    if (event.currentTarget.value !== '') {
      this.placementFieldContainerElement.classList.remove('is-hidden');
      this.placementFieldTextElement.innerHTML = `What field on ${event.currentTarget.value}?`;
      this.targets.find('placementField').innerHTML = this[`${event.currentTarget.value.toLowerCase()}Options`];
    } else {
      if (!this.placementFieldContainerElement.classList.contains('is-hidden')) {
        this.placementFieldContainerElement.classList.add('is-hidden');
      }
    }

    if (!this.placementCommissionContainerElement.classList.contains('is-hidden')) {
      this.placementCommissionContainerElement.classList.add('is-hidden');
    }
  }

  displaySubmissionFields(event) {
    if (event.currentTarget.value !== '') {
      this.submissionFieldContainerElement.classList.remove('is-hidden');
      this.submissionFieldTextElement.innerHTML = `What field on ${event.currentTarget.value}?`;
      this.targets.find('submissionField').innerHTML = this[`${event.currentTarget.value.toLowerCase()}Options`];
    } else {
      if (!this.submissionFieldContainerElement.classList.contains('is-hidden')) {
        this.submissionFieldContainerElement.classList.add('is-hidden');
      }
    }
  }

  displayGRSyncFields(event) {
    if (event.currentTarget.value.length) {
      this.grScoreLable.classList.remove('is-hidden');
      this.grScoreFields.classList.remove('is-hidden');
      this.grScoreLable.innerHTML = `What field on ${event.currentTarget.value}?`;
    } else {
      if (!this.grScoreLable.classList.contains('is-hidden')) {
        this.grScoreLable.classList.add('is-hidden');
        this.grScoreFields.classList.add('is-hidden');
      }
    }
  }

  displayNpsSyncFields(event) {
    if (event.currentTarget.value.length) {
      this.npsScoreLable.classList.remove('is-hidden');
      this.npsScoreFields.classList.remove('is-hidden');
      this.npsScoreLable.innerHTML = `What field on ${event.currentTarget.value}?`;
    } else {
      if (!this.npsScoreLable.classList.contains('is-hidden')) {
        this.npsScoreLable.classList.add('is-hidden');
        this.npsScoreFields.classList.add('is-hidden');
      }
    }
  }

  preview(event) {
    event.preventDefault();
    if (event.currentTarget.attributes.disabled?.value == 'disabled'){
      return
    }

    const trigger = event.currentTarget.dataset['trigger'];

    if (this.connected) {
      this.subscription.send(this.buildParams(trigger));
      this.disableSettingForm();
    } else {
      this.enableSettingForm();
      alert('Preview is not supported right now');
    }
  }

  reloadFields(event) {
    event.preventDefault();

    if (this.connected) {
      this.subscription.send({ company: this.company, fetchFields: true });
      this.disableSettingForm();
    } else {
      this.enableSettingForm();
      alert('Fetch fields is not supported right now');
    }
  }

  showModal(content) {
    const contentScript = document.createElement('script');
    contentScript.innerHTML = content;
    document.head.appendChild(contentScript);
  }

  buildParams(trigger) {
    const channel = this.data.get('channel').toLowerCase();
    const params_key = {
      [`${channel}_setting`]: this[`${trigger}Params`](),
      company: this.company,
      setting: this.setting,
      preview: true,
    };
    return { ...params_key };
 }

  notesParams() {
    return {
      note_actions: this.targets.find('noteActions').value,
      note_active: true,
      note_preview: true,
      importer: 'notes',
      event_source: 'Note'
    }
  }

  placementsParams() {
    let fieldAttributes = {};
    const placementFields = this.targets.find('placementFields').getElementsByClassName('placement-field');

    Array.from(placementFields).forEach((placementField, i) => {
      fieldAttributes[i] = {
        name: placementField.querySelector('select').value,
        matches: placementField.querySelector('textarea').value
      };
    });

    const placementRevieweeField = this.targets.find('placementField')?.value;
    let params = {
      placement_active: true,
      placement_preview: true,
      placement_days_ago: this.targets.find('placementDays')?.value,
      placement_end_days_ago: this.targets.find('placementEndDays')?.value,
      placement_reviewee_entity: this.targets.find('placementEntity')?.value,
      placement_reviewee_field: placementRevieweeField,
      placement_commission_role: placementRevieweeField === 'commissions' && this.targets.find('placementCommission')?.value || null,
      fields_attributes: fieldAttributes,
      importer: 'placements',
      event_source: "Placement"
    }

    if (this.targets.has('placementReviewer')) {
      params['placement_reviewer'] = this.targets.find('placementReviewer')?.value
    }

    return params;
  }

  submissionsParams() {
    let params = {
      submission_active: true,
      submission_preview: true,
      submission_states: this.targets.find('submissionStates')?.value,
      submission_reviewee_entity: this.targets.find('submissionEntity')?.value,
      submission_reviewee_field: this.targets.find('submissionField')?.value,
      importer: 'submissions',
      event_source: 'Submission'
    }

    if (this.targets.has('submissionReviewer')) {
      params['submission_reviewer'] = this.targets.find('submissionReviewer').value
    }

    return params;
  }

  get company() {
    return this.data.get('company');
  }

  get setting() {
    return this.data.get('setting');
  }

  get candidateOptions() {
    return this.data.get('candidateOptions');
  }

  get contactOptions() {
    return this.data.get('contactOptions');
  }

  get jobOptions() {
    return this.data.get('jobOptions');
  }

  get placementOptions() {
    return this.data.get('placementOptions');
  }

  get submissionOptions() {
    return this.data.get('submissionOptions');
  }

  get settingFormElement() {
    return this.targets.find('settingsForm');
  }

  get settingSubmitElement() {
    return this.targets.find('settingsSubmit');
  }

  get connected() {
    return this.data.get('connected');
  }

  set connected(val) {
    this.data.set('connected', val)
  }

  get placementCommissionContainerElement() {
    return this.targets.find('placementCommissionContainer');
  }

  get placementFieldContainerElement() {
    return this.targets.find('placementFieldContainer');
  }

  get placementFieldTextElement() {
    return this.targets.find('placementFieldTextElement');
  }

  get submissionFieldContainerElement() {
    return this.targets.find('submissionFieldContainer');
  }

  get submissionFieldTextElement() {
    return this.targets.find('submissionFieldTextElement');
  }

  get grScoreLable() {
    return this.targets.find('grScoreLable');
  }

  get npsScoreLable() {
    return this.targets.find('npsScoreLable');
  }

  get grScoreFields() {
    return this.targets.find('grScoreFields');
  }

  get npsScoreFields () {
    return this.targets.find('npsScoreFields');
  }

}
