// TODO: Check if this is still needed
export default class Tooltips {
  initializeListeners() {
    $(document).on('touchstart click', 'body', (event) => {
      if ($(event.target).parents('.has-tip').length ||
        $(event.target).hasClass('has-tip') ||
        $(event.target).parents('.tooltip').length ||
        $(event.target).hasClass('tooltip')) {
        return true;
      }

      $('.tooltip').toArray().forEach(tooltip => $(tooltip).hide());
    });
  }
}
