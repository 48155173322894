export default class EmojiScore {
  initializeListeners() {
    $(document).on(
      'click', '[data-emoji-score]', onClickDataScoreFn('emoji-score', 'emoji-scale')
    ).on(
      'click', '[data-scale-score]', onClickDataScoreFn('scale-score', 'scale')
    ).on('submit', 'form', (event) => {
      if (!$('.emoji-scale').length) {
        return true;
      }

      let isError = false;
      $('.emoji-scale').toArray().forEach(scale => {
        const input = $(scale).find('[data-emoji-score-target]');

        if (!input.val()) {
          isError = true;
          $(scale).find('a[data-emoji-score]').addClass('error')
        }
      });

      $('.text-scale').toArray().forEach(scale => {
        const input = $(scale).find('textarea');

        if (!input.val()) {
          isError = true;
          $(input).addClass('is-scale-error')
        }
      });

      if (isError) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }).on('submit', 'form', (event) => {
      if (!$('.scale').length) {
        return true;
      }

      let isError = false;
      $('.scale').toArray().forEach(scale => {
        const input = $(scale).find('[data-scale-score-target]');

        if (!input.val()) {
          isError = true;
          $(scale).find('a[data-scale-score]').addClass('error');
        }
      });

      if (isError) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    });
  }
}

const onClickDataScoreFn = (dataScore, parentClass) => {
  return (event) => {
    event.preventDefault();

    const $this = $(event.currentTarget);
    const $container = $this.parents(`div.${parentClass}`);
    const score = $this.data(`${dataScore}`);

    $container.find(`[data-${dataScore}]`).removeClass('active error');
    $container.find(`[data-${dataScore}-target]`).val(score);
    $this.addClass('active');
  };
};
