// TODO: Check if this is still needed
export default class UniqueCheckbox {
  initializeListeners() {
    $('input[data-unique]').on('click', (e) => {
      if ($(e.target).is(':checked')) {
        const key = $(e.target).attr('data-unique');
        const id = $(e.target).attr('id');

        $(`input[data-unique='${key}']:checked`).not(`#${id}`).prop('checked', false)
      }
    });
  }
}
