$.widget('custom.combobox', {
  _create: function() {
    this.wrapper = $('<span>').addClass('custom-combobox').insertAfter(this.element);
    this.element.hide();
    this._createAutocomplete();
    this._createShowAllButton();
    this.input.attr("placeholder",this.element.attr("placeholder") || "Enter at least 1 character.");
  },
  _createAutocomplete: function() {
    var selected, value;
    selected = this.element.children(':selected');
    value = selected.val() ? selected.text() : '';
    minLength = parseInt(this.element.attr('length') || 0);
    this.input = $('<input>').appendTo(this.wrapper).val(value).attr({
      title: '',
      type: 'text'
    }).addClass('custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left').autocomplete({
      delay: 0,
      minLength: minLength,
      source: $.proxy(this, '_source')
    }).tooltip({
      classes: {
        'ui-tooltip': 'ui-state-highlight'
      }
    });
    this._on(this.input, {
      autocompleteselect: function(event, ui) {
        ui.item.option.selected = true;
        if (this.element.data('comboboxEventTrigger')) {
          this.element.trigger(this.element.data('comboboxEventTrigger'), {
            item: ui.item.option
          });
        } else {
          this._trigger('select', event, {
            item: ui.item.option
          });
        }
      },
      autocompletechange: '_removeIfInvalid'
    });
  },
  _createShowAllButton: function() {
    var input, wasOpen;
    input = this.input;
    wasOpen = false;
    $('<a>').attr('tabIndex', -1).appendTo(this.wrapper).button({
      icons: {
        primary: 'ui-icon-triangle-1-s'
      },
      text: false
    }).removeClass('ui-corner-all').addClass('custom-combobox-toggle ui-corner-right companies-dropdown').on('mousedown', function() {
      wasOpen = input.autocomplete('widget').is(':visible');
    }).on('click', function() {
      input.trigger('focus');
      if (wasOpen) {
        return;
      }
      input.autocomplete('search', '');
    });
  },
  _source: function(request, response) {
    var matcher;
    matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), 'i');
    response(this.element.children('option').map(function() {
      var text;
      text = $(this).text();
      if (this.value && (!request.term || matcher.test(text))) {
        return {
          label: text,
          value: text,
          option: this
        };
      }
    }));
  },
  _removeIfInvalid: function(event, ui) {
    var valid, value, valueLowerCase;
    if (ui.item) {
      return;
    }
    value = this.input.val();
    valueLowerCase = value.toLowerCase();
    valid = false;
    this.element.children('option').each(function() {
      if ($(this).text().toLowerCase() === valueLowerCase) {
        this.selected = valid = true;
        return false;
      }
    });
    if (valid) {
      return;
    }
    this.input.val('').attr('title', value + ' didn\'t match any item').tooltip('open');
    this.element.val('');
    this._delay((function() {
      this.input.tooltip('close').attr('title', '');
    }), 2500);
    this.input.autocomplete('instance').term = '';
  },
  _destroy: function() {
    this.wrapper.remove();
    this.element.show();
  }
});
