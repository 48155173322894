import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('gr-expandable', event => {
      const parent = document.querySelector(`${event.detail.parent}`);
      const isOpen = parent.dataset.expandable === 'open';

      document.querySelectorAll(`${event.detail.element}`).forEach(element => {
        if (isOpen) {
          element.classList.remove('active');
        } else {
          element.classList.add('active');
        }
      });

      if (isOpen) {
        parent.dataset.expandable = null;
      } else {
        parent.dataset.expandable = 'open';
      }
    }, false);
  }

  disconnect() {
    document.removeEventListener('gr-expandable', event => $(`${event.detail.element}`), false);
  }
};
