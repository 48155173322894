import { Controller } from 'stimulus';
import { openModal } from '../components/modal';

export default class extends Controller {
  initialize() {
    if (document.getElementById('thank-you-modal')){
      openModal('#thank-you-modal')
    }
  }

  fileCheck(event){
    if ($('#resume-file-name')[0].innerText == 'No file selected'){
      $('#resume-file-name')[0].style.color = "red";
    }
  }

  close(event){
    $('#resume-file-name')[0].style.color = "#6B7280";
    document.querySelector('form').reset()
    $('#file-type-error').text('')
    $('#resume-file-name')[0].innerText = 'No file selected';
  }

  file(event){
    let size = $('#resume')[0].files[0].size
    if (size > 5000000) {
      $('#file-type-error').text('File size should be less than 5.0 MB');
      $('#apply-now-button').addClass('disabled');
      return
    }
    let name = $('#resume')[0].files[0].name
    let extension = name.split('.').pop();
    $('#resume-file-name')[0].style.color = "black";
    $('#resume-file-name')[0].innerText = name;
    if (extension == 'pdf' || extension == name) {
      $('#file-type-error').text('')
      if(grecaptcha.getResponse().length != 0) {
        $('#apply-now-button').removeClass('disabled');
      }
    }
    else {
      $('#file-type-error').text('Only PDF format is allowed')
      $('#apply-now-button').addClass('disabled');
    }
  }

  toggle(event) {
    let acknowledged_at;
    let checkbox = document.getElementById(event.target.value);
    if (!checkbox.checked) {
      checkbox.checked = !checkbox.checked;
      checkbox.disabled = true;
      acknowledged_at = new Date().toUTCString();
      let formData = new FormData();
      formData.append('acknowledged_at', acknowledged_at);
      fetch(this.data.get('update-url'), {
        body: formData,
        method: 'PATCH',
        credentials: 'include',
        dataType: 'script',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
      })
        .then((response) => {
          if (response.status != 200) {
            checkbox.checked = !checkbox.checked;
            checkbox.disabled = false;
          } else {
            checkbox.setAttribute('title', 'can not uncheck already marked job application');
          }
          return response.text();
        });
    } else {
      this.flash_message('Cannot perform this action');
    }
  }

  flash_message(message) {
    $('#fixed-notice-container').html(message);
    $('#fixed-notice-container').removeClass('hidden');
    $('#fixed-notice-container').addClass('notice');
    setTimeout(() => $('p.notice').addClass('fade-out'), 5000);
  }
}
