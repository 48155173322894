import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#company-select').select2({
      width:'100%',
      maximumSelectionLength: 5,
      placeholder: 'Select a Parent company'
    });

    $('#user-select').select2({
      width:'100%',
      placeholder: 'Select Users'
    });

    $('#company-select').on('change.select2', () => {
      this.selectedCompany();
    });

    $('#user-select').on('change.select2', () => {
      this.selectedUsers();
    });
  }

  async CreateSuperCompanyAdmin(event) {
    event.preventDefault();
    $('#create-super-company-admin-modal select').val('');
    $('#select2-company-select-container').text('Select a Parent company');
    $('.users-block')[0].classList.add('display-none');
    $('.super-admin-submit-btn')[0].classList.add("disabled");
    this.selectedCompany();
    $('#create-super-company-admin-modal').attr('data-action', 'create');
    openModal('#create-super-company-admin-modal');
  }

  async selectedCompany() {
    let company_id = $('.company-dropdown')[0].value;
    let users_block = $('.users-block')[0];
    $('.users-block select').val('');
    document.getElementById("user-select").innerText = '';
    this.selectedUsers();

    if (company_id) {
      const formData = new FormData();
      formData.append('company_id', company_id);

      try {
        const response = await fetch('/super_company_admins/company_users', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
          },
          body: formData,
        });
        const data = await response.json();
        let users = data.users;
        this.displayUsers(users);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }

      users_block.classList.remove('display-none');
    }
  }

  async selectedUsers() {
    let company_id = $('.company-dropdown')[0].value;
    let user_dropdown = $('.user-dropdown')[0].value;

    if (company_id != '' && company_id != 'Select a Parent company' && user_dropdown != '') {
      $('.super-admin-submit-btn')[0].classList.remove("disabled");
    }
    else {
      $('.super-admin-submit-btn')[0].classList.add("disabled");
    }
  }

  displayUsers(users) {
    const usersContainer = document.getElementById("user-select");
    let newContent = "";

    users.forEach((user) => {
      newContent += `
        <div>
          <option value="${user.id}">${user.first_name + ' ' + user.last_name}</option>
        </div>
      `;
    });

    usersContainer.innerHTML = newContent;
  }

  async editSuperCompanyAdmin(event) {
    event.preventDefault();
    let userName = this.element.getAttribute("username");
    let company_id = this.element.getAttribute("company_id");
    let company_name = this.element.getAttribute("company_name");
    let userId = this.element.getAttribute("user_id");
    const companySelect = document.getElementById("company-select");
    const companyOption = companySelect.querySelector(`option[value="${company_id}"]`);

    document.getElementById("select2-company-select-container").textContent = company_name;

    if (companyOption) {
      companyOption.selected = true;
    }
    const userSelect = $('#user-select');
    const userOption = userSelect.find(`option[value="${userId}"]`);

    if (userOption.length > 0) {
      userOption.prop('selected', true);
      userSelect.trigger('change');
    }

    $('#create-super-company-admin-modal').attr('data-action', 'edit').data('company_id', company_id).data('userId', userId);
    openModal('#create-super-company-admin-modal');
    this.editRecord(userId, userName);
  }

  async editRecord(userId, userName) {
    let company_id = $('.company-dropdown')[0].value;
    let users_block = $('.users-block')[0];

    const formData = new FormData();
    formData.append('company_id', company_id);

    try {
      const response = await fetch('/super_company_admins/company_users', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: formData,
      });
      const data = await response.json();
      let users = data.users;
      const usersContainer = document.getElementById("user-select");
      let newContent = "";

      newContent += `
          <div>
            <option value="${userId}" selected>${userName}<option>
          </div>
        `;

      users.forEach((user) => {
        newContent += `
          <div>
            <option value="${user.id}">${user.first_name + ' ' + user.last_name}</option>
          </div>
        `;
      });

      usersContainer.innerHTML = newContent;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }

    if (company_id) {
      users_block.classList.remove('display-none');
    }

    this.selectedUsers();
  }

  async saveSuperCompanyAdmin(event) {
    event.preventDefault();
    let parent_company = $('.company-dropdown')[0].value;
    const selectedOptions = Array.from($('.user-dropdown')[0].selectedOptions);
    const selectedUserIds = selectedOptions.map(option => option.value);
    const modal = $('#create-super-company-admin-modal');
    const action = modal.attr('data-action');

    if (parent_company == '' || parent_company === 'Select a Parent company' || selectedOptions == '') {
      $('.super-admin-submit-btn')[0].classList.add("disabled");
    }
    else {
      if (action === 'create') {
        try {
          const response = await fetch(`/super_company_admins/${selectedUserIds.join(',')}`, {
            method: 'PATCH',
            headers: {
              'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
            },
          });
          location.reload();
        } catch (error) {
          console.error("Error:", error);
          throw error;
        }
      } else if (action === 'edit') {
        if ((parent_company == modal.data('company_id')) && !(selectedUserIds.includes(modal.data('userId')))) {
          try {
            const response = await fetch(`/super_company_admins/${selectedUserIds.join(',')}/edit_super_company_admin?user_id=${modal.data('userId')}`, {
              method: 'PATCH',
              headers: {
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
              },
            });
            location.reload();
          } catch (error) {
            console.error("Error:", error);
            throw error;
          }
        } else {
          try {
            const response = await fetch(`/super_company_admins/${selectedUserIds.join(',')}/edit_super_company_admin`, {
              method: 'PATCH',
              headers: {
                'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
              },
            });
            location.reload();
          } catch (error) {
            console.error("Error:", error);
            throw error;
          }
        }
      }
    }
  }

  async deleteSuperCompanyAdmin(event) {
    event.preventDefault();
    let user_id = this.element.getAttribute("user_id");
    const confirmed = window.confirm("Are you sure you want to delete?");

    if (confirmed) {
      try {
        const response = await fetch(`/super_company_admins/${user_id}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
          },
        });

        location.reload();
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  }
}
